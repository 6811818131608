import { classNames } from "../../../Utils";

export const FilterStyleClass = {
    root: classNames(
        'h-full',
        'w-full',
        'p-4'
    ),
    summary: classNames(
        'h-auto',
        'w-full',
        'flex',
        'flex-row',
        'flex-wrap',
        'gap-4',
        'p-4'
    ),
    filter: classNames(
        'h-auto',
        'w-full',
        'flex',
        'flex-row',
        'gap-4',
    ),
    item: {
        root: classNames(
            'flex flex-row w-full items-stretch'
        ),
        title: classNames(
            'text-gray-600',
            'text-sm',
            'mb-2',
            'font-medium',
            'inline-block'
        ),
        subTitle: classNames(
            'text-sm', 
            'text-gray-600', 
            'pt-2'
        ),
        actionBtns: classNames(
            'flex', 
            'gap-x-4', 
            'col-span-1', 
            'md:col-span-3', 
            'row-span-2',
            'mt-1'
        ),
        leftSection: classNames(
            'w-1/2 flex-col mt-2'
        ),
        rightSection: classNames(
            'w-1/2 flex items-center h-full pt-14'
        )
    }
}
export interface IFilterSummaryData {
    id: number,
    title: string,
    data: Array<{name: string, value: string | number}>,
    onClick: (id: number) => void,
    ishidden: boolean
}
export interface IFilter {
    selectionId: number
    filter: IFilterSummaryData
    rawData: any
    result: {selected: number, total: number}
    onSave: () => void,
    onCancel: () => void
}

export interface IEducationFilterItems extends IFilter {
    items: IEducationItems
}

enum IFilterType {
    Education = "Education",
    Experience = "Experience",
    Individual_Screening = "Individual_Screening",
    Motivation = "Motivation",
}
export interface IFilterResponseData {
    id: number
    selection: number
    filter_values: Record<string, any>
    filtered_applicants: number[]
    result: {total: number, selected: number}
    type: IFilterType[]
}
export interface IEducationItems {
    fieldOfStudies: string[],
    skills: string[],
    universities: string[]
}

export interface IEducationFilterPOST {
    gpa: number | null
    university: string[]
    field_of_study:  string[]
    skills:  string[]
}

export interface IExperienceFilterPOST {
    number_of_previous_jobs: number
    years_of_experience: number
}

export interface IMotivationFilterPOST {
    rating: number
}

export interface IScreeningFilterPOST {
    rating: number
}

export interface IExamFilterPOST {
    grade: number
}




