import React from 'react'
import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled"
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled"
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled"

const WagonWarning:React.FC = () => {    
    return(
        <div className={'col-span-1 md:col-span-3 text-gray-600 text-sm w-full'}>
            <WarningFilled className={'text-red-400 text-6xl w-1/2 text-center'} />
            <p className={'font-semibold mb-2 text-lg'}>Note: This action can't be undone!</p>
            <p className={'text-sm flex items-start text-fw-green'}>
                <CheckCircleFilled className={'text-base mr-2'} />
                Selected Candidates will get an invitation/congratulations notification and will proceed to next step </p>
            <p className={'text-sm flex items-start text-red-400'}>
                <CloseCircleFilled className={'text-base mr-2'} />
                <span>Failed Candidates will get an declination message and will be left behind and can't be accessed later</span>
            </p>
        </div>
    )
}

export default WagonWarning