import { Button, Image } from 'antd'
import { BulbTwoTone, SwapRightOutlined, CheckCircleOutlined} from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import FWBadge from '../../components/base/FWBadge'
import useAlert from '../../components/toast/useAlert'
import { useAuth } from '../../context/AuthContext'
// import EmploymentCredential from './EmploymentCredential'
import EmploymentInvitationModal from './EmploymentInvitationModal'
import axios from 'axios';
import { GetOffersEmplpoyee, OfferResponse } from '../../services/HiringService';

interface Idata {
    [index: number]: {
        employee_name?: string
        employee_did?: string
        employer?: string
        hash?: string
        issuer?: string
    }
}

const EmploymentJobSeekerPage = () => {
    const serverid = 1;
    const {currentUser} = useAuth()
    const {addAlert} = useAlert()
    // const [isAccepted, setIsAccepted] = useState(false)
    // const [isRejected, setIsRejected] = useState(false)
    // const [showOfferModal, setShowOfferModal] = useState(false)
    // const [showCredentialForm, setShowCredentialForm] = useState(false)
    // const [issued, setIssued] = useState(false)
    // const [successfulCredentials, setSuccessfulCredentials] = useState(false)
    const [credentials, setCredentials] = useState<Idata>({})
    // const [loading, setLoading] = useState(false)
    const [offers, setOffers] = useState<any[]>([])
    const [selectedJob, setSelectedJob] = useState<number>(0)
    const [viewedOffers, setviewedOffers] = useState<number[]>([])
    const [acceptedOffers, setAcceptedOffers] = useState<number[]>([])
    const [rejectedOffers, setRejectedOffers] = useState<number[]>([])
    const [issuedOffers, setIssuedOffers] = useState<number[]>([])
    const [loadingOffers, setLoadingOffers] = useState<number[]>([])
    const [successfulCredentialsOffers, setSuccessfulCredentialsOffers] = useState<number[]>([])


    useEffect(() => {
        const fetch = () => {
            GetOffersEmplpoyee().then(res => {
                setOffers(res?.data)
                // setJob(res?.data[0].job)
                // setMessageFromEmployer(res?.data[0].message)
            }).catch(err => console.error(err))
        }
        
       fetch()
       
    }, [])

    useEffect(() => {

        offers.forEach(item => {
            if(item.has_accepted)
                setAcceptedOffers(prev => [...prev, item.job?.id])
            else if (item.has_refused)
                setRejectedOffers(prev => [...prev, item.job?.id])
        })  
    }, [offers])
    
    

    const onViewOffer = (id:number) => {
        setSelectedJob(id)
        if(acceptedOffers.includes(id)) {
            // setShowCredentialForm(true)
            // setIssued(true)
            setIssuedOffers(prev => [...prev, id])

        } else {
            setviewedOffers(prev => [...prev, id])
            // setShowOfferModal(true)
        }
    }

    const checkStatus = (id:number) => {
        // setLoading(true)
        setLoadingOffers(prev => [...prev, id])
        axios.get(`https://employment-cred.hasura.app/api/rest/get_credential/${serverid}`).then(res =>{
            // console.log(res.data);
            setCredentials(res.data.employment_Credentials)           ;
            addAlert({type: 'success', title: 'success', description: 'Credential successfully checked', autoClose: true})
            // setLoading(false)
            setLoadingOffers(prev => prev.splice( prev.indexOf(id),1))
            setIssuedOffers(prev => prev.splice( prev.indexOf(id),1))
        }).catch(err => {
                addAlert({type: 'error', title: 'error', description: `${err?.message}`, autoClose: true})
                // setLoading(false)
                setLoadingOffers(prev => prev.splice( prev.indexOf(id),1))
            }).finally(() => {
                // setSuccessfulCredentials(true)
                setSuccessfulCredentialsOffers(prev => [...prev, id])
                // setLoading(false)
                setLoadingOffers(prev => prev.splice( prev.indexOf(id),1))
            })
    }  
    
    // const onClose = () => {
    //     setShowCredentialForm(false);
    // }

    const AcceptOffer = () => {
        OfferResponse({
            job: selectedJob,
            has_accepted: true,
            has_refused: false
        }).then(res => {
            console.log(res?.data)
            setAcceptedOffers(prev => [...prev, selectedJob])
            addAlert({type: 'success', title: 'success', description: 'Employment Offer Accepted Successfully!', autoClose: true})
        }).catch(err => {
            console.error(err)
            addAlert({type: 'error', title: 'Error Sending Acceptance', description: err.data, autoClose: true})
        }).finally(() =>
            setviewedOffers(prev => prev.splice( prev.indexOf(selectedJob),1))
        )
       
        // setShowOfferModal(false)
        
    }

    const RejectOffer = () => {
        OfferResponse({
            job: selectedJob,
            has_accepted: false,
            has_refused: true
        }).then(res => {
            console.log(res?.data)
            setRejectedOffers(prev => [...prev, selectedJob])
            addAlert({type: 'success', title: 'success', description: 'Employment Offer Rejected Successfully!', autoClose: true})
        }).catch(err => {
            console.error(err)
            addAlert({type: 'error', title: 'Error Sending Rejection', description: err.data, autoClose: true})
        }).finally(() =>
            setviewedOffers(prev => prev.splice( prev.indexOf(selectedJob),1))
        )
        // setShowOfferModal(false)
    }

    return(
        <div className='p-2 flex gap-2 wrap'>
            { offers.length !== 0 ? offers.map(item => 
         <div className={'ml-7'} key={item.id}>
            <div className={'rounded-md max-w-lg bg-white gap-y-2 gap-x-10 p-4 flex justify-between shadow-md'}>
                <div className={'flex flex-col'}>
                    <p className={'text-md font-semibold text-lg'}>Employment Offer Letter</p>
                    <p>Position: {item.job?.title}</p>
                    <p>Company: {item.job?.business?.name}</p>

                    {issuedOffers.includes(item.job?.id) && <div className={'mt-4 flex flex-col gap-2'}>
                            <p className='text-xl font-semibold'>Scan QR Code</p>
                            <p className={'text-md font-medium'}>Please Scan the QR Code and follow the prompt</p>
                            <Image src='/QR_Proofspace.png' width={'80%'} height={'auto'} />
                        </div>}
                </div>
                <div>
                    {successfulCredentialsOffers.includes(item.job?.id) ? <CheckCircleOutlined className='w-full flex justify-center' style={{color: 'green'}}/> : issuedOffers.includes(item.job?.id) ? <Button disabled={successfulCredentialsOffers.includes(item.job?.id)} loading={loadingOffers.includes(item.job?.id)} type="primary" size={'small'} className={'flex items-center ml-4'} onClick={()=> checkStatus(item.job?.id)}>{loadingOffers.includes(item.job?.id) ? 'checking' :'check status' }</Button> :<Button disabled={issuedOffers.includes(item.job?.id) || rejectedOffers.includes(item.job?.id)} size={'small'} className={'flex items-center ml-4'} onClick={() => onViewOffer(item.job?.id)} type="primary">
                        {acceptedOffers.includes(item.job?.id) ? 'Get Employment Credential' : rejectedOffers.includes(item.job?.id) ? 'Rejected' : 'View Offer'}
                    </Button>}
                    {/* <FWBadge className={isAccepted ? 'text-fw-green' : isRejected ? 'text-fw-orange' : 'text-red-400'} text={!successfulCredentials ? isAccepted ? 'Contract is Active' : 'Offer Pending Acceptance' : 'Credential Approved'}  /> */}
                    <FWBadge className={acceptedOffers.includes(item.job?.id) ? 'text-fw-green' : rejectedOffers.includes(item.job?.id) ?  'text-red-400': 'text-fw-orange'} text={ acceptedOffers.includes(item.job?.id)  ? 'Offer Accepted' : rejectedOffers.includes(item.job?.id) ? 'Offer Rejected' : 'Offer Pending Acceptance' }  />
                    {issuedOffers.includes(item.job?.id) && <div className='mt-10 flex flex-col gap-y-2'>
                        <ul className='list-none pl-0 flex flex-col gap-y-2'>
                            <li className='flex gap-2'><span><BulbTwoTone /></span><p>If you don't  have Proof Space App installed in your mobile, the prompt will redirect you to the link to download the app.</p></li>
                            <li className='flex gap-2'><span><BulbTwoTone /></span><p>You must have Proof Space App installed to proceed.</p></li>
                            <li className='flex gap-2'><span><BulbTwoTone /></span><p>Please give only your Fairway account email address when promoted to give your information in the Proof Space Application.</p></li>
                            
                        </ul>
                       
                        </div>}
                </div>
        
            </div>
            {successfulCredentialsOffers.includes(item.job?.id) && <div className={'rounded-md max-w-lg bg-white gap-y-2 gap-x-5 p-4 flex flex-col shadow-md -mt-2'}>
                <p className='text-lg font-semibold'>Employee Credential Form</p>
                <ul className='list-none pl-0 flex flex-col gap-y-2 max-w-full'>
                    <li className='flex gap-2'><span className='font-semibold flex items-center gap-x-2 min-w-[120px]'><SwapRightOutlined />Employee Name :</span><div className='max-w-[300px] overflow-x-scroll'>{credentials[0]?.employee_name}</div></li>
                    <li className='flex gap-2'><span className='font-semibold flex items-center gap-x-2 min-w-[120px]'><SwapRightOutlined />Employee DID :</span><div className='max-w-[300px] overflow-x-scroll'>{credentials[0]?.employee_did}</div></li>
                    <li className='flex gap-2'><span className='font-semibold flex items-center gap-x-2 min-w-[120px]'><SwapRightOutlined />Employer :</span><div className='max-w-[300px] overflow-x-scroll'>{credentials[0]?.employer}</div></li>
                    <li className='flex gap-2'><span className='font-semibold flex items-center gap-x-2 min-w-[120px]'><SwapRightOutlined />Hash :</span><div className='max-w-[300px] overflow-x-scroll'>{credentials[0]?.hash}</div></li>
                    <li className='flex gap-2'><span className='font-semibold flex items-center gap-x-2 min-w-[120px]'><SwapRightOutlined />Issuer :</span><div className='max-w-[300px] overflow-x-scroll'>{credentials[0]?.issuer}</div></li>
                </ul>
            </div>}
            {viewedOffers.includes(item.job?.id) && 
                <EmploymentInvitationModal
                    name={`${currentUser?.profile?.first_name} ${currentUser?.profile?.last_name}`}
                    job={item.job}
                    messageFromEmployer={item.message}
                    onClose={() => setviewedOffers(prev => [...prev.splice(prev.indexOf(item.job?.id),1)])}
                    onSubmit={AcceptOffer}
                    rejectOffer={RejectOffer}
                    readOnly={true}
            />} 
            {/* {showCredentialForm && 
                <EmploymentCredential
                 fullName={`${currentUser?.profile?.first_name} ${currentUser?.profile?.last_name}`}
                 onClose={onClose}
                 onSubmit={() => {
                    setShowCredentialForm(false)
                    setIssued(true)
                 }}
             />} */}
            
        </div>
            ): <div className='text-center'>No Offers Found!</div> }
        </div>
        )
}

export default EmploymentJobSeekerPage