import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth, ILoginDetails } from "../../context/AuthContext";
import { baseAddress } from "../../services/Request";
import { useQuery } from "../../Utility";
import useAlert from "../toast/useAlert";

interface ISignup {
    fromModal?: boolean
    onLogin?: () => void
    onBusiness?: () => void
}

const SignUp:FC<ISignup> = ({fromModal=false, onLogin, onBusiness}) => {

    const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
    const authContext = useAuth();
    const {addAlert} = useAlert();
    const navigate = useNavigate();
    const query = useQuery()

    const redirectUrl = useMemo(() => {
        const url = query.get('redirectTo')
        return url ? url : '/dashboard/account';
    }, [query]);

    const onSignUp =(values: any) => {
        if(values && values.email && values.password) {
            setSignUpLoading(true);
            axios.post(baseAddress + '/users/', {
               email: values.email,
               password: values.password
            })
            .then((response) => {
                if(response.status === 201) {
                    const data:ILoginDetails = {email: values.email, password:values.password, onSuccess:onLoginSuccess, onError:onLoginError}
                    authContext.logIn(data);
                }
            }, (error) => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(error?.response?.data), autoClose:false})
            }).catch(err => console.log(err))
            .finally(() => {
                setSignUpLoading(false);
            })
        }
    }

    const onLoginSuccess = ()=> {
        setTimeout(()=> {
            navigate(redirectUrl);
        }, 300)
    }
    const onLoginError = (err:any)=> {
        addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose:false})
    }

    const onLoginClick = () => {
        navigate(`/${query.get('redirectTo') ? `?redirectTo=${query.get('redirectTo')}` : ''}`)
    }

    const handleLoginClick = () =>{
        if(fromModal){
            onLoginClick()
            return
        }
        onLogin?.()
    }

    return(
    <div className={'flex flex-col items-center'}>  
        <Form
            name="signUp"
            onFinish={onSignUp}
            autoComplete="off"
            className={'w-full'}
        >
            <Form.Item 
                className={'mb-4 mt-4 md:mt-12'}
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'Please enter a valid email'}]}
            >
                <Input placeholder={'email'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!'},
                ({getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.length >= 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password should be at least 8 characters!'));
                    },
                }),
                ({getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || isNaN(Number(value))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Password can't be all numeric!"));
                    },
                }),
                ]}
            >
                <Input.Password placeholder={'password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
            </Form.Item>

            <Button loading={signUpLoading} disabled={signUpLoading} block type="primary" htmlType="submit">Sign up as Job Seeker</Button>

            <div className={'w-full flex flex-row items-center mt-1 justify-center'}>
                <p className={'text-xs mt-1 text-gray-400'}>Already registered?</p>
                <p className={'pt-1 text-xs pl-2 text-fw-orange font-medium cursor-pointer'} onClick={handleLoginClick}>Log In</p>
            </div>
            <div className={'divide-y-[3px] text-gray-500'} />
            <div className={'w-full flex flex-row items-center gap-2 mt-1 justify-center'}>
                <p className={'text-xs mt-1 text-gray-400'}>Are you a company?</p>
                {/* <p className={'pt-1 text-xs text-fw-orange font-medium cursor-pointer'} onClick={onCompanyWaitlist}>Follow link below</p> */}
            </div>
            {!fromModal && <Button block type={'default'} className={'mt-2'} htmlType="submit" onClick={onBusiness}>
                {/* <a target="_blank" href="https://fairway.global" rel="noreferrer" /> */}
                    Register as a Business
            </Button>}
        </Form>
    </div>
    );
}

export default SignUp;