import Checkbox from 'antd/lib/checkbox'
import React, { useEffect, useState } from 'react'
import { getSelectionAutoStat, startIntialFiltering } from '../../services/SelectionService'
import { classNames } from '../../Utils'
import Modal from '../base/Modal'
import useAlert from '../toast/useAlert'
import VettedChart from './Filter/VettedChart'

interface IInitialFilterModal {
    onSubmit: () => void
    onCancel: () => void
    selectionId: number
    totalApplicants: number
}

interface IVettingStats {
    elgibile_applicants: number
    number_of_applicants: number
}

const InitialFilterModal:React.FC<IInitialFilterModal> = ({onCancel, onSubmit, selectionId, totalApplicants}) => {

    const styleClass = {
        root: classNames(
            'w-full',
            'flex',
            'mt-4'
        ),
        leftSection: classNames(
            'w-1/2',
            'flex',
            'flex-col',
            'h-full',
        ),
        rightSection: classNames(
            'w-1/2',
            'flex',
            'flex-col',
            'h-full',
            'justify-between',
            'items-center'
        )
    }

    const [autoIsEnabled, setAutoIsEnabled] = useState(false)
    const [stats, setStats] = useState<IVettingStats>({elgibile_applicants: 0, number_of_applicants: 0})
    const [submitLoading, setSubmitLoading] = useState(false)
    const {addAlert} = useAlert()

    const onSubmitForm  = () => {
        setSubmitLoading(true)
        startIntialFiltering(selectionId).then(() => {
            onSubmit()
        }).catch((err) => {
            console.log(err)
            addAlert({type: 'error', title: 'Something went wrong', description: 'Please try again later', autoClose: true})
        })
        .finally(() => setSubmitLoading(false))
    }

    useEffect(() => {
        if(autoIsEnabled) {
            getSelectionAutoStat(selectionId).then(res => setStats(res?.data)).catch(err => console.log(err))
        } else {
            setStats({elgibile_applicants: totalApplicants, number_of_applicants: totalApplicants})
        }
    }, [autoIsEnabled, selectionId, totalApplicants])

    return(<Modal 
            className={'w-[70%] min-h-[50%] -mt-10 max-w-3xl'}
            title={'Start Vetting Process'} 
            subTitle={'Please select your preferences to decrease number of applicants'}
            onCancel={onCancel} 
            onSubmit={onSubmitForm}
            submitDisabled={submitLoading}
            >
            <div className={styleClass.root}>
                <section className={styleClass.leftSection}>
                <Checkbox onChange={() => setAutoIsEnabled(prev => !prev)}>
                    <p className={`${autoIsEnabled ? 'text-fw-orange' : 'text-fw-gray'} text-lg`}>Enable Fairway's Auto Initial Vetting</p>
                </Checkbox>
                <div className={'flex mt-2 gap-4'}>
                    <img src="/img/fw-logo-blue.jpg" width='80px' height='80px' alt="" />
                    <div className={'flex flex-col'}>
                        <p className={'text-fw-blue font-semibold text-md'}>Fairway's Smart Auto Filtering <sup>TM</sup></p>
                        <p className={'text-fw-blue font-light text-sm'}>
                            Fairway’s Automatic Vetting enables filtering 
                            removes all candidates who haven’t meet your job requirements
                        </p>
                    </div>
                </div>
                <p className={'text-sm text-orange-300 font-light pt-2'}>Note: This Action can't be Undone!</p>
                </section>
                <section className={styleClass.rightSection}>
                    <VettedChart selected={stats?.elgibile_applicants} total={stats?.number_of_applicants} />
                </section>
            </div>
        </Modal>
    )
}

export default InitialFilterModal