import { Button, Steps } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Modal from '../../components/base/Modal'
import { IFilterResponseData } from '../../components/selection/Filter/FilterHelper'
import SelectionFilter from '../../components/selection/Filter/SelectionFilter'
import VettedChart from '../../components/selection/Filter/VettedChart'
import WagonWarning from '../../components/selection/WagonWarning'
import useAlert from '../../components/toast/useAlert'
import { releaseExam } from '../../services/ExamService'
import { releaseMotivation } from '../../services/MotivationService'
import { releaseScreening } from '../../services/ScreeningService'
import { finishSelection, getFilters } from '../../services/SelectionService'
import { ISelection, ISelectionWagon, ITab } from '../../types'
import { classNames } from '../../Utils'
import ExamSetupModal from './exam/ExamSetupModal'
import MotivationModal from './motivation/MotivationModal'

interface INewWagon {
    selection: ISelection
    onClose: () => void
    onSubmit: () => void
}

const NewWagon:React.FC<INewWagon>  = ({selection, onSubmit, onClose}) => {
    const { Step } = Steps;
    const styleClass = {
        root: classNames(
            'flex', 
            'flex-row', 
            'w-full', 
            'mt-4'
        ),
        wagon: (selected: boolean) => classNames(
            'w-32', 
            'h-32', 
            'bg-fw-light-gray',
             selected ? 'border-fw_orange border-4' : 'border-fw_wagon_gray',
            'border', 
            'border-solid', 
            'rounded-[20px]',
            selected ? 'text-fw-orange': 'text-black',
            'font-medium',
            'text-sm',
            'grid',
            'place-items-center',
            'cursor-pointer'
        )
    }

    const {addAlert} = useAlert()
    const [showFilter, setShowFilters] = useState(false)
    const [reload, setReload] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [current, setCurrent] = useState(0);
    const [selectedWagon, setSelectedWagon] = useState(-1);
    const [result, setResult] = useState<{selected: number, total: number}>({selected:0, total:0})
    const examSetupRef = useRef<any>()

    useEffect(() => {
        getFilters(selection.id).then(res => {
            const filters:IFilterResponseData = res?.data[0]
            setResult(filters.result)
        }).catch((err) => console.log(err))
    }, [selection, reload])

    const tabs: ITab[] = useMemo(() => [
        {
            title:"Select new action",
            status: current === 0 ? "process" : "finish",
            onStepClick: () => console.log('')
        },
        {
            title:"Add step details",
            status: current === 1 ? "process" : "finish",
            onStepClick: () => console.log('')
        },
        {
            title:"Send Invitation/Rejection",
            status: current === 2 ? "process" : "finish",
            onStepClick: () => console.log('')
        }
    ], [current])

    const wagons = useMemo(() => {
        const possibleWagons  = [
            {
                id: 0,
                name: 'Exam',
                disabled: false
            },
            {
                id: 1,
                name: 'Motivation',
                disabled: false
            },
            {
                id: 2,
                name: 'Individual Screening',
                disabled: false
            },
            {
                id: 3,
                name: 'Finished',
                disabled: false
            },
        ]
        return possibleWagons.filter(wagon => {
            return !selection.train.some((w:ISelectionWagon) => w.wagon.type === wagon.name)     
        })
    }, [selection.train])

    const onFinishSetup = () => {
        setCurrent(current + 1)
    }

    const submitForm = () => {
        if(selectedWagon === -1) {
            addAlert({type: 'warning', title: 'No Action Selected', description: 'You must select to proceed', autoClose: true})
            return
        }
        if(current < 2) {
            if(current === 1 && selectedWagon === 0) {
                examSetupRef.current?.submitExam()
                return
            }
            setCurrent(current + 1)
            return
        }
        if(selection.id) {
            setSubmitLoading(true)
            if(selectedWagon === 0) {
                releaseExam(selection.id).then(() => {
                    onSubmit()
                }).catch(err => console.log(err)).finally(() => setSubmitLoading(false))
            }
            if(selectedWagon === 1) {
                releaseMotivation(selection.id).then(() => {
                    onSubmit()
                }).catch(err => console.log(err)).finally(() => setSubmitLoading(false))
            }
            if(selectedWagon === 2) {
                releaseScreening(selection.id).then(() => {
                    onSubmit()
                }).catch(err => console.log(err)).finally(() => setSubmitLoading(false))
            }

            if(selectedWagon === 3) {
                finishSelection(selection.id, {finish: true}).then(() => {
                    onSubmit()
                }).catch(err => console.log(err)).finally(() => setSubmitLoading(false))
            }
        }   
    }

    const onPrevious = () => {
        if(current > 0 ) {
            setCurrent(current - 1)
        }
    }

    return(<Modal title={'Add new step'} className={'w-[70%] min-h-[80%]'} onCancel={onClose} onPrevious={onPrevious}
            onSubmit={submitForm} submitText={current < 2 ? 'Next' : 'Submit' } submitDisabled={submitLoading} >
            <Steps
                type="navigation"
                size="small"
                current={current}
                className="site-navigation-steps"
                >
                {tabs.map((tab, i) => 
                    <Step
                        key={i}
                        title={tab.title}
                        status={tab.status}
                        description={tab.description}
                        onStepClick={tab.onStepClick}
                    />
                )}
            </Steps>
                <div className={styleClass.root}>
                 {current === 0 &&
                    <div className={'flex flex-col mt-6 ml-4'}>
                        <div  className='flex gap-6'>
                            {wagons.map(wagon => <div key={wagon.id} onClick={() => setSelectedWagon(wagon.id)} className={styleClass.wagon(wagon.id === selectedWagon)}>
                                {wagon.name === 'Individual Screening' ? 'Screening' : wagon.name}
                            </div>)}
                        </div>
                    {selectedWagon < 0 ? 
                     <p className={'text-gray-700 mt-4'}>Please click on one the above steps to proceed </p> :
                        <div className={'flex flex-col mt-4'}>
                            <div className={'flex gap-x-4 mt-2'}>
                                <p className={'text-gray-700'}>You Have Selected <span className={'uppercase font-bold mr-2'}>
                                    {wagons.find(wg => wg.id === selectedWagon)?.name ?? ''}</span> 
                                    <Button onClick={() => setSelectedWagon(-1)} size={'small'} type={'default'}>Deselect</Button>
                                </p>
                            </div>
                        </div>}
                    </div>
                 }
                 {current === 1 &&
                    <>
                    {selectedWagon === 0 && selection.id &&
                        <ExamSetupModal ref={examSetupRef} selectionId={selection.id} onSubmit={onFinishSetup} />}
                    {selectedWagon === 1 && selection.id &&
                        <MotivationModal selectionId={selection.id} />}
                    {selectedWagon === 2 && selection.id &&
                        <p>No Setup required for Individual Screening. Please proceed to next tab</p>}
                    {selectedWagon === 3 && selection.id &&
                        <p>Please note this will close the selection process and can't be undone.</p>}
                 </>}
                 {current === 2 &&
                    <div className={'w-full flex mt-6'}>
                        <div className={'w-1/2 text-gray-600 text-sm'}>
                            <WagonWarning />
                            <Button className={'mt-2'} onClick={() => setShowFilters(true)} size={'small'} type={'primary'}>Edit Filters</Button>
                        </div>
                        <div className={'w-1/2'}>
                            <VettedChart selected={result.selected} total={result.total} newWagon={wagons.find(wg => wg.id === selectedWagon)?.name} />
                        </div>
                    </div>}
                    
                 {showFilter && selection.id &&
                    <SelectionFilter 
                        selectionId={selection.id}
                        onSubmit={() => {
                            setShowFilters(false)
                            setReload(prev => !prev)
                        }}
                        onClose={() => setShowFilters(false)}
                    />}
                </div>
            </Modal>
    )
}

export default NewWagon