import { Badge, Button } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { getCVsForRating } from "../../../services/ScreeningService";
import { ICV } from "../../../types";
import ScreeningComplete from "./ScreeningComplete";
import ScreeningEvaluation from "./ScreeningEvaluation";


interface IScreeningWagonModal {
    selectionId: number
    reloadParent: () => void
}

const ScreeningWagonDetail:FC<IScreeningWagonModal> = ({selectionId, reloadParent}) => {

    const [loading, setLoading] = useState(false)
    const [showEvaluation, setShowEvaluation] = useState(false)
    const [finishScreening, setFinishScreening] = useState(false)
    const [cvIds, setCVIds] = useState<{id: number, cvId: number}[]>([])
    const [cvs, setCVs] = useState<ICV[]>([])
    const [ratedCVsCount, setRatedCVsCount] = useState<number>(0)
    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => {
       setLoading(true)
       getCVsForRating(selectionId).then((res) => {
         setCVs(res?.data ?? [])
         setCVIds(res?.data?.map((item: any) => ({id: item.id, cvId:item?.cv?.cv})))
         setRatedCVsCount(res?.data.filter((item: any) => item.is_rated === true).length)
       }).finally(() => setLoading(false))
    }, [selectionId, reload])

    const onSubmitEvaluation = () => {
        setShowEvaluation(false)
        setReload(prev => !prev)
        reloadParent()
    }

    const onSubmitFinishModal = () => {
        setFinishScreening(false)
        reloadParent()
    }

    const finishingIsDisabled = useMemo(() => {
        return ratedCVsCount !== cvIds.length
    }, [cvIds.length, ratedCVsCount])

    return(
        <div className={'flex flex-col gap-1 text-gray-500 mt-4 ml-2'}>
            <p className={'text-lg font-bold'}>Current Step: <span className={'text-fw-orange'}>Individual Screening</span> </p>
            <p className={'text-base font-medium mt-2 text-fw-orange'}>Available Operations</p>
            <p className={'text-base font-medium mt-2 text-fw-orange'}>{`Rated CVs (${ratedCVsCount} of ${cvIds.length})`}</p>
            <Button onClick={() => setShowEvaluation(true)} disabled={ratedCVsCount === cvIds.length} type={'primary'} className={'w-max'}>{`Evaluate CVs`}</Button>
            <Button onClick={() => setFinishScreening(true)} disabled={finishingIsDisabled} type={'primary'} className={'w-max'}>Finish/Close Screening Step</Button>
            {finishingIsDisabled && <Badge status={'error'} text={'Cvs are not fully rated to finish the wagon'} />}
    
            {showEvaluation && cvIds?.length > 0 && selectionId &&
            <ScreeningEvaluation
                selectionId={selectionId}
                candidateCVIds={cvIds}
                onClose={onSubmitEvaluation}
                onSubmit={onSubmitEvaluation}
                cvs={cvs}
            />}

            {finishScreening && !loading &&
            <ScreeningComplete 
                selectionId={selectionId}
                onClose={() => setFinishScreening(false)}
                onSubmit={onSubmitFinishModal}
            />}
        </div>
    )
}

export default ScreeningWagonDetail