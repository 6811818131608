import { Form, Input, DatePicker, TimePicker, Switch, Checkbox, Button, Select, Badge, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'
import { deleteExam, deleteExaminer, getExam, getExaminers, setExam, updateExam } from '../../../services/ExamService'
import { IExam, IExaminer } from '../../../types'
import ExaminerModal from './ExaminerModal'
import useAlert from '../../../components/toast/useAlert'

interface IExamSetupModal {
    selectionId: number
    onSubmit: () => void
}

const ExamSetupModal = React.forwardRef(({selectionId, onSubmit}: IExamSetupModal, ref) => {

    useImperativeHandle(ref, () => ({
      submitExam() {
        examForm.submit()
      }
    }))
    
    const {addAlert} = useAlert()
    const { Option } = Select
    const [examForm] = Form.useForm();
    const [saving, setSaving] = useState(false)
    const [examiners, setExaminers] = useState<IExaminer[]>([])
    const [examData, setExamData] = useState<IExam>()
    const [showEditModal, setShowEditModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [reload, setReload] = useState(false)
    const [examLoading, setExamLoading] = useState(false)
    const [examinerLoading, setExaminerLoading] = useState(false)
    const [acknowledged, setAcknowledged] = useState(true)
    const [acknowledgeError, setAcknowledgedError] = useState('')
    const [examinerError, setExaminerError] = useState('')
    const [editingMode, setEditingMode] = useState(false)
    const [selfExamined, setSelfExamined] =  useState(() => examData?.self_examined)
    const [selectedExaminer, setSelectedExaminer] = useState<IExaminer>()
    const [selectedExaminerId, setSelectedExaminerId] = useState<number>(0)  

    useEffect(() => {
        setExamLoading(true)
        getExam(selectionId).then(res => {
            const examRes:IExam = res?.data[0]
            if(examRes) {
                setExamData(examRes)
                setSelfExamined(examRes.self_examined)
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => setExamLoading(false))

        setExaminerLoading(true)
        getExaminers(selectionId).then((res:any) => {
            setExaminers(res?.data  ?? [] as IExaminer[])
        })
        .catch(ex =>  console.log(ex.message)).finally(() => setExaminerLoading(false))
    }, [selectionId, reload])

    useEffect(() => {
        if(selectedExaminerId && examiners.length > 0) {
            setSelectedExaminer(examiners.find((examiner:IExaminer) => examiner.id === selectedExaminerId) ?? undefined)
        }
    }, [examiners, selectedExaminerId])

    useEffect(() => {
        if(examData?.exam_institution) {
            setSelectedExaminerId(examData?.exam_institution)
        }
    }, [examData])

    useEffect(() => {
        examForm.setFieldsValue({
            ...examData,
            date: examData?.date ? moment(examData?.date) : moment().add(1, 'week').startOf('week').add(1, 'day'),
        });
      }, [examData, examForm]);

    const getDisabledDate = (currentDate: moment.Moment) => {
        if(currentDate.isSameOrBefore(moment(), 'day')) {
          return true
        } 
        return false
    }

    const submitExam = (values: any) => {
        if(!saving) {
        const {date, message, location} = values
       
        let exam: Omit<IExam, 'id'>
        if(!acknowledged) {
            setAcknowledgedError('Please acknowledge the exam setup')
            setSaving(false)
            return
        }
        if(!selfExamined && !selectedExaminerId) {
            setExaminerError('Please select examiner or mark as self Examined')
            setSaving(false)
            return
        }

        setSaving(true)
        const examDate = date ? date : examData ? moment(examData.date) : moment().add(1, 'week').startOf('week').add(1, 'day')
        if(!examData) {
            exam = Object.assign({}, {
                date: examDate.toISOString(),
                location: location,
                message: message,
                self_examined: selfExamined,
                exam_institution: selfExamined ? null : selectedExaminerId,
            })
            setExam(selectionId, exam).then((res) => {
                setExamData(res?.data)
                addAlert({type: 'success', message: 'success', description: 'Exam setup successfully', autoClose: true})
                onSubmit()
            }).finally(() => {
                setSaving(false)
                
            }) 
        } else {
            exam = Object.assign({}, {
                id: examData.id,
                date: examDate.toISOString(),
                location: location,
                message: message,
                self_examined: selfExamined,
                exam_institution: selfExamined ? null : selectedExaminerId,
            })
            updateExam(selectionId, examData.id, exam).then((res) => {
                setExamData(res?.data)
                addAlert({type: 'success', message: 'success', description: 'Exam updated successfully', autoClose: true})
                onSubmit()
            }).catch(err => {
                console.log(err)
                addAlert({type: 'error', message: 'Error updating exam', description: JSON.stringify(err.message), autoClose: true})
            })
            .finally(() => {
                setSaving(false)
            })
        } 
        }
    }

    const onCloseEditModal = () => {
        setShowEditModal(false)
    }

    const onCloseAddModal = () => {
        setShowAddModal(false)
    }

    const onSubmitEditModal = () => {
        setShowEditModal(false)
        setReload(prev => !prev)
    }

    const onSubmitAddModal = () => {
        setShowAddModal(false)
        setReload(prev => !prev)
    }

    const onEditExaminer = () => {
        setShowEditModal(true)
    }

    const onDeleteExaminer = () => {
        if(window.confirm('Are you sure you want to delete?') && selectedExaminerId) {
            deleteExaminer(selectionId, +selectedExaminerId).then(() => {
                addAlert({type: 'success', title: 'Success', description: 'Examiner deleted successfully', autoClose: true})
                setSelectedExaminerId(0)
                setReload(prev => !prev)
            }).catch((err: any) => console.log(err))
        }
    }

    const onDeleteExam = () => {
        if(window.confirm('Are you sure you want to delete?') && examData?.id) {
            setSaving(true)
            deleteExam(selectionId, examData.id).then(() => {
                setSaving(false)
                setEditingMode(true)
                setReload(prev => !prev)
            })
        }
    }



    return(<div className={'flex w-full mt-4'}>
                    <Form
                    name="examinerForm"
                    onFinish={submitExam}
                    className={'w-full flex flex-col md:flex-row gap-6'}
                    form={examForm}
                    autoComplete="off"
                    layout={'vertical'}
                    requiredMark={false}
                    >
                        <div className={'w-1/2 flex flex-col'}>
                            <p className={'text-gray-700 text-base font-semibold mb-2'}>Examiner</p>
                            {examinerError && <p className={'text-red-500 text-sm font-semibold mb-2'}>{examinerError}</p>}
                            {examinerLoading ? <div className={'flex justify-center items-center'}><Spin /></div> :
                            <>
                            <Form.Item 
                                className={'col-span-1 md:col-span-4 flex mb-2'}
                                initialValue
                            >
                                <Switch
                                    checked={!selfExamined}
                                    unCheckedChildren="I am self Examiner"
                                    checkedChildren="I will use External Examiner"
                                    onChange={() => {
                                        setSelfExamined(prev => !prev);
                                    }}
                                />
                                <p className={'w-max text-xs mt-2 text-gray-800'}>
                                    {!examData?.self_examined ? 'External Examiner will be Responsible' : 'You will be responsible for Exam'}
                                </p>
                            </Form.Item>
                            
                            {!selfExamined && 
                            <div className={'flex items-center gap-2'}>
                                <Form.Item 
                                    className={'w-full flex mb-2 mt-4'}
                                    name="exam_institution"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Select Examiner"
                                        value={selectedExaminerId}
                                        filterOption={(input, option) => 
                                            option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => {
                                            setSelectedExaminerId(value)
                                            setSelectedExaminer(examiners.find(examiner => examiner.id === value))     
                                            if(value) {
                                                setExaminerError('')
                                            }
                                        }}
                                    >
                                        {examiners.map((item: IExaminer, i) => {
                                            return  <Option key={i} value={item.id}>{item.institution_name} 
                                                {item.default_examiner && <Badge text={'default'} color={'green'} className={'ml-2'} />} 
                                            </Option>
                                        })} 
                                    </Select>
                                </Form.Item>
                                <Button className={'mt-2'} onClick={() => setShowAddModal(true)} size={'small'} type={'primary'}>Add Examiner</Button>
                            </div>}
                            
                            {!selfExamined &&
                            <>
                            {selectedExaminer ?
                            <div className={'flex flex-col'}>
                                <hr />
                                <p className={'font-semibold underline'}>Selected Examiner
                                    <Button size={'small'} type="link" onClick={onEditExaminer}>Edit</Button>

                                    <DeleteOutlined className={'ml-1 text-red-600'} onClick={onDeleteExaminer} />
                                </p>
                                <p className={'font-bold'}>{selectedExaminer.institution_name}
                                    {selectedExaminer.default_examiner && <Badge text={'default'} color={'green'} className={'ml-2'} />}
                                </p>
                                <p className={'font-light'}>{selectedExaminer.description}</p>
                                <p className={'font-light'}>{selectedExaminer.responsible_person_name}</p>
                                <p className={'font-light'}>{selectedExaminer.responsible_person_email}</p>
                                <p className={'font-light'}>{selectedExaminer.responsible_person_phone}</p>
                            </div> :
                            <p >No Examiner Selected</p>}
                            </>}
                            </>}

                        </div>
                        <div className={'w-1/2 grid grid-cols-1 md:grid-cols-4 gap-x-5'}>
                        <p className={'text-gray-700 text-base font-semibold mb-2 whitespace-nowrap'}>Exam Details
                            {!editingMode && examData && <EditOutlined className={'mx-1 text-fw-orange'} onClick={() => setEditingMode(prev => !prev)} />}
                            {examData && 
                            <>
                            <Badge text={'existing exam found'} color={'green'} className={'ml-2'} />
                            <DeleteOutlined className={'ml-1 text-red-600'} onClick={onDeleteExam} />
                            </>}
                        </p>
                        {examLoading ? <div className={'min-h-full w-full grid place-items-center'}><Spin /></div> :
                         !editingMode && examData ?
                         <div className={'col-span-1 md:col-span-4 flex flex-col bg-gray-100 rounded-sm shadow-sm p-4'}>
                            <p className={'font-bold'}>Location: {examData?.location}</p>
                            <p className={'font-light'}>Date: {examData?.date ? moment(examData?.date).format('DD.MM.YYYY HH:mm') : 'N/A'}</p>
                            <p className={'font-light'}>Self Examined: {examData?.self_examined ? 'Yes' : 'No' }</p>
                            <p className={'font-light'}>Is released: {examData?.is_released ? 'Yes' : 'No' }</p>
                            <p className={'font-light'}>Message: {examData?.message}</p>
                         </div> :
                         <>
                            <Form.Item 
                                className={'col-span-1 md:col-span-4'}
                                name="location"
                                rules={[{ required: true, message: 'Location is required!' }]}
                            >
                                <Input placeholder={'Exam venue and city'}/>
                            </Form.Item>
                            
                            <Form.Item 
                                className={'col-span-1 md:col-span-2'}
                                name="date"
                                rules={[{ required: true, message: 'Exam date is required' }]}
                            >
                                <DatePicker disabledDate={getDisabledDate} placeholder={'Exam date'} className={'w-full'} />
                            </Form.Item>


                            <Form.Item 
                                className={'col-span-1 md:col-span-2'}
                                name="date"
                                rules={[{ required: true, message: 'Exam time is required' }]}
                            >
                                <TimePicker format={'HH:mm'} showNow={false} minuteStep={15} use12Hours showSecond={false} placeholder={'Exam Time'} className={'w-full'} />
                            </Form.Item>


                            <Form.Item 
                                className={'col-span-1 md:col-span-4 row-span-2'}
                                name="message"
                                label={'Message to exam participants'}
                            >
                                <TextArea placeholder={'Invitation Message to job seekers (optional)'}  rows={4} />
                            </Form.Item>

                            <Form.Item 
                                className={'col-span-1 md:col-span-4 row-span-2'}
                                rules={[{ required: true, message: 'Please ackowledge the conditions' }]}
                            >
                                {acknowledgeError && <p className={'text-red-500 text-sm font-semibold mb-2'}>{acknowledgeError}</p>}
                                <Checkbox checked={acknowledged} onChange={(value) => {
                                    setAcknowledged(value.target.checked)
                                    setAcknowledgedError('')
                                    }} className={'text-gray-600'}>
                                    I acknowledge that i have to book an appointment with the examiner separately and the exam date and practicalities are already agreed upon with the examiner before sending exam invites. 
                                </Checkbox>
                            </Form.Item>
                            </>}
                        </div>
                    </Form>
                    {selectedExaminer && showEditModal &&
                        <ExaminerModal 
                            selectionId={selectionId} 
                            editingMode={true} 
                            examiner={selectedExaminer}
                            onClose={onCloseEditModal} 
                            onSubmit={onSubmitEditModal}                     
                        />
                    }
                    {showAddModal && 
                        <ExaminerModal 
                            selectionId={selectionId} 
                            editingMode={false} 
                            onClose={onCloseAddModal} 
                            onSubmit={onSubmitAddModal}                     
                        />
                    }
            </div>
    )
})

ExamSetupModal.displayName = 'ExamSetupModal'

export default ExamSetupModal