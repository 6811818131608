import { IMotivationAnswer, IMotivationQuestion } from "../types";
import Request from "./Request"

export const setupMotivation = (selectionId: number, body: {questions: number[], deadline: string}) => {
    return Request('POST', `/selection/${selectionId}/motivation/`, body);
}

export const editMotivation = (selectionId: number, body: {id: number, questions: number[], deadline: string}) => {
    return Request('POST', `/selection/${selectionId}/motivation/`, body);
}
  
export const getMotivation = (selectionId: number,) => {
    return Request('GET', `/selection/${selectionId}/motivation/`);
}
  
export const getMotivationQuestions = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/motivation/questions/`);
}
  
export const addMotivationQuestion = (selectionId: number, body: Omit<IMotivationQuestion, 'id'>) => {
    return Request('POST', `/selection/${selectionId}/motivation/questions/`, body);
}

export const getMotivationAnswers = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/motivation/answers/`);
}

export const addMotivationAnswer = (selectionId: number, body: Partial<IMotivationAnswer>) => {
    return Request('POST', `/selection/${selectionId}/motivation/answers/`, body);
}

export const rateMotivationAnswer = (selectionId: number, body: {
    user: number,
    question: number,
    rating: number
}) => {
    return Request('POST', `/selection/${selectionId}/motivation/answers/`, body);
}

export const getMotivationFilter = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/motivationfilter/`);
}

export const setMotivationFilter = (selectionId: number, body: {rating: number}) => {
    return Request('POST', `/selection/${selectionId}/motivationfilter/`, body);
}

export const releaseMotivation = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/motivation/release/`, {"release": true});
}

export const finishMotivationWagon = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/motivation/finish/`, {"finish": true});
}

