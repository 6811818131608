import Request from "./Request"
interface IOffer {
    job: number
    user: number
    message: string
}
interface IOfferResponse {
    job: number
    has_accepted: boolean
    has_refused: boolean
}

export const SendOffer = (data:IOffer)=> {
    return Request('POST', `/hire/`, data);
}
export const GetOffers = (id:number) => {
    return Request('GET', `/employment_offer/${id}`);
} 
export const OfferResponse = (data:IOfferResponse)=> {
    return Request('POST', `/offer_response/`, data);
}

export const GetOffersEmplpoyee = () => {
    return Request('GET', `/employment_offer/`);
}