import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectionStats from "../../components/selection/SelectionStats"
import PlayCircleFilled from "@ant-design/icons/PlayCircleFilled"
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import { getSelection } from "../../services/SelectionService";
import { useParams } from "react-router-dom";
import { classNames } from "../../Utils";
import { ISelection, ISelectionWagon } from "../../types";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Spin, Tooltip } from "antd";
import InitialFilterModal from "../../components/selection/IntialFilterModal";
import Wagon from "../../components/selection/Wagon";
import MotivationWagonDetail from "./motivation/MotivationWagonDetail";
import ScreeningWagonDetail from "./screening/ScreeningWagonDetail";
import ExamWagonDetail from "./exam/ExamWagonDetail";
import FWSubHeader from "../../components/base/FWSubHeader";
import { useNavigate } from "react-router-dom";
import NewWagon from "./NewWagon";
import useAlert from "../../components/toast/useAlert";
import FinishedWagonDetail from "./FinishedWagonDetail";

const SelectionRoot = () => {
    const {id} = useParams()
    const {state} = useLocation()
    const navigate = useNavigate()
    const [selection, setSelection] = useState<ISelection>()
    const [applicationIsClosed, setApplicationIsClosed] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [showIntialModal, setShowIntialModal] = useState(false)
    const [showNewWagon, setShowNewWagon] = useState(false)
    const [selectionStarted, setSelectionStarted] = useState(false)
    const [currentWagon, setCurrentWagon] = useState('')
    const [currentWagonIsComplete, setCurrentWagonIsComplete] = useState(false)
    const {addAlert} = useAlert()

    useEffect(() => {
        if(state?.job && moment(state?.job?.deadline).isBefore(moment(), 'day')) {
            setApplicationIsClosed(true)
        }
    }, [state])

    const styleClass = {
        root: classNames(
            'min-h-[calc(100vh_-_56px)] w-full flex flex-col bg-white'
        ),
        wagons: (started: boolean) => classNames(
            'min-h-[208px]', 
            'rounded-lg', 
            !started ? 'my-auto flex justify-center items-center' : 'mt-[10vh]', 
            'w-full', 
            'border-2', 
            'border-dotted', 
            'border-fw_wagon_border',
            'mb-4'
        )
    }

    const fetchSelection = useCallback(async () => {
        setLoading(true)
        getSelection(id).then(res => { 
            const data = res?.data ?? undefined
            setSelection(data)
            setSelectionStarted(data?.train?.length > 0 ?? false)
            if(data?.train?.length > 0) {
                setCurrentWagon(data.train.slice(-1)[0].wagon?.type)
                setCurrentWagonIsComplete(!data.train.slice(-1)[0].in_progress)
            }
        }).catch((err:any) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [id])

    useEffect(() => {
        fetchSelection()
    }, [fetchSelection, reload])

    const onSubmitClosed = () => {
        setShowIntialModal(false)
        setReload(prev => !prev)
    }

    const onCreateNew = () => {
        setShowNewWagon(true)
    }

    const onCancelClosed = () => {
        setShowIntialModal(false)
    }

    const onSubmitNew = () => {
        setShowNewWagon(false)
        setReload(prev => !prev)
    }

    const onCancelNew = () => {
        setShowNewWagon(false)
    }

    const isLastWagonClosed = useMemo(() => {
        if(selectionStarted && selection) {
            const index = selection.train.length - 1
            return !selection.train[index].in_progress
        }
    }, [selectionStarted, selection])

    const startVetting = () => {
        if(applicationIsClosed) {
           setShowIntialModal(true)
        } else {
            addAlert({type: 'error', title: 'Application is still open', description: 'You cannot start vetting until the application is closed', autoClose: true})
        }
    }
    
    return(<div className={styleClass.root}>
            <FWSubHeader title={`Job: ${state?.job.title}`} className={'p-4 pl-6 text-sm'}>
                <div className={'flex items-center ml-6'}>
                    <ArrowLeftOutlined className={'mr-2 text-fw-orange font-medium scale-100 cursor-pointer'} onClick={() => navigate(-1)} />
                    <p className={'text-sm text-gray-500 self-center'}>{`Client: ${selection?.business?.name ?? ''}`}</p>
                </div>
            </FWSubHeader>
            <div className={'p-6 pt-0'}>
            {loading ? <div className={'w-full h-[140px] grid place-items-center'}><Spin /></div> :
            selection ? <SelectionStats selection={selection} applicationIsClosed={applicationIsClosed} /> : <p className={'text-fw-orange text-sm'}>No Data Found. Please try again!</p>}
            <p className={'text-fw-blue font-bold text-lg mt-6 mb-1'}>Vetting Process Steps</p>
            <p className={'text-gray-500 text-sm mb-2'}>A step: a collection that holds candidates that reach the specific step of the recruitment process</p>
            <div className={styleClass.wagons(selectionStarted)}>
            {loading ? <div className={'w-full min-h-full grid place-items-center'}><Spin /></div> : !selectionStarted ? 
                <div className={'flex flex-col gap-4'}>
                    <p className={`font-semibold text-xl ${!applicationIsClosed ? 'text-gray-200' : 'text-fw-blue'} text-center`}>Start Vetting Process
                        {!applicationIsClosed && <Tooltip className={'text-gray-400 pl-2'} title={'Starting Vetting is allowed once job application period is closed'}>
                            <InfoCircleOutlined />
                        </Tooltip>}
                    </p> 
                    <PlayCircleFilled disabled={!applicationIsClosed}  onClick={startVetting} className={`text-7xl ${applicationIsClosed ? 'text-fw-orange' : 'text-gray-400'}`} />
                </div> : 
                <div className={'flex flex-col md:flex-row md:items-center md:flex-wrap h-full p-6 gap-[94px]'}>
                    {selection?.train.map((wagon:ISelectionWagon, key) => <Wagon key={key} selection={selection} selectionWagon={wagon} first={key === 0} onReload={() => console.log('')} />)}
                    {isLastWagonClosed && selection && selection?.in_progress &&
                        <Wagon selection={selection} selectionWagon={{} as ISelectionWagon} onCreateNew={onCreateNew} onReload={() => setReload(prev => !prev)} />
                    }
                </div>}
            </div>
            {showIntialModal && selection &&
                <InitialFilterModal
                    onSubmit={onSubmitClosed}
                    onCancel={onCancelClosed}
                    selectionId={selection.id}
                    totalApplicants={selection.number_of_applicants}
                />}
            {showNewWagon && selection &&
                <NewWagon
                    selection={selection}
                    onClose={onCancelNew}
                    onSubmit={onSubmitNew}
                />}
            
            {currentWagon === 'Motivation' && selection && !currentWagonIsComplete &&
                <MotivationWagonDetail
                    selection={selection}
                    reloadParent={() => setReload(prev => !prev)}
                />
            }
            {currentWagon === 'Individual Screening' && selection && !currentWagonIsComplete &&
                <ScreeningWagonDetail
                    selectionId={selection.id}
                    reloadParent={() => setReload(prev => !prev)}
                />
            }
            {currentWagon === 'Exam' && selection && !currentWagonIsComplete &&
                <ExamWagonDetail
                    selectionId={selection.id}
                    job={state?.job}
                    reloadParent={() => setReload(prev => !prev)}
                />
            }

            {currentWagon === 'Finished' && selection &&
                <FinishedWagonDetail 
                    selection={selection}               
                />
            }
            </div>
        </div>
    )
}

export default SelectionRoot