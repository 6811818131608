import { Form, Input, DatePicker, Checkbox, Button, Spin, InputNumber } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../../../components/base/Modal'
import useAlert from '../../../components/toast/useAlert'
import { getMotivationQuestions, addMotivationQuestion, setupMotivation, editMotivation } from '../../../services/MotivationService'
import { IMotivation, IMotivationQuestion } from '../../../types'

interface IMotivationSetupModal {
    selectionId: number
    onClose: () => void
    onSubmit: () => void
    motivation?: IMotivation
}

const MotivationSetupModal:React.FC<IMotivationSetupModal> = ({selectionId, onSubmit, onClose, motivation}) => {
    const [form] = Form.useForm();
    const [askCoverLetter, setAskCoverLetter] = useState(false)
    const [selectedQuestions, setSelectedQuestions] = useState<number[]>([])
    const [newQuestion, setNewQuestion] = useState('')
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addQuestionLoading, setAddQuestionLoading] = useState(false)
    const [questions, setQuestions] = useState<IMotivationQuestion[]>([])
    const [answersLength, setAnswersLength] = useState(250)
    const {addAlert} = useAlert()

    const getJobIntialValues =()=> {
        return motivation ? {
            deadline: moment(motivation?.deadline),
        } : {}
    }

    const getDisabledDate = (currentDate: moment.Moment) => {
        if(currentDate.isSameOrBefore(moment().add(3, 'days'))) {
          return true
        } 
        return false
    }

    useEffect(() => {
        if(motivation) {
            if(motivation.questions.includes(1)) {
                setAskCoverLetter(true)
            } else {
                setSelectedQuestions(motivation?.questions ?? [])
            }
        }
    }, [motivation])

    useEffect(() => {
        setLoading(true)
        getMotivationQuestions(selectionId).then((res) => {
            const clone = [...res?.data]
            clone.shift()
            setQuestions(clone)
        }).catch(err => console.log(err)).finally(() => {
            setLoading(false)
        })
    }, [selectionId, reload])

    const toggleSelection = (id: number) => {
        if(selectedQuestions.includes(id)) {  
            setSelectedQuestions(prev => prev.filter(index => index!== id))
        } else {
            if(selectedQuestions.length === 3) {
                addAlert({title: 'error', type: 'error', description: 'max allowed questions is 3', autoClose: true})
                return
            } else {
                setSelectedQuestions(prev => [...prev, id])
            }
        }
    }

    const addNewQuestion = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if(newQuestion) {
            setAddQuestionLoading(true)
            addMotivationQuestion(selectionId, {
                question: newQuestion,
                description: '',
                answer_length: answersLength,
                is_cover_letter: false,
                is_user_input: true
            }).then((res) => {
                setNewQuestion('')
                setAnswersLength(250)
                setReload(prev => !prev)
            }).finally(() => setAddQuestionLoading(false))
            
        }   
    }

    const isDisabled = useMemo(() => {
        return askCoverLetter || selectedQuestions.length === 3
    }, [askCoverLetter, selectedQuestions])

    const submitModal = () => {
        form.submit()
    }

    const getQuestion = () => {
        
        if(!askCoverLetter && selectedQuestions?.length === 0) {
            addAlert({title: 'error', type: 'error', description: 'At least 1 question is required', autoClose: true})
            return undefined
        }

        if(askCoverLetter) {
            return [1]
        }

        return selectedQuestions
    }

    const onSaveQuestions = (values: any) => {
        if(getQuestion()) {
            if(motivation) {
                editMotivation(selectionId, {
                    id: motivation.id,
                    deadline: values.deadline.format('YYYY-MM-DD'),
                    questions: getQuestion() ?? []
                }).then((res) => {
                    onSubmit()
                    onClose()
                })
            } else {
                setupMotivation(selectionId, {
                    deadline: values.deadline.format('YYYY-MM-DD'),
                    questions: getQuestion() ?? []
                }).then((res) => {
                    addAlert({title: 'success', type: 'success', description: 'Motivation created successfully!', autoClose: true})
                    onSubmit()
                }).catch(err => {
                    addAlert({title: 'error', type: 'error', description: 'Motivation creation failed. Please Try again', autoClose: true})
                })
            }
        }
    }

    return(
        <Modal
         title='Setup Motivation'
         onCancel={onClose}
         onSubmit={submitModal}
         className={'min-h-[70%] w-[60%]'}
        >
            <Form
                name="addExam"
                className={'w-3/4 flex flex-col'}
                form={form}
                autoComplete="off"
                onFinish={onSaveQuestions}
                initialValues={getJobIntialValues()}
            >   
                <Form.Item 
                    className={'w-1/2'}
                    name="deadline"
                    rules={[{ required: true, message: 'Deadline is required' }]}
                >
                    <DatePicker disabledDate={getDisabledDate} placeholder={'Deadline'} className={'w-full'} />
                </Form.Item>

                <Checkbox checked={askCoverLetter} onChange={() => {
                    setAskCoverLetter(prev => !prev)
                    setSelectedQuestions([])
                }}>
                    <p className={`${askCoverLetter ? 'text-fw-orange' : 'text-gray-600'} text-sm`}>Ask Cover Letter only (1000 characters)</p>
                </Checkbox>
                <p className='text-center'>OR</p>

                <p className={'text-gray-700 font-medium mb-1'}>Select Questions (Max of 3)</p>
                {loading ? <Spin /> : questions.length >0 && questions.map((question) => (
                    <Checkbox disabled={isDisabled && !selectedQuestions.includes(question.id)} className={'mb-2'} key={question.id} checked={selectedQuestions.includes(question.id)} onChange={() => toggleSelection(question.id)}>
                        <p className={`${selectedQuestions.includes(question.id) ? 'text-fw-orange' : 'text-gray-600'} text-sm`}>{question.question} ({question.answer_length} characters)</p>
                    </Checkbox>
                ))}

                <p className={'text-gray-700 font-medium mb-1'}>Add a new question</p>
                <Form.Item 
                    className={'flex items-center'}
                >   
                    <p className={'text-gray-700 text-xs mb-1'}>Answer character length (max 1000)</p>
                    <InputNumber value={answersLength} onChange={(val) => setAnswersLength(val)} type={'number'} disabled={isDisabled || addQuestionLoading} placeholder={'answer length'} min={100} max={1000} className={'w-36 mr-2'}/>
                    <Input onPressEnter={!isDisabled ? addNewQuestion : undefined}  disabled={addQuestionLoading} className={'w-1/2'} value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)}  placeholder={'Add new question'}/>
                    <Button disabled={isDisabled || addQuestionLoading} className={'-ml-0.5'} onClick={addNewQuestion}>Add</Button>
                </Form.Item>
                   
            </Form>
        </Modal>
    )
}

export default MotivationSetupModal