import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import KeyOutlined from '@ant-design/icons/lib/icons/KeyOutlined';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import { Input, Button, Alert, Form } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, ILoginDetails } from '../../context/AuthContext';
import { baseAddress } from '../../services/Request';
import { useQuery } from '../../Utility';
import useAlert from '../toast/useAlert';

interface IResetPassword  {
    onSubmit? : () => void
    onLogin? : () => void
}

const ResetPassword = ({onSubmit, onLogin}:IResetPassword) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [emailLoading, setEmailLoading] = useState<boolean>(false);
    const [codeLoading, setCodeLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [emailVerified, setEmailVerified] = useState<boolean>(false);
    const [codeVerified, setCodeVerified] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const authContext = useAuth();
    const {addAlert} = useAlert();
    const navigate = useNavigate();
    const query = useQuery()

    useEffect(() => {
        if(query.get('email') && query.get('token')) {
            setEmailVerified(true)
            setCodeVerified(true)
        }
    }, [query])

    const onVerifyEmail = (values:any)=> {
        if(values.email) {
            setEmailLoading(true)
            axios.post(baseAddress + '/passwordreset/', {
                email: values.email,
             })
             .then((response:any) => {
                setEmailVerified(true);
                setEmail(values.email);
             }, (error:any) => {
                 console.log(error);
             }).catch(err => setError(JSON.stringify(err?.response?.data)))
             .finally(() => {
                setEmailLoading(false);
             })
        }
    }

    const onVerifyCode = (values:any)=> {
        if(values.code) {
            setCodeLoading(true)
            axios.post(baseAddress + '/passwordreset/validate_token/', {
                token: values.code
             })
             .then((response:any) => {
                 if(response.status === 200) {
                    setCodeVerified(true);
                    localStorage.setItem('resetCode', values.code);
                 }
             }, (error:any) => {
                 console.log(error);
             }).catch(err => console.log(err))
             .finally(() => {
                setCodeLoading(false);
             })
        }
    }

    const onFinish = (values:any)=> {
        // if(!query.get('token') || !query.get('email') || localStorage.getItem('resetCode') === null) {
        //     addAlert({type: 'error', title: 'error', description: 'Url is not valid. Try again', autoClose:false})
        //     return;
        // }
        if (values.password !== values.confirmpassword) {
            addAlert({type: 'error', title: 'Password Incorrect', description: 'Password doesnot match', autoClose:true})
            return;
        }
        setLoading(true)
        if(values.password && values.confirmpassword && values.password === values.confirmpassword) {
            setCodeLoading(true)
            axios.post(baseAddress + '/passwordreset/confirm/', {
                password: values.password,
                token: query.get('token') ? query.get('token') : localStorage.getItem('resetCode')
             }, {timeout: 5000, timeoutErrorMessage: 'Request timed out'})
             .then((response:any) => {
                 addAlert({type: 'success', title: 'success', description: 'Password changed successfully!', autoClose:true})
                 const data:ILoginDetails = {email: email ? email : (query.get('email') ?? ''), password:values.password, onSuccess, onError}
                 authContext.logIn(data);
             }).catch(err => {
                console.log(err)
                addAlert({type: 'error', title: 'error', description: 'Reset password failed. Try again with your correct email', autoClose:false})
            }).finally(() => {
                // navigate('/')
                onSubmit ? onSubmit() : navigate('/')
                setCodeLoading(false);
             })
        }
    }

    const onSuccess = ()=> {
        setLoading(true);
        setTimeout(()=> {
            // navigate('/dashboard')
            onSubmit ? onSubmit() : navigate('/dashboard')
        }, 300)
    }

    const onError = (err:any)=> {
        addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose:true})
        setLoading(true);
    }

    const onLoginClick = () => {
        // navigate('/')
        onLogin ? onLogin() : navigate('/')
    }

    return (
            <div className={'flex flex-col items-center'}>  
                {!emailVerified && !codeVerified && <Form
                    name="confirm-email"
                    onFinish={onVerifyEmail}
                    autoComplete="off"
                    className={'w-full'}
                    >
                    
                    <Form.Item 
                        className={'mb-4 mt-4 md:mt-12'}
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'Please enter a valid email'}]}
                    >
                        <Input placeholder={'email'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
                    <Button loading={emailLoading} disabled={emailLoading} block type="primary" htmlType="submit">Verify Email</Button>
                </Form>}

                {emailVerified && !codeVerified && <Form
                    name="confirm-code"
                    onFinish={onVerifyCode}
                    autoComplete="off"
                    className={'w-full'}
                    >
                    <p className={'text-gray-600 text-sm py-2'}>An email has been sent to you, Follow the url on your email to finish your password reset or Add the verification code below.</p>
                   
                    <p className={'pt-1 text-xs pl-2 text-fw-blue font-medium cursor-pointer'}>
                        Email Not Received? (please wait at least 10 mins)
                        <span className={'text-fw-orange block w-full text-center'} onClick={() => setEmailVerified(false)}>Send Email Again!</span>
                    </p>
                    <Form.Item 
                        className={'mb-4 mt-4 md:mt-8'}
                        name="code"
                        rules={[{ required: true, message: 'Please enter the emailed code' }]}
                    >
                        <Input placeholder={'enter emailed code here'} prefix={<KeyOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
                    <Button loading={codeLoading} disabled={codeLoading} block type="primary" htmlType="submit">Verify Code</Button>
                </Form>}

                {emailVerified && codeVerified && <Form
                    name="password-reset"
                    onFinish={onFinish}
                    autoComplete="off"
                    className={'w-full'}
                >                
                   {query.get('email') && <Form.Item 
                        className={'mb-4 mt-4 md:mt-12'}
                    >
                        <Input disabled={true} placeholder={'email'} value={query.get('email') ?? undefined} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>}
                    <Form.Item
                        className={'mb-4 mt-4'}
                        name="password"
                        rules={[{ required: true, message: 'Please confirm your password!'},
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Password should be at least 8 characters!'));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || isNaN(Number(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Password can't be all numeric!"));
                            },
                        })
                        ]}
                    >
                        <Input.Password placeholder={'password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
    
                    <Form.Item
                        name="confirmpassword"
                        rules={[{ required: true, message: 'Please confirm your password!'},
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Password should be at least 8 characters!'));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || isNaN(Number(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Password can't be all numeric!"));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value ||(getFieldValue('password') === getFieldValue('confirmpassword'))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Passwords must match!"));
                            },
                        }),
                        ]}
                    >
                        <Input.Password placeholder={'confirm password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
    
                    <Button loading={loading} disabled={loading} block type="primary" htmlType="submit">Reset Password</Button>
                </Form>}
                <div className={'w-full flex flex-row items-center mt-1 justify-center'}>
                        <p className={'text-xs mt-1 text-gray-400'}>Remember password?</p>
                        <p className={'pt-1 text-xs pl-2 text-fw-orange font-medium cursor-pointer'} onClick={onLoginClick}>Login</p>
                    </div>
                {error ? (
                    <Alert message={error} type="error" closable afterClose={() => setError('')} />
                ) : null}
            </div>
        );
}

export default ResetPassword;