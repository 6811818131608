import { IEducationFilterPOST, IExamFilterPOST, IExperienceFilterPOST, IMotivationFilterPOST, IScreeningFilterPOST } from "../components/selection/Filter/FilterHelper";
import Request from "./Request"

export const getAllSelections = () => {
  return Request('GET', '/selection/');
} 

export const getSelection = (id: number) => {
  return Request('GET', `/selection/${id}/`);
} 

export const getSelectionWagons = (id: number) => {
  return Request('GET', `/selection/${id}/wagons`);
} 

export const getSelectionAutoStat = (selectionId: number) => {
  return Request('POST', `/selection/${selectionId}/initial_filter/`, {});
} 

export const startIntialFiltering = (selectionId: number) => {
  return Request('POST', `/selection/${selectionId}/start_vetting/`, {});
} 

export const getFilters = (selectionId: number) => {
  return Request('GET', `/selection/${selectionId}/filters/`);
} 

export const saveEducationFilters = (selectionId: number, body: IEducationFilterPOST) => {
  return Request('POST', `/selection/${selectionId}/educationfilter/`, body);
}

export const saveExperienceFilters = (selectionId: number, body: IExperienceFilterPOST) => {
  return Request('POST', `/selection/${selectionId}/experiencefilter/`, body);
}

export const saveMotivationFilters = (selectionId: number, body: IMotivationFilterPOST) => {
  return Request('POST', `/selection/${selectionId}/motivationfilter/`, body);
}

export const saveScreeningFilters = (selectionId: number, body: IScreeningFilterPOST) => {
  return Request('POST', `/selection/${selectionId}/individualscreeningfilter/`, body);
}

export const saveExamFilters = (selectionId: number, body: IExamFilterPOST) => {
  return Request('POST', `/selection/${selectionId}/examfilter/`, body);
}

export const finishSelection = (selectionId: number, body: {finish: boolean}) => {
  return Request('POST', `/selection/${selectionId}/finish_selection/`, body);
}

export const exportFinishedCandidates = (selectionId: number) => {
  return Request('POST', `/selection/${selectionId}/export_excel/`, {download: true}, undefined, 'blob');
}

