
import moment from 'moment';
import React from 'react';
import FWBadge from '../../components/base/FWBadge';
import Page from '../../components/base/Page';

const EmploymentPage = () => {
    return (
        <Page>
            <h2>Employment Page</h2>
        
            <p className={`text-gray-500 text-sm mb-4`}>JOB: Sales Representative</p>
            <div className={`bg-white cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto max-w-lg px-6 py-4`} >
                <div className={'flex items-center justify-between'}>
                    <div className='flex flex-col'>    
                        <p className={`text-gray-500 font-medium text-base`}>Henok Alemayehu</p>
                        <p className={`text-gray-400 text-sm`}>Accepted offer on {moment().format('DD-MM-yy')}</p>
                    </div>
                    <FWBadge text={`Offer Accpted`}  className={'bg-fw-orange text-white whitespace-nowrap'} />
            </div>
            </div>
        </Page>
    );
};

export default EmploymentPage;