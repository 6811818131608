import React from 'react'

interface IProgressBar {
    value: number
    total: number
    color: 'green' | 'orange' | 'white'
    label?: string
    showPercentage?: boolean
}

const ProgressBar:React.FC<IProgressBar> = ({value, total, color, label, showPercentage = false}) => {
    const getWidth = () => {
        if(!value || !total) {
            return {width: '100%'}
        } 
        return { width: `${Math.min((value / total), 1) * 100}%`}
    }

    return(
        <div className={'w-full flex flex-col'}>
            {label && <p className={'text-gray-600 text-xs mb-0.5'}>{label}</p>}
            <div className={'flex items-center'}>
                <div className={`rounded-full w-full h-1.5 relative ${color === 'white' ? 'bg-gray-300 opacity-75' : 'bg-fw-cv-gray'}`}>
                    <div style={getWidth()} className={`h-1.5 absolute top-0 left-0 rounded-full ${color === 'white' ? 'bg-white' : color === 'green' ? 'bg-fw-green' : 'bg-fw-orange'}`} />
                </div>
                <span className={'text-xs text-gray-500 pl-2'}>{showPercentage ? `${Number((value / total) * 100).toFixed(0)}%` : value}</span>
            </div>
        </div>
    )
}

export default ProgressBar