import React, { useEffect, useState } from 'react';
import CheckSquareOutlined from '@ant-design/icons/lib/icons/CheckSquareOutlined';
import FileAddOutlined from '@ant-design/icons/lib/icons/FileAddOutlined';
import Button from 'antd/lib/button';
import { useLocation, useNavigate } from 'react-router-dom';
import JobListItem from '../../components/jobs/JobListItem';
import { applyToJob, getOrganizationJobs, getUserAppliedJobs } from '../../services/JobService';
import { IJob } from '../../types';
import { useAuth } from '../../context/AuthContext';
import { Empty } from 'antd';
import useAlert from '../../components/toast/useAlert';
import PageSpinner from '../../components/base/PageSpinner';
import FWSubHeader from '../../components/base/FWSubHeader';
import Page from '../../components/base/Page';
import { Pagination } from 'antd';
import type { PaginationProps } from 'antd';

const MyJobs = ()=> {

    const {currentUser} = useAuth();
    const {addAlert} = useAlert();
    const location = useLocation();
    const showAllJobs = location.state?.showAllJobs;
    const [isStaff, setIsStaff] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [limit, setLimit]  = useState(10)
    const [showMore, setShowMore] = useState(false)
    const [pages, setPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(()=> {
        if(currentUser?.is_staff || !!showAllJobs) {
            setIsStaff(true);
            fetchOrgJobs();
        } else {
            fetchUserAppliedJobs()
        }
    }, [currentUser, showAllJobs, reload, limit])

    const navigate = useNavigate();
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        console.log(current, pageSize);
      };

    const onChange: PaginationProps['onChange'] = page => {
        if(page > currentPage)
            setLimit((prev) => prev+10)
        else
            setLimit((prev) => prev-10)
        setCurrentPage(page)
    };

    const fetchOrgJobs = ()=> {
        setLoading(true);
        getOrganizationJobs(limit)
        .then(res =>{
            if(res?.data?.count !==0){
                setPages(Math.ceil(res?.data?.count /10))
                setShowMore(true)
            }
            if(res?.data?.jobs && Array.isArray(res?.data?.jobs)) 
                setJobs(res?.data?.jobs);

        }).catch(err => console.log(err))
        .finally(()=> setLoading(false))
    }

    const fetchUserAppliedJobs = ()=> {
        setLoading(true);
        getUserAppliedJobs()
        .then(res => {
            if(res?.data && Array.isArray(res?.data)) {
                setJobs(res.data.reverse());
            }
        })
        .catch(err => console.log(err))
        .finally(()=> setLoading(false))
    }

    const onNewJobPost = ()=> {
        navigate('/dashboard/jobs/create')
    }

    const getWidth = () => {
       const count = jobs?.length ?? 1;
       return `${(count/10)*100 > 100 ? 100 : (count/10)*100}%`; 
    }

    const onJobApply = (jobId:number, onSuccess:()=>void)=> {
        if(!currentUser?.is_staff && jobId) {
            applyToJob(jobId)
            .then(res => {
                setReload(prev => !prev)
                addAlert({type:'success', title: 'Success', description: 'Applied to Job successfully', autoClose:true})
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    console.log(err.response.data) 
                    addAlert({type:'error', title: 'error', description: JSON.stringify(err.response.data)})
                }
            }).finally(() => {
                onSuccess()
            })
        }
    }

    return(
        <Page>
        <FWSubHeader title={showAllJobs ? 'Eligible Jobs' : currentUser?.is_staff ? 'Jobs Posted': 'Jobs Applied'}>
            {(currentUser !== null && currentUser?.is_staff) ? <Button size={'small'} className={'flex items-center ml-4'} onClick={onNewJobPost} type="primary">
                Post New Job <FileAddOutlined className={'ml-2'} />
            </Button> : null}
        </FWSubHeader>
        <div className={'mt-6'}>
            {!showAllJobs && <div className={'flex flex-row flex-wrap'}>
                <div className={'rounded-md md:rounded-3xl bg-white w-32 h-24 md:w-48 md:h-32 p-3 md:p-5 flex flex-col justify-between'}>
                    <div className={'flex items-center'}>
                            <CheckSquareOutlined className={'mr-2 font-semibold text-fw-orange'} />
                        <p className={'font-semibold text-gray-600'}>My Jobs</p>
                    </div> 
                    <p className={'text-2xl md:text-4xl text-gray-900 font-medium '}>{jobs?.length}</p>
                    <div className={'bg-green-100 w-full rounded-md'}>
                        <div style={{width: getWidth()}} className={'h-1.5 rounded-md bg-fw-green w-1/3'} />
                    </div>
                </div>
                
            </div>}

            <div className={'mt-6'}>
                {loading ? <PageSpinner /> :
                (jobs?.length >0 ? jobs.map((job,i) => <JobListItem key={i} job={job} onApply={onJobApply} isStaff={!!(currentUser?.is_staff)} userMyJobs={!currentUser?.is_staff &&  !showAllJobs} />) : <Empty description={isStaff ? 'No jobs found' : 'Not applied to any job yet, Apply to some ✍🏻' } />)}
                {/* {showMore && <Button id={'endofJobsList'} type="default" onClick={() => setLimit((prev) => prev+10)} size="large" className="rounded-lg mt-5">See More Listing</Button>} */}
                {showMore && <Pagination simple onShowSizeChange={onShowSizeChange} defaultCurrent={1} pageSize={1} total={pages} onChange={onChange}/>}
            </div> 
        </div>
        </Page>
    )
}

export default MyJobs;