import { Steps, Form, Input, DatePicker, Button, Empty, Alert } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from "../../context/AuthContext";
import TextArea from "antd/lib/input/TextArea";
import { ICVCertification } from "../../types";
import useAlert from "../../components/toast/useAlert";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import { addCVCertification, deleteCVCertification, getCVCertifications, updateCVCertification,  } from "../../services/CVService";
import PublishBtn from "../../components/cv/PublishBtn";
import { noteToJobSeeker } from "../../Utility";
import Bullet from "../../components/base/Bullet";
import PageSpinner from "../../components/base/PageSpinner";
import Page from "../../components/base/Page";
import FWSubHeader from "../../components/base/FWSubHeader";

const CVEdit5 = ()=> {
    const {Step} = Steps;
    const {cvId} = useAuth();
    const navigate = useNavigate();
    const {addAlert} = useAlert();
    const monthFormat = 'YYYY/MM';

    const [cvCertifications, setCVCertifications] = useState<ICVCertification[]>([]);
    const [selectedCertification, setselectedCertification] = useState<ICVCertification>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [isPresent, setIsPresent] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(()=> {       
        if(cvId) {
            setLoading(true);
            getCVCertifications(cvId)
            .then(res => {
                const educations:ICVCertification[] = res?.data;
                setCVCertifications(educations)
            })
            .finally(()=> setLoading(false))
        }
    }, [cvId, reload])

    const onEditClicked = (id?:number)=> {
        if(id) {
            setIsCreating(false)
            const selected = cvCertifications.find(cert => cert.id === id);
            if(selected) {
                const {start_date, end_date, ...rest} = selected;
                if(!end_date){setIsPresent(true)}
                const selectedCert = {...rest, start_date: moment(start_date), end_date: end_date ? moment(end_date) : undefined}
                setselectedCertification(selectedCert)
            }
        }
    }

    const onSubmitEducation = (values:ICVCertification)=> {
        if(cvId) {
            setLoading(true)
            const formattedStartDate =  moment(values?.start_date).format('YYYY-MM-DD');
            const formattedEndDate = (values.end_date && !isPresent) ? moment(values?.end_date).format('YYYY-MM-DD') : undefined;
            const data= {...values, start_date: formattedStartDate, end_date: formattedEndDate};
            const api = (!isCreating && selectedCertification?.id) ? updateCVCertification(cvId, selectedCertification?.id, data) : addCVCertification(cvId,data );
                        
            api
            .then(res => {
                addAlert({type: 'success', title: 'success', description: `${isCreating ? 'added' : 'updated'} successfully`, autoClose: true})
                setselectedCertification(undefined);
                setReload(prev => !prev);
                if(isCreating) {
                    setIsCreating(false);
                }
                form.resetFields();
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> setLoading(false))
        }
    }

    const onCancelEdit = ()=> {
        setselectedCertification(undefined);
        if(isCreating) {
            setIsCreating(false)
        }
        form.resetFields();
    }

    const onDeleteClicked = (id?:number)=> {
        if(id) {
            deleteCVCertification(cvId,id)
            .then(res => {
                addAlert({type: 'success', title: 'success', description: 'Deleted succesfully', autoClose: true})
                setReload(prev => !prev);
                setselectedCertification(undefined)
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> setLoading(false))
        }
    }

    useEffect(() => {
        form.setFieldsValue(!isCreating ? selectedCertification ? selectedCertification : [] : [])
    }, [form, isCreating, selectedCertification])

    const onChangeStep = (newStep: number) => {
        if(isCreating) {
            return
        }
        if(newStep === 0) {
            navigate(`/dashboard/cv/edit/1`)
        }
        if(newStep === 1) {
            navigate(`/dashboard/cv/edit/2/${cvId}`)
        }
        if(newStep === 2) {
            navigate(`/dashboard/cv/edit/3/${cvId}`)
        }
        if(newStep === 3) {
            navigate(`/dashboard/cv/edit/4/${cvId}`)
        }
    }

    return(
        <Page>
        <FWSubHeader title={'Edit Curriculum Vitae _ Ceritifications'} />
            <Steps responsive className={'px-4 mt-4 sm:mb-4 w-full max-w-3xl xl:max-w-4xl'} onChange={onChangeStep}  current={4} size={'small'}>
                    <Step title="Personal Info" />
                    <Step title="Experience"  />
                    <Step title="Education" />
                    <Step title="Achievements" />
                    <Step title="Certifications" />
            </Steps>
            <div className={'bg-white shadow rounded-md md:rounded-xl px-6 py-4 w-auto max-w-3xl xl:max-w-4xl'}>
            <div className="w-full h-2 flex justify-end -mb-2"><CloseOutlined onClick={() => navigate('/dashboard/cv', {state: {isFromCv:true}})} className="hover:text-fw-orange cursor-pointer"/></div>
                <div className={'flex flex-col w-full mt-6'}>
            {loading ? <PageSpinner /> :
            <>
            {(selectedCertification || isCreating) && <Form
                name="addCertification"
                onFinish={onSubmitEducation}
                autoComplete="off"
                className={'w-full flex flex-col md:grid grid-cols-1 md:grid-cols-3 gap-x-5'}
                form={form}
            >   
                    <Form.Item
                        className={'col-span-1 md:col-span-3'}
                        name="certification_name"
                        rules={[{ required: true, message: 'Achievment name is required!' }]}
                    >
                        <Input placeholder={'Certification name'} />
                    </Form.Item>

                    <Form.Item
                        name={"start_date"}
                        rules={[{ required: true, message: 'Start date is required!'}]}
                    >
                        <DatePicker className={'w-full'} format={monthFormat} placeholder={'start date'} />
                    </Form.Item>

                    <Form.Item
                        name={"end_date"}
                    >   
                        <DatePicker className={'w-full'} disabled={isPresent} format={monthFormat} placeholder={'end date'} />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox checked={isPresent} onChange={e => setIsPresent(e.target.checked)}>Up to Present</Checkbox>
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        name="description"
                    >
                        <TextArea showCount maxLength={150} placeholder={'Describe the certificate briefly here...'}  rows={2} />
                    </Form.Item>
                    <Form.Item
                        name="issuing_organization"
                        rules={[{ required: true, message: 'Issuing Organazation is required!'}]}
                    >
                        <Input placeholder={'Issuing Organization'} />
                    </Form.Item>
                    <Form.Item
                        name="issuing_country"
                    >
                        <Input placeholder={'Organization city and country'} />
                    </Form.Item>

                    <Form.Item
                        name="issuing_organization_website"
                    >
                        <Input placeholder={'Organization website'} />
                    </Form.Item>
 
                    <Form.Item
                        className={'col-span-1 md:col-span-3'}
                        name="digital_link"
                    >
                        <Input placeholder={'Digital link / evidence'} />
                    </Form.Item>

                    <div className={'flex items-center gap-x-3'}>
                        <Button loading={loading} disabled={loading} size={'small'}  block type={'primary'} htmlType="submit">{isCreating ? 'Add Certificate' : 'Update'}</Button>              
                        <Button onClick={onCancelEdit} size={'small'}  block type={'default'}>Cancel</Button>  
                    </div>  
            </Form>}

            {/* List of Certificates */}
            {cvCertifications?.length === 0 && !isCreating && <Empty className={'mb-4'} description={'No items found, Click add button to input your certificate 🏆 ...(is optional, can skip it...)' } />}
            {(!selectedCertification && !isCreating) && <Button onClick={()=> setIsCreating(true)} className={'w-max'} loading={loading} disabled={loading} size={'small'} type={'primary'} ghost>Add new Certification</Button>}
            {cvCertifications?.length > 0 ? cvCertifications.filter(edu => edu.id !== selectedCertification?.id).map(certification =>
            (<div className={'mt-4'}>
                <p className={'text-base text-gray-900 font-semibold pb-1 flex items-center'}>{certification?.certificatoin_name}<span> 
                    <EditOutlined onClick={() => onEditClicked(certification?.id)} className={'ml-2 mb-1 text-fw-orange'} />
                    <DeleteOutlined onClick={() => onDeleteClicked(certification?.id)} className={'ml-2 mb-1 text-red-700'} />
                </span> </p>
                <div className={'flex flex-col md:grid grid-cols-1 lg:grid-cols-5 text-gray-500 items-start'}>  
                    <div className={'flex flex-col col-span-2'}>
                        <Bullet text="lang1" noMargin={true}>
                            <p className={'text-base text-gray-900 pb-1'}><b>{certification?.issuing_organization}</b></p>
                        </Bullet>
                        <p className={'text-sm text-gray-500 pb-1'}>{certification?.issuing_country}</p>
                        <p className={'text-sm text-gray-500 pb-1 underline'}>{certification?.issuing_organization_website}</p>
                    </div>
                    <span className={'inline-flex items-center col-span-1 lg:col-span-2'} >
                        {certification?.description}
                    </span>
                    <span className={'inline-flex items-center col-span-1 lg:justify-self-end font-medium'} >
                       {moment(certification?.start_date)?.format('MMM YYYY') ?? ''} - {certification?.end_date ? moment(certification?.end_date)?.format('MMM YYYY') : 'Present'}
                    </span>
                </div>
            </div>)) : null}
            
            <Alert className={'col-span-1 md:col-span-3 mt-4 mb-1 text-xs'} message={noteToJobSeeker} type={'warning'} showIcon />
            
            <div className={'w-full flex justify-end'}>
                <div className={'mt-4 flex flex-col md:flex-row gap-4 w-full md:w-1/2 justify-items-end'}>
                    <Button onClick={() => navigate(-1)} block type="default">Previous</Button>
                    <PublishBtn isBlock={true} loading={loading} disabled={loading|| !!selectedCertification || isCreating} />
                </div>
            </div>
            </>}
            </div>
        </div>
        </Page>
    )
}

export default CVEdit5;