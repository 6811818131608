
interface IFileUploader {
    onFileSelectSuccess: (e:any) => void;
    onFileSelectError: (e:any) => void;
    fileType?: string;
}

const FileUploader = ({onFileSelectSuccess, onFileSelectError, fileType=''}:IFileUploader) => {
    const handleFileInput = (e:any) => {
        const file = e.target.files[0];
        if (file && file.size > 1000000) {
            onFileSelectError({ error: "File size cannot exceed more than 1MB" });
        } else {
            onFileSelectSuccess(file);
        }
      };

    return (
        <div className="file-uploader">
            <input type="file" className={'choose'} onChange={handleFileInput} />
            <p className={'text-xs font-light text-gray-500 pt-0.5 italic'}>file size can't exceed 1MB.</p>
            {fileType ? <p className={'text-xs font-light text-gray-500 pt-0.5 italic'}>file type must be {fileType}</p> : null}
        </div>
    )
}

export default FileUploader;