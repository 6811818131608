
import React, { FC } from "react";
import { classNames } from "../../Utils";

interface IPage {
    className?: string;
    children: React.ReactNode
}

const Page:FC<IPage> = ({className = '', children}: IPage) => {
    const styleClass = {
        root: classNames(
            'p-4',
            'md:p-6',
            className
        )
    }

    return (<div className={styleClass.root}>
        {children}
    </div>);
}

export default Page;