import { Button, Input } from 'antd';
import React, { FC, useContext, useState } from  'react'
import Typist from 'react-typist-component';
import { SearchOutlined } from "@ant-design/icons"
import AnimatedCorners from '../../components/onboarding/AnimatedCorners';
import EmployerList from '../../components/base/EmployerList';
import LandingHeader from '../../components/onboarding/LandingHeader';
import {clearContext } from './SearchContext';
interface IHero {
  onSearch: (searchTerm: string, employerId:number) => void
  login: ()=> void
  onSignUp:() => void
  onBusinessSignUp:() => void
}

const Hero:FC<IHero> = ({onSearch,login, onSignUp, onBusinessSignUp}) => {

    const [cursor, setCursor] = useState(true);
    const [searchValue, setSearchValue] = useState('')
    const [employerId, setEmployerId] = useState(0)
    const { clear , setClear } = useContext(clearContext)
    const onSelectEmployer = (employerId: number) => {
        setEmployerId(employerId)
    }

    const handleSearch = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onSearch(searchValue, employerId)
        const releventDiv = document.getElementById('search');
        releventDiv?.scrollIntoView({behavior: "smooth"});
    }

    return (
      <section className={'min-h-screen w-screen max-w-screen bg-hero relative'}>
        <LandingHeader login={login} onSignUp={onSignUp} onBusinessSignUp={onBusinessSignUp}/>
        <section className="w-full min-h-[calc(100vh_-_64px)] grid grid-cols-2 grid-rows-1 gap-4 max-w-7xl mx-auto">
            <div className="md:pl-8 md:-mt-10 col-span-2 lg:col-span-1 flex flex-col justify-center items-center lg:items-start">
                <Typist 
                    typingDelay={140} loop={false} finishDelay={3000} 
                    cursor={cursor ? <span className='cursor'>|</span> : undefined}
                    onTypingDone={() => setCursor(false)}
                >
                    <h1 className="min-h-[180px] mb-6 text-4xl md:text-5xl lg:text-6xl font-bold font-heading md:max-w-xl leading-relaxed" data-config-id="auto-txt-12-1">Land Your <br/> Dream Job In <br/>
                        <Typist.Delay ms={200} />
                        <span className={'text-fw-orange px-1'}>Business</span>
                        <Typist.Delay ms={500} />
                        <Typist.Backspace count={8} delay={2000} />
                        <span className={'text-fw-orange px-1'}>Technology</span>
                        <Typist.Delay ms={500} />
                        <Typist.Backspace count={10} delay={2000} />
                        <span className={'text-fw-orange px-1'}>Engineering</span>
                        <Typist.Delay ms={500} />
                        <Typist.Backspace count={11} delay={2000} />
                        <span className={'text-fw-orange px-1'}>And More...</span>
                        </h1>
                </Typist>
                <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="500" className="text-fw-blue-light text-md">
                    Find Jobs, Employment & Career Opportunities
                </div>
                <div className="z-10 sm:w-[500px] flex-col gap-y-2 sm:flex-row w-[250px] h-[130px] sm:h-[60px] bg-white rounded-md shadow-sm mt-10 flex justify-center items-center">
                    <Input 
                        prefix={<SearchOutlined/>} 
                        onPressEnter={(e:any) => handleSearch(e)}
                        size='middle'
                        className='ml-2 max-w-[185px] md:min-w-[200px]' 
                        placeholder="jobtitle, keywords, company" 
                        bordered={false} 
                        onChange={(e)=>{
                            setClear(false)
                            e.preventDefault()
                            e.stopPropagation()
                            e.nativeEvent.preventDefault()
                            e.nativeEvent.stopImmediatePropagation()
                            // console.log(e.target.value)
                            setSearchValue(e.target.value ?? '')
                        }}
                        value={clear ? '' : undefined}
                        allowClear
                    />
                    <hr className="bg-fw-blue-light bg-opacity-5 h-[55%] hidden sm:block"/>
                    <EmployerList onSelect={onSelectEmployer}/>
                    <Button type="primary" size="large" className="bg-fw-orange mr-3 rounded-md" onClick={e => handleSearch(e)}>Find Jobs</Button>
                </div>
                <div data-aos="fade-up" data-aos-delay="1500" data-aos-duration="700" className="text-fw-blue-light text-md mt-5 px-3 md:px-0 z-5">
                    <b>Popular Searches : </b>Accountant, Finance, Admin, Developer, Banker
                </div>
            </div>
            <div className="justify-center items-end hidden lg:flex">
                <AnimatedCorners />
            </div>
        </section>
  </section>
    )
}

export default Hero