export const firebaseConfig = {
  apiKey: "AIzaSyBtdQS0FwVw45z9n8BPY5NqQSvqjAmb3lU",
  authDomain: "fairway-379dc.firebaseapp.com",
  projectId: "fairway-379dc",
  storageBucket: "fairway-379dc.appspot.com",
  messagingSenderId: "636631849709",
  appId: "1:636631849709:web:b5aaa20782727bcf2fcae4",
  measurementId: "G-1QK3PSDYZ1"
};


