import {CarryOutFilled, FundFilled, FieldTimeOutlined, FileUnknownFilled, PlusCircleOutlined, FileExclamationFilled} from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { ICredential } from '../../types';
import { getCredentials } from '../../services/UserService';
import CredentialListItem from './CredentialListItem';
import PageSpinner from '../../components/base/PageSpinner';
import { useAuth } from '../../context/AuthContext';
import { Button } from 'antd';
import CredentialRequestModal from './CredentialRequestModal';

interface ICredentialRequest {
    credential_requests : ICredential[],
    issue_rate: number,
    issued_credentials: number,
    pending_credentials : number
}

const MyCredential = () => {
    const [credentials, setCredentials] = useState<ICredentialRequest>({} as ICredentialRequest)
    const [userCredentials, setUserCredentials] = useState<ICredential[]>([])

    const [loading , setLoading] = useState<boolean>(true)
    const [restart , setRestart] = useState<Boolean>(true)
    const [showRequestModal, setShowRequestModal] = useState<Boolean>(false)
    const { currentUser} = useAuth();
    const [statusId, setStatusId] = useState<number | null>(null)

    useEffect(() => {
        setLoading(true)
        const fetchData = () => {
            getCredentials().then(res => {
                if(currentUser?.is_university_staff){
                    setCredentials(res?.data)
                }
                else{
                    setUserCredentials(res?.data)
                }
                setLoading(false)   
            }).catch(err=> console.error(err))
        }
        if(currentUser) {
            fetchData()
        }
    }, [restart, currentUser])

    const total = credentials?.credential_requests?.length
    // const getIssued = () => {
    //     const count = credentials?.issued_credentials ?? 1;
    //     return `${(count/total)*100 > 100 ? 100 : (count/total)*100}%`; 
    //  }

    //  const getPending = () => {
    //     const count = credentials?.pending_credentials ?? 1;
    //     return `${(count/total)*100 > 100 ? 100 : (count/total)*100}%`; 
    //  }

    //  const getIssueRate = () => {
    //     // const count = credentials?.issue_rate ?? 1;
    //     // return `${(count/total)*100 > 100 ? 100 : (count/total)*100}%`; 
    //     return credentials?.issue_rate *100
    //  }

     const onClose = () => {
        setShowRequestModal(false)
     }

     const onSubmit = () => {
        setShowRequestModal(false)
        setRestart(prev => !prev)
     }

    const onIssue = () => {
        setRestart(prev => !prev)
    }

    const onReject = () => {
        setRestart(prev => !prev)
    }

    const onCheckStatus = () => {
        setRestart(prev=> !prev)
    }

    const isStaff = useMemo(() => {
        return !!currentUser?.is_staff
    }, [currentUser])

    const isUniversityStaff = useMemo(() => {
        return !!currentUser?.is_university_staff
    }, [currentUser])

    const data = useMemo(() => {
        const data = isUniversityStaff ? credentials?.credential_requests : userCredentials
        if(statusId !== null) {
            return data.filter(data => data.status === statusId) 
        }
        return data
    }, [isUniversityStaff, credentials, userCredentials, statusId])


    const calculateRejected = () => {
        let count = 0
        credentials?.credential_requests?.forEach((cred) => {
            if (cred.status === 4)
                count++
        })
        return count
    }

    const calculateRequested = () => {
        let count = 0
        credentials?.credential_requests?.forEach((cred) => {
            if (cred.status === 1)
                count++
        })
        return count
    }

    const filterComponent = [
        {
            name: "Requested",
            icon: <FileUnknownFilled className={'mr-2 font-semibold text-fw-orange'} />,
            value: calculateRequested(),
            textStyle: "border-2 border-green-500 border-solid",
            lineStyle: "h-1.5 rounded-md bg-fw-green w-1/3",
            length: `${(calculateRequested()/total)*100 > 100 ? 100 : (calculateRequested()/total)*100}%`,
            id: 1
        },
        {
            name: "Issued",
            icon: <CarryOutFilled className={'mr-2 font-semibold text-fw-orange'} />,
            value: credentials?.issued_credentials,
            textStyle: "border-2 border-green-500 border-solid",
            lineStyle: "h-1.5 rounded-md bg-fw-green w-1/3",
            length: `${(credentials?.issued_credentials/total)*100 > 100 ? 100 : (credentials?.issued_credentials/total)*100}%`,
            id: 3
        },
        {
            name: "Pending",
            icon: <FieldTimeOutlined className={'mr-2 font-semibold text-fw-orange'} />,
            value: credentials?.pending_credentials,
            textStyle: "border-2 border-green-500 border-solid",
            lineStyle: "h-1.5 rounded-md bg-fw-orange",
            length: `${(credentials?.pending_credentials/total)*100 > 100 ? 100 : (credentials?.pending_credentials/total)*100}%`,
            id: 2
        },
        {
            name: "Rejected",
            icon: <FileExclamationFilled className={'mr-2 font-semibold text-fw-orange'} />,
            value: calculateRejected(),
            textStyle: "border-2 border-green-500 border-solid",
            lineStyle: "h-1.5 rounded-md bg-red-500",
            length: `${(Number(calculateRejected())/total)*100 > 100 ? 100 : (Number(calculateRejected())/total)*100}%`,
            id: 4
        },
        {
            name: "Issue Rate",
            icon: <FundFilled className={'mr-2 font-semibold text-fw-orange'} />,
            value: Number(credentials?.issue_rate * 100)?.toFixed(0) + ' %',
            textStyle: "",
            lineStyle: "h-1.5 rounded-md bg-fw-green",
            length: `${(credentials?.issue_rate)*100}%`,
            id: null
        }
    ]


  return (
    <div className="mt-6 ml-6">
        <div className='font-semibold text-2xl md:pb-6'>{isStaff ? "Credentials": "Education Credentials"}{!isStaff && !isUniversityStaff && <Button onClick={() => setShowRequestModal(true)} icon={<PlusCircleOutlined />} className='md:ml-4 mt-2 md:mt-0' size='middle' type='primary'>Request a New Credential</Button>}</div>
        <div className='mt-4 md:mt-0'>
        {isUniversityStaff && <div className={'flex flex-row flex-wrap gap-6'}>
            {filterComponent.map(filter => <div key={filter.id} onClick={() => statusId === filter.id ? setStatusId(null) : setStatusId(filter.id)} className={`${statusId === filter.id && filter.textStyle} cursor-pointer rounded-md md:rounded-3xl bg-white w-32 h-24 md:w-48 md:h-32 p-3 md:p-5 flex flex-col justify-between`}>
                <div className={'flex items-center'}>
                    {filter.icon}
                    <p className={'font-semibold text-gray-600'}>{filter.name}</p>
                </div> 
                <p className={'text-2xl md:text-4xl text-gray-900 font-medium '}>{filter.value}</p>
                <div className={'bg-gray-100 w-full rounded-md'}>
                    <div style={{width: filter.length}} className={filter.lineStyle} />
                </div>
            </div>)}
            {/* <div onClick={() => statusId === 3 ? setStatusId(null) : setStatusId(3)} className={`${statusId === 3 && 'border-2 border-green-500 border-solid'} cursor-pointer rounded-md md:rounded-3xl bg-white w-32 h-24 md:w-48 md:h-32 p-3 md:p-5 flex flex-col justify-between`}>
                <div className={'flex items-center'}>
                        <CarryOutFilled className={'mr-2 font-semibold text-fw-orange'} />
                    <p className={'font-semibold text-gray-600'}>Issued</p>
                </div> 
                <p className={'text-2xl md:text-4xl text-gray-900 font-medium '}>{credentials?.issued_credentials}</p>
                <div className={'bg-gray-100 w-full rounded-md'}>
                    <div style={{width: getIssued()}} className={'h-1.5 rounded-md bg-fw-green w-1/3'} />
                </div>
            </div>
            <div onClick={() => statusId === 2 ? setStatusId(null) : setStatusId(2)} className={`${statusId === 2 && 'border-2 border-green-500 border-solid'} cursor-pointer rounded-md md:rounded-3xl bg-white w-32 h-24 md:w-48 md:h-32 p-3 md:p-5 flex flex-col justify-between`}>
                <div className={'flex items-center'}>
                        <FieldTimeOutlined className={'mr-2 font-semibold text-fw-orange'} />
                    <p className={'font-semibold text-gray-600'}>Pending</p>
                </div> 
                <p className={'text-2xl md:text-4xl text-gray-900 font-medium '}>{credentials?.pending_credentials}</p>
                <div className={'bg-gray-100 w-full rounded-md'}>
                    <div style={{width: getPending()}} className={'h-1.5 rounded-md bg-fw-orange'} />
                </div>
            </div>
            <div className={'rounded-md md:rounded-3xl bg-white w-32 h-24 md:w-48 md:h-32 p-3 md:p-5 flex flex-col justify-between'}>
                <div className={'flex items-center'}>
                        <FundFilled className={'mr-2 font-semibold text-fw-orange'} />
                    <p className={'font-semibold text-gray-600'}>Issue Rate</p>
                </div> 
                <p className={'text-2xl md:text-4xl text-gray-900 font-medium '}>{Number(credentials?.issue_rate * 100)?.toFixed(0)}%</p>
                <div className={'bg-gray-100 w-full rounded-md'}>
                    <div style={{width: getIssueRate()}} className={'h-1.5 rounded-md bg-fw-green'} />
                </div>
            </div> */}
        </div>}
        </div>
        <div className={'mt-6 mr-2'}>
            {loading ? <PageSpinner /> : 
            Array.isArray(data) ? 
            data?.map(credential =>  <CredentialListItem loading={loading} onCheckStatus={onCheckStatus} onIssue={onIssue} onRejct={onReject} credential={credential} key={credential.id}/>)
            : null
        }
        </div>
        {showRequestModal && <CredentialRequestModal onClose={onClose} onSubmit={onSubmit}/>}
    </div>
    
  )
}

export default MyCredential