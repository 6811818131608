import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Spin } from "antd";
import {CloseCircleOutlined } from '@ant-design/icons'
import { getJobIndustries, searchJob } from "../../services/JobService";
import FWHeroJob from "../base/FWHeroJob";
import { IJob } from "../../types";
import { clearContext } from "../../pages/onboarding/SearchContext";

interface IJobIndustries {
    searchValue?: string,
    employerId?: number,
    employerName?: string
    onResetSearch?: () => void
    onSignUp: () => void
}

const JobIndustries = ({searchValue= '', employerId, onResetSearch, onSignUp}: IJobIndustries) => {
    const Activestyle = 'bg-fw-orange text-white'
    const notActiveStyle = 'bg-white text-fw-orange'

    const [industries, setIndustries] = useState<string[]>([])
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([])
    const [jobs, setJobs] = useState<IJob[]>([])
    const [loading, setLoading] = useState(false)
    const { setClear } = useContext(clearContext)
    
    useEffect(() => {
        getJobIndustries().then(res => {
            const content = JSON.parse(res?.data?.files['industries.json']?.content)
            const keys = content ? Object?.keys(content) : undefined
            const trimmed = keys?.map((item: string) => item.split('&')[0].trim())
            setIndustries(trimmed ?? [])
        })
    }, [])

    useEffect(() => {
        let industriesStr = ''
        if(selectedIndustries.length > 0) {
            selectedIndustries.forEach(item => {
                industriesStr += `&industry=${item}`
            })
        }
        setLoading(true)
        searchJob(searchValue, employerId, industriesStr).then(res => res?.data).then(res => {
            setJobs(res.jobs ?? [])
            setLoading(false)
        })
    }, [searchValue, employerId, selectedIndustries])

    const onSelect = (item:string) => {
        const isSelected = selectedIndustries.includes(item)
        if(isSelected) {
            setSelectedIndustries(selectedIndustries.filter((x: string) => x !== item))
        } else {
            setSelectedIndustries([...selectedIndustries, item])
        }
    }

  const searchResults = useMemo(() => {
    return searchValue || employerId || selectedIndustries.length > 0
  }, [searchValue, employerId, selectedIndustries])

  return (
    <div className="flex flex-col justify-center items-center py-28 max-w-7xl mx-auto" id='search'>
        <div className="text-2xl md:text-4xl text-grey-600 font-semibold mb-2">{searchResults ? 'Search Results' :'Latest Jobs'}</div>
        <div className="text-md md:text-lg text-grey-200 px-2 md:px-0">{searchValue ? 
        <p>Results for search <span
            className="text-fw-orange cursor-pointer"
        >'{searchValue}' </span>
        <span><CloseCircleOutlined className='hover:text-fw-orange' onClick={()=> {
            setClear(true)
            onResetSearch && onResetSearch()}}/></span></p>
        
        :'Click on Items below to show industry jobs'}</div>
        <div className="max-w-full flex items-center flex-wrap lg:flex-nowrap gap-2 lg:gap-5 my-5 mx-2 md:mx-0">
        
        <div className={'flex flex-wrap gap-1 justify-center'}>
        {industries.map((item:string) => <div onClick={e => onSelect(item)} key={item} className={`flex justify-between items-center rounded-lg border-fw-orange border-2 border-solid text-base transform scale-90 lg:scale-95 font-normal capitalize px-4 py-1 w-auto fw_badge whitespace-nowrap text-capitalize cursor-pointer ${selectedIndustries.includes(item) ? Activestyle : notActiveStyle}`}><p className="w-full text-center">{item}</p></div> )}
        </div>
        
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-max gap-8 mt-5 text-grey-700 min-h-[500px]" >
            {loading ? 
            <div className="flex flex-col justify-center items-center col-span-2 min-h-[500px]">
                <Spin tip='loading' size='large'/>
            </div> :
            jobs.length === 0 ?
            <div className="flex flex-col justify-center items-center col-span-2">
                <img src="/not-found.svg" alt="empty" className="w-1/2"/>
                <p className="text-lg text-grey-600 mt-5">No jobs found</p>
            </div> :
            <>
            {jobs.slice(0,6).map((x:any) => <FWHeroJob job={x} key={x.id} /> )}

            {/* {showBadge1 && showBadge2 && jobs.slice(0,6).map((x:any) => <Badge.Ribbon key={x.id}  text={searchValue}> <Badge.Ribbon text={employerName} color='green' placement="start"></Badge.Ribbon> <FWHeroJob job={x} key={x.id} /> </Badge.Ribbon> )}
            {showBadge1 && jobs.slice(0,6).map((x:any) => <Badge.Ribbon text={searchValue}> <FWHeroJob job={x} key={x.id} /> </Badge.Ribbon> )}
            {showBadge2 && jobs.slice(0,6).map((x:any) => <Badge.Ribbon key={x.id} text={employerName} color='green' placement="start" > <FWHeroJob job={x} key={x.id} /> </Badge.Ribbon> )}
            {!showBadge1 && !showBadge2 && jobs.slice(0,6).map((x:any) => <FWHeroJob job={x} key={x.id} />)} */}
            </>}
            
        </div>
        <Button type="default" size="large" className="rounded-lg mt-5" onClick={onSignUp}>See More Listing</Button>
    </div>
  )
}

export default JobIndustries