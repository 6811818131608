import React, { FC, useState } from 'react'
import MessageOutlined from '@ant-design/icons/MessageOutlined'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import CloseClickOutside from './ClickCloseOutside'
import { Tabs } from 'antd'
import { useAuth } from '../../context/AuthContext'

const FAB:FC = () => {

    const [showMenu, setShowMenu] = useState(false)
    const {currentUser} = useAuth();
    
    return (
        <CloseClickOutside onClose={() => setShowMenu(false)} className={'flex flex-col'} >
            <>
                {showMenu && 
                <div className={'absolute bottom-20 right-8 rounded-lg shadow-lg w-[80vw] sm:max-w-xs h-[50vh] sm:h-[40vh] bg-white'}>
                    <div className={'bg-fw-orange text-white text-base font-semibold p-2 rounded-md flex justify-between items-center'}>
                        Fairway Help Desky
                        <CloseCircleOutlined className={'text-white cursor-pointer'} onClick={()=>setShowMenu(false)}/>
                    </div>
                    <Tabs defaultActiveKey="1" className={'px-2'}>
                        {currentUser?.is_staff ? <Tabs.TabPane tab="For Urgent Requests" key="1" className={'p-2'}>
                            <p>Call Fairway Representatives at </p>
                            <a href={'tel:+251983399366'} target={'_blank'} className={'text-fw-orange font-bold'} rel="noreferrer">+251-983399366</a>
                            <br />
                            or via telegram to
                            <p className={'text-fw-blue font-semibold'}>Customer Affairs:
                            <a className={'text-fw-orange font-bold pl-2 inline'} rel={'noreferrer'} target={'_blank'} href={'https://t.me/Gemechismarema'}>Gemechis</a>
                            </p>
                            <p className={'text-fw-blue font-semibold'}>Tech Support:
                                <a className={'text-fw-orange font-bold pl-2'} rel={'noreferrer'} target={'_blank'} href={'https://t.me/ermiarch'}>Ermias</a>
                            </p>
                        </Tabs.TabPane> :
                        <Tabs.TabPane tab="For Feedback" key="2">
                            Please follow this link to report a bug or request a feature in
                            <a className={'text-fw-orange font-bold pl-2'} rel={'noreferrer'} target={'_blank'} href={'https://d33tl9trffb.typeform.com/to/DkeoyN3U'}>TypeForm</a>
                        </Tabs.TabPane>}
                    </Tabs>
                </div>}
                <div onClick={() =>  setShowMenu(prev => !prev)} className="fixed bottom-0 right-0 m-6 z-[100]">
                    <button className="cursor-pointer bg-fw-orange shadow border-0 text-white font-bold w-12 h-12 flex items-center justify-center rounded-full">
                    {showMenu 
                        ? <CloseCircleOutlined className={'scale-125'} /> :
                        <MessageOutlined className={'scale-125'}/>}
                    </button>
                </div>
            </>
        </CloseClickOutside>
    )
}

export default FAB