import { Form, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FC, useState } from 'react'
import Modal from '../../components/base/Modal'
import { useAuth } from '../../context/AuthContext'
import { IJob } from '../../types'


interface IExaminerModal {
    name: string
    job?: IJob
    messageFromEmployer?: string
    onClose: () => void
    onSubmit: (message: string) => void
    rejectOffer?: () => void
    readOnly?: boolean
}

const EmploymentInvitationModal:FC<IExaminerModal> = ({name,job, messageFromEmployer, onSubmit, rejectOffer,  onClose, readOnly=false}) => {
    console.log(job, messageFromEmployer);
    const { currentUser} = useAuth()
    const [message ] = useState<string>(`Congratulations  ${name}! We are happy to let you know that you are chosen for the ${job?.title} Role at ${job?.business?.name}. If you have further questions please communicate with us in email at ${currentUser?.email}`);


    return (
        <Modal title={'Employment Offer from'} className={'w-[70%]'} onCancel={onClose} 
            onSubmit={() => {onSubmit(message)}} submitText={readOnly ? 'Accept Offer' : 'Send'} cancelText={readOnly ? 'Reject Offer' : 'cancel'} onCancelCustom={rejectOffer && rejectOffer} requiresConfirmation >
            <Form
                name="examinerForm"
                className={'w-2/3 grid grid-cols-2 gap-2'}
                autoComplete="off"
                layout={'horizontal'}
                requiredMark={false}
                title={'Employment Offer'}
                initialValues={{
                    job_title: job?.title,
                    Employer: job?.business?.name,
                    responsible_person_email:  name,
                    description: message
                }}    
            >
                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="job_title"
                    rules={[{ required: true, message: 'Job title required!' }]}
                >
                    <Input placeholder={job?.title} disabled/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="Employer"
                    rules={[{ required: true, message: 'Employer is required!' }]}
                >
                    <Input placeholder={job?.business?.name} disabled/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-2'}
                    name="responsible_person_email"
                    rules={[{ required: true, message: 'Email is required!' }]}
                >
                    <Input placeholder={'Raked Yonas'} type={'email'} disabled />
                </Form.Item>

                
                <Form.Item 
                    className={`col-span-1 md:col-span-4 ${!readOnly ? 'row-span-2' : ''}`}
                    name="description"
                >
                    {!readOnly ?
                    <TextArea disabled={readOnly} placeholder={`Congratulations ${name}! We are happy to let you know that you are chosen for the Sales Representative Role at Vulcan ICT. If you have further questions please communicate with us in email at info@vulcanig.com.`} rows={8} /> :
                    <p className={'bg-fw-green text-md text-white p-2 rounded-md font-medium'}>
                        {/* {`Congratulations ${name}! We are happy to let you know that you are chosen for the ${job?.title} at ${job?.business?.name}. If you have further questions please communicate with us in email at ${currentUser?.email}.`} */}
                        {messageFromEmployer}
                    </p>}
                </Form.Item>
                
            </Form> 
            <p className={'mt-2 text-gray-400 text-md'}>If you agree to our 
            <a href="https://www.ilo.org/dyn/natlex/docs/WEBTEXT/31977/64870/E93ETH01.HTM" target={'_blank'} className={'px-1'} rel="noreferrer">terms</a>
             of employment, please click Accept button.</p>

        </Modal>
    )
}

export default EmploymentInvitationModal