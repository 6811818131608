import React, { FC } from 'react'

const HowItWorks:FC<any> = ({signup}) => {
    return (
        <div id='howitworks' style={{scrollBehavior: 'smooth'}} className='py-12'>
            <div className='text-3xl text-center font-semibold text-gray-800'>How It Works</div>
          <section className="flex lg:flex-row flex-col max-h-[max-content] p-10 justify-center items-center gap-x-36 gap-y-10 text-grey-800">
            <div className="flex flex-col gap-y-10">
                <div className='bg-fw-green self-center rounded-full w-14 h-14 flex justify-center items-center'><span className='pr-1'>Step</span><div className='text-2xl font-semibold'>1</div></div>
                <h1 className="text-3xl font-bold">Create your digital CV</h1>
                <ul className="list-disc ml-8">
                    <li>Create your CV</li>
                    <li>Add your education and experience</li>
                    <li>Apply for jobs</li>
                    <li>Download your CV as PDF</li>
                </ul>

                <button className="rounded-md shadow-md text-white bg-fw-orange p-1 px-6 text-lg lg:self-start self-center w-[200px] font-semibold border-0" onClick={signup}>Sign up</button>
             </div>

                <div className="order-first lg:order-last">
                    <img className="max-h-[50vh]" src="/1.png" alt="vistualizations"/>
                </div>
            </section>
            <section className="flex lg:flex-row flex-col max-h-[max-content] p-10 justify-center items-center gap-x-36 gap-y-10 bg-hero text-grey-800">
                <div className="flex flex-col gap-y-10">
                <div className='bg-fw-orange self-center rounded-full w-14 h-14 flex justify-center items-center'><span className='pr-1'>Step</span><div className='text-2xl font-semibold'>2</div></div>
                    <h1 className="text-3xl font-bold">Look for new openings</h1>
                    <p className="max-w-md">Explore new positions and apply easily with the cv you have created</p>

                    <button className="rounded-md shadow-md text-white bg-fw-orange p-1 px-6 text-lg lg:self-start self-center w-[200px] font-semibold border-0" onClick={signup}>Sign up</button>
                </div>

                <div className="order-first">
                    <img className="max-h-[50vh]" src="/2.png" alt="vistualizations"/>
                </div>
            </section>

            <section className="flex lg:flex-row flex-col max-h-[max-content] p-10 justify-center items-center gap-x-36 gap-y-10 text-grey-800">
                <div className="flex flex-col gap-y-10">
                <div className='bg-fw-blue-light self-center rounded-full w-14 h-14 flex justify-center items-center text-white'><span className='pr-1'>Step</span><div className='text-2xl font-semibold'>3</div></div>
                    <h1 className="text-3xl font-bold">Get hired</h1>
                    <p className="max-w-md">Be notified on where you are on the selection process all the time. No more wondering what happened to your application 
                    </p>

                    <button className="rounded-md shadow-md text-white bg-fw-orange p-1 px-6 text-lg lg:self-start self-center w-[200px] font-semibold border-0" onClick={signup}>Sign up</button>
                </div>

                <div className="order-first lg:order-last">
                    <img className="max-h-[50vh]" src="/3.png" alt="vistualizations"/>
                </div>
            </section>
        </div>
    )
}

export default HowItWorks