import Button from "antd/lib/button";
import FileDoneOutlined from '@ant-design/icons/lib/icons/FileDoneOutlined'
import { useNavigate } from "react-router-dom";

interface IPublishBtn {
    disabled:boolean;
    loading:boolean;
    isBlock?: boolean;
    cutomClick?: ()=> void;
}


const PublishBtn = ({disabled, loading, isBlock=false, cutomClick}:IPublishBtn) => {
    const navigate = useNavigate();

    const handleClick =()=> {
        if(cutomClick) {
            cutomClick()
            return;
        }
        navigate('/dashboard/cv')
    }
    
    return(
        <Button size={!isBlock ? 'small' : undefined} className={`${!isBlock ? 'ml-4' : ''} text-white bg-fw-green hover:text-white hover:bg-fw-green flex items-center justify-center hover:border-0`} loading={loading} disabled={disabled}  block={isBlock} type="default" onClick={handleClick}><FileDoneOutlined className={'mr-2'}/>{isBlock ? 'Publish' : 'Publish Draft'}</Button>
    )
}

export default PublishBtn;