export interface ITab {
    title: string
    status: "process" | "finish",
    description?: string
    onStepClick: (index: number) => void
}
export interface IBusiness {
	id?: number;
    name: string,
    description: string,
    sector: string,
    year_established: string,
    number_of_employees: number,
	logo: string,
	address: string | null,
    city: string,
	is_verified: false,
    subscription_type: number
}
export interface IJob {
	id?: number;
    title: string;
    description?: string,
    requirements: string,
    salary?: number | string,
    experience: 0 | 1 | 2 | 3 | 4 | 5 | 6,
	number_of_positions: number,
	location: string,
	level_of_study?: 0 | 1 | 2 | 3 | 4 | 5,
	field_of_study: string[],
	employment_type: 0 | 1 | 2,
	length_of_employment: 0 | 1,
	skills?: string,
    date_posted?: string,
    date_updated?: string,
    deadline: string | null,
	responsibilities?: string[],
	is_draft?: boolean
    has_hired?: boolean,
	job_status?: 0 | 1 | 2 | 3,
    business?: IBusiness,
	stage?: string[],
	application_status?: IApplicationStatus
	gender?: 'M' | 'F'
	years_of_experience?: number,
	languages?: string[]
	max_age?: number
	selection?: number
}

export interface IApplicationStatus {
	id?: number;
	job: number;
	status: string[];
	user: number;
	cv: number;
	motivation_required: boolean;
	exam_required: boolean;
}
export interface IUser {
	id: number,
	email: string,
	is_staff: boolean,
	is_university_staff: boolean,
	profile: IProfile
}

export interface IUniversity {
	id: number,
	name: string,
	description: boolean,
	proofspace_serviceID: string,
	credential_id:  string,
	schema_id: string,
	year_established: Date,
	city: string,
	address ?: string,
	website ?: null,
	logo: string,
	is_verified: boolean
	programs: Partial<IProgram[]>
	qr_code: string
}

export interface IProgram {
	id: number,
	name: string,
	code?: string,
	duration?: string,
	weblink?: string,
	description: boolean,
	logo: string,
	created_at: Date,
	is_archived: boolean,
	university: IUniversity
}
export interface ICredential {
	id: number,
	is_issued: boolean,
	year_of_enrollment: number,
	year_of_graduation: number,
	student_id: string,
	university: IUniversity,
	program : IProgram,
	requested_at: Date,
	registration_id: string,
	paper_credential_code: string,
	user: Partial<IProfile>,
	status: number
}

export interface IProfile {
	id?: number;
	first_name: string,
	last_name: string,
	phone_number: string,
	secondary_phone?: string
	gender: string;
	photo: string;
	credential_verified: boolean
	email_verified: boolean
	business?: number
	university?: number
	date_of_birth: string | null
	nationality : string
	city: string
	subcity: string
	woreda: string
	house_no: string
	total_years_of_experience: number
	expected_salary: number
	has_drivers_license: boolean
}

export interface ITokenDecoded {
	token_type: string,
	exp: number,
	iat: number,
	jti: number,
	user_id: number,
	is_staff: boolean
} 

export interface ICandidate {
	id?: number,
	email: string,
	is_staff: boolean,
	profile: IProfile;
	application_status?: IApplicationStatus;
	cv?: number;
}

export interface ICV {
	cv?: number;
	user: IUser;
	profile: ICVProfile[];
	experience: ICVExperience[];
	education: ICVEducation[];
	achievements: ICVAchievement[];
	certifications: ICVCertification[];
}

export interface ICVProfile {
	id?:number
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	city: string;
	country: string;
	title: string;
	bio: string;
	skills: string[];
	languages:  Array<{name: string, level: number}>
	socials: string[];
}

// interface Ilang {
// 	name: string;
// 	level: string;
// }
export interface ICVExperience {
	id?:number;
	title: number;
	company_name: string;
	start_date: moment.Moment | string;
	end_date?: moment.Moment | string;
	company_city: string;
	company_country: string;
	company_website: string;
	achievements: string[]; 
	notable_achievements: string[];
	reference_name: string;
	reference_email: string;
	reference_phone: string; 
}
export interface ICVEducation {
	id?: number;
	institute_name: string;
	start_date: moment.Moment | string;
	end_date?: moment.Moment | string,
	institute_city: string;
	institute_country: string;
	institute_website: string;
	field_of_study: string;
	level_of_study: 0 | 1 | 2 | 3 | 4 | 5;
	major?: string;
	minor?: string;
	gpa?: string;
	gpa_out_of?: string;
	is_verified?: boolean;
	university: null | number;
	program: null | number;
}

export interface ICVAchievement {
	id?: number;
	achievement_name: string;
	description: string,
	start_date: moment.Moment | string,
	end_date?:  moment.Moment | string,
	issuing_organization: string;
	issuing_country: string;
	issuing_organization_website: string;
	organization_phone: string;
	digital_link?: string;
}

export interface ICVCertification {
	id?: number;
	certificatoin_name: string;
	description: string;
	start_date: moment.Moment | string;
	end_date?: moment.Moment | string;
	issuing_organization: string;
	issuing_country: string;
	issuing_organization_website: string;
	organization_phone: string;
	digital_link: string;
}

export interface IWagon {
	id: number
	description: string
	number_of_maximum_candidates: number | null
	type: string
	disabled?: boolean
}

export interface ISelectionWagon {
	id: number,
    type: string,
    description: string
    number_of_maximum_candidates: string | null
	position: number,
	in_progress: boolean
	filtered_applicants: number[],
    is_absolute_filter: boolean,
    selection: number
	wagon: IWagon
}

export interface ISelectionStats {
	id: number
	business: IBusiness,
	train: ISelectionWagon[],
	number_of_applicants: 1,
	eligible_applicants: number | null,
	current_filtered_applicants: number | null,
	applicant_universities: Map<string, string> 
	applicant_field_of_study: Map<string, string> 
	applicant_skills: Map<string, string> 
	years_of_experience: Map<string, string> 
	number_of_previous_jobs: Map<string, string> 
	languages: Map<string, string>,
}

export interface ISelection {
	id: number
	business: IBusiness,
	train: ISelectionWagon[],
	number_of_applicants: number,
	eligible_applicants: number | null,
	current_filtered_applicants: number[] | null,
	applicant_universities: Map<string, string> 
	applicant_field_of_study: Map<string, string> 
	applicant_skills: Map<string, string> 
	years_of_experience: Map<string, string> 
	number_of_previous_jobs: Map<string, string> 
	languages: Map<string, string>,
	is_archived: boolean,
	in_progress: boolean,
	has_hired: boolean,
	job: number,
	application_status: IApplicationStatus
	selected_cvs?: Array<{cv: ICV, user: number}>
}

export interface INotificationType {
	id: number, 
	type: string 
	description: string
}

export interface INotification {
	created_at: string
	detail: string
	id: number
	job: number
	job_title: string
	notification_type: INotificationType
	seen: boolean
	user: number
}

export interface IMotivation {
	id: number,
	received_results: number,
	total_applicants: number,
	questions: number[],
	deadline: string, // YYYY-MM-DD
	is_released: boolean,
	selection: number
}
export interface IMotivationQuestion {
	id: number
	question: string,
    description?: string,
    answer_length: number
	business?: null
	is_cover_letter: boolean
	is_selected?: boolean
	is_user_input?: boolean
}

export interface IMotivationAnswer {
	id: number,
	motivation: number
	user: number,
	question: number,
	answer: string
	rating: number | null
}

export interface IExam {
	id: number,
	date: string,
    location: string
    message: string,
    self_examined?: boolean,
    exam_institution?: number | null
	user_exam_id?: number,
	is_released?: boolean,
	grade_uploaded?: boolean
}

export interface IExaminer {
	id: number
	institution_name: string,
    institution_type?: string,
    description: string,
    location: string,
    responsible_person_name: string,
    responsible_person_email: string,
    responsible_person_phone: string,
    is_user_input: boolean,
    default_examiner: boolean
}

export const BusinessSkills = [
    'Analytical and problem solving',
    'Microsoft Excel',
    'Enterprise resource planning software',
    'Business and leadership',
    'Verbal and writing skills',
    'Data analytics',
    'Revenue recognition',
    'Risk and compliance',
    'Generally accepted accounting principles (GAAP)',
    'Financial management',
    'Marketing, sales and customer service',
    'Communication and negotiation',
    'Leadership',
    'Project management and planning',
    'Delegation and time management',
    'Problem solving',
    'Networking'
]

export const EthiopianCities = [
    'Addis Ababa',
	'Mekelle',	
	'Gondar',
	'Adama',	
	'Awassa',
	'Bahir Dar',	
	'Dire Dawa',	
	'Sodo',	
	'Dessie',	
	'Jimma',	
	'Jijiga',
	'Shashamane',	
	'Bishoftu',	
	'Arba Minch',	
	'Hosaena',	
	'Harar',
	'Dilla',	
	'Nekemte',	
	'Debre Birhan',
	'Asella',	
	'Debre Markos',
	'Kombolcha',	
	'Debre Tabor',	
	'Adigrat',	
	'Weldiya',	
	'Areka',	
	'Sebeta',	
	'Burayu',	
	'Shire (Inda Selassie)',	
	'Ambo',	
	'Arsi Negele',	
	'Aksum',	
	'Gambela',	
	'Bale Robe',	
	'Butajira',	
	'Batu',	
	'Boditi',	
	'Adwa',	
	'Yirgalem',	
	'Waliso',	
	'Welkite',	
	'Gode',	
	'Meki',	
	'Negele Borana',	
	'Alaba Kulito',
	'Alamata',	
	'Chiro',	
	'Tepi',	
	'Durame',	
	'Goba',	
	'Assosa',	
	'Wukro',	
	'Haramaya',	
	'Mizan Teferi',	
	'Sawla',	
	'Mojo',	
	'Dembi Dolo',
	'Aleta Wendo',	
	'Metu',
	'Mota',	
	'Fiche',	
	'Finote Selam',	
	'Bule Hora Town',		
	'Bonga',	
	'Kobo',	
	'Jinka',
	'Dangila',	
	'Degehabur',
	'Dimtu',	
	'Agaro'
]

export const AccountantDuties = [
	'Preparing accounts and tax returns',
	'Monitoring spending and budgets',
	'Auditing and analysing financial performance',
	'Financial forecasting and risk analysis',
	'Advising on how to reduce costs and increase profits',
	'Compiling and presenting financial and budget reports',
	'Ensure that financial statements and records comply with laws and regulations',
	'Keeping account books and systems up to date'
] 

export const AllFieldsOfStudy = [
	'Accounting',
	'Accounting and Finance',
	'Administrative Service Management',
	'Business Administration and Information Systems',
	'Economics',
	'Logistics and Supply Chain Management',
	'Marketing Management',
	'Management',
	'Public Administration & Development Management',
	'Archaeology and Heritage Management',
	'Geography and Environmental Studies',
	'History',
	'Philosophy',
	'Political Science and International Relations',
	'Social Anthropology',
	'Social Work',
	'Sociology',
	'Law'
]

export const EthiopianLanguages = [
	'Amharic',
	'Afaan Oromoo',
	'Tigrigna',
	'Afar',
	'Somali',
	'Sidaamu Afoo',
	'English',
	'Finnish',
	'French',
]

export const genderTypes = [
	'M',
	'F',
]

export const AllLevelsOfStudy = [
	'Non Applicable', 
    'Highschool Graduate', 
    'Diploma',
    'Bachelors',
    'Masters',
    'PHD'
]