import React, { useState } from 'react'
import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled'
import ClockCircleOutlined from '@ant-design/icons/lib/icons/ClockCircleOutlined'
import ProgressBar from '../base/ProgressBar';
import { ISelection, ISelectionWagon } from '../../types';
import SelectionFilter from './Filter/SelectionFilter';

interface IWagonComponent {
    selection: ISelection
    selectionWagon: ISelectionWagon
    onCreateNew?: () => void
    onReload: () => void
    first?: boolean
}

const Wagon:React.FC<IWagonComponent> = ({selection, selectionWagon, onCreateNew, onReload, first = false}) => {

    const {selection:selectionId, wagon, in_progress, position, filtered_applicants} = selectionWagon
    const [showFilterModal, setShowFilterModal] = useState(false)

    const getStyle = () => {
        const style = {boxShadow: '0px 10px 20px 0 rgba(0,0,0,0.15)'}
        return wagon?.type === 'Finished' ? Object.assign(style, {background: '#68D3AC', color: 'white'}) : in_progress ? Object.assign(style, {background: '#FDB82C'}) : style
    }

    const submit = () => {
       onReload()
       setShowFilterModal(false)
    }

    return(
        <div style={getStyle()} className={`relative w-[150px] h-[150px] py-2 px-4 rounded-2xl flex flex-col cursor-pointer border-[0.5px] border-solid ${in_progress ? 'border-fw-orange' : 'border-white'}`}>
            {onCreateNew ? 
            <div onClick={() => onCreateNew()} className={'w-full h-full grid place-items-center text-6xl text-fw-orange'}>
                +
            </div> :
                <>
                    <div className={'flex justify-between items-center mb-2'}>
                        <p className={'text-xs text-gray-600'}>{''}</p>
                        <p className={'text-base font-semibold'}>{position}</p>
                    </div>
                        <div className={'flex items-center justify-between'}>
                            {in_progress ? 
                            <ClockCircleOutlined className={`inline-flex text-xl mb-2 text-white`} /> :
                            <CheckCircleFilled className={`inline-flex text-xl mb-2 text-fw-green`} />}
                            {/* {!in_progress && <FilterOutlined onClick={onFilterClicked} className={'text-lg text-gray-800'} />} */}
                        </div>
                        <p className={`${(wagon.type === 'Finished' || in_progress) ? 'text-white' : 'text-gray-800'} font-black text-base`}>{wagon.type === 'Individual Screening' ? 'Screening' : wagon.type}</p>
                        <div className={`rounded-xl p-2 py-0.5 ${wagon.type === 'Finished' ? 'bg-white text-fw-green' : !in_progress ? 'bg-fw-green text-white' : 'bg-white text-fw-orange'} text-xs w-max mb-2`}>
                            {!in_progress ? 'Done' : 'In progress' }
                        </div>
                        {!in_progress && filtered_applicants.length > - 1 &&
                            <ProgressBar showPercentage={true} value={filtered_applicants.length} total={selection?.number_of_applicants ?? filtered_applicants.length} color={wagon.type === 'Finished' ? 'white' : in_progress ? 'green' : 'orange'} />}
                        </>}
            {!first && <div className={'cursor-none absolute top-[50px] w-[92px] border-dotted border border-fw-green right-full mr-0.5'} />}
            {showFilterModal && <SelectionFilter selectionId={selectionId} onSubmit={submit}  onClose={() => setShowFilterModal(false)} />}
        </div>
    )
}

export default Wagon