import { FC, useState} from 'react'
import { Form, Input, Button, Alert} from "antd";
import {BankFilled, MailOutlined , UserOutlined, PhoneOutlined ,BranchesOutlined} from "@ant-design/icons";
import useAlert from '../toast/useAlert';
import { BusinessRequestForm } from '../../services/BusinessService';

interface IBusinessSignup {
    onDone: () => void
}

const BusinessSignup:FC<IBusinessSignup> = ({onDone}) => {
    const [form] = Form.useForm();
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError ] = useState<string>('');
    const {addAlert} = useAlert();

    const {TextArea} = Input;
    const handleSubmit =(values: any)=> {
        //adding prefix in phone number
        values.representative_phone_number = '+251'.concat(values.representative_phone_number)
        
        BusinessRequestForm(values).then(res =>{
            if(res?.status === 201){
                    onSuccess()
                }
            }
            ).catch(err=> {
                console.log(err.response.data);
                
                onError(err.message, err.response.data)})
    }

    const onSuccess = ()=> {
        setLoading(false)
        onDone()
        setSuccess(true)
    }
    const onError = (errName: string, errData:any)=> {
        addAlert({type: 'error', title: errName, description: JSON.stringify(errData), autoClose:false})
        setLoading(false)  
        setSuccess(false)
    }

  return (
    <div className={'flex flex-col items-center'}>
    {!success && <Form
        form={form}
        name="businessRequestForm"
        initialValues={{ remember: true}}
        onFinish={handleSubmit}
        className={'w-full'}
    >
        <Form.Item
            className={'mb-4 mt-6 md:mt-12'}
            name="company_name"
            rules={[{ required: true, message: 'Please input your company name!' }]}
        >
            <Input placeholder={'Name of your company'}  prefix={<BankFilled  className="site-form-item-icon mr-2" />} />
        </Form.Item>

        <Form.Item 
            className={'mb-4'}
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'Please enter a valid email'}]}
        >
            <Input placeholder={'Email of company representative'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
        </Form.Item>

        <Form.Item
            className={'mb-4'}
            name="representative_name"
            rules={[{ required: true, message: 'Please input your name!' }]}
        >
            <Input placeholder={'Representative name'}  prefix={<UserOutlined className="site-form-item-icon mr-2" />} />
        </Form.Item>
        <Form.Item
            className={'mb-4'}
            name="representative_phone_number"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
            <Input addonBefore={<PhoneOutlined className="site-form-item-icon mr-2 rotate-90 ml-1" />} placeholder={'Representative Phone Number'} prefix={"+251" }/>
        </Form.Item>

        <Form.Item
            className={'mb-4'}
            name="business_sector"
            rules={[{ required: false, message: 'This is optional!' }]}
        >
            <Input placeholder={'Business Sector(Optional)'}  prefix={<BranchesOutlined className="site-form-item-icon mr-2" />} />
        </Form.Item>


        <Form.Item
            className={'mb-4'}
            name="message"
            rules={[{ required: false, message: 'This is optional' }]}
            
        >
             <TextArea rows={4} placeholder="Message(Optional)" maxLength={250} />
        </Form.Item>
        
        <Button loading={loading} disabled={loading} block type="primary" htmlType="submit">
            Send Request
        </Button>
    </Form>}
    {success && <div className='flex flex-col justify-center items-center'>
        <img width="250" height="250" src="https://img.icons8.com/fluency/250/checked.png" alt="checked"/>
        <p className={'text-sm text-gray-400 font-medium pt-1 cursor-pointer'}>Request was sent to successfully!</p>
        <p className={'text-sm text-gray-400 font-medium pt-1 cursor-pointer'}>Our Team will contact you very soon!</p>
        {/* <p className={'text-sm text-gray-400 font-medium pt-1 cursor-pointer'}>Check your email, We will send you an email very soon!</p> */}
        </div>}
    {error  && <Alert message={error} type="error" closable afterClose={() => setError('')} />}
</div>
  )
}

export default BusinessSignup