import {useEffect, useState} from 'react'
import PageSpinner from '../../components/base/PageSpinner'
import { getPrograms, updateProgram } from '../../services/UserService'
import { IProgram } from '../../types'
import ProgramListItem from './ProgramListItem'
import { Button, Modal } from 'antd'
import {PlusCircleOutlined } from '@ant-design/icons'
import AddProgramForm from './AddProgramForm'
import { useNavigate } from 'react-router-dom'
import useAlert from '../../components/toast/useAlert'

const MyPrograms = () => {
    const [programs, setPrograms] = useState<IProgram[]>([])
    const [loading , setLoading] = useState<Boolean>(true)
    const [showAddForm , setShowAddForm] = useState<Boolean>(false)
    const [reload , setReloading] = useState<Boolean>(false)
    const [archiveId, setArchiveID] = useState<Number>(0)
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false)
    const [showUnArchiveModal, setShowUnArchiveModal] = useState<boolean>(false)
    const Navigate = useNavigate()
    const {addAlert} = useAlert();

    useEffect(() => {
        setLoading(true)
        getPrograms().then(res => {
            setPrograms(res?.data)
            setLoading(false)
        }).catch(err=> console.error(err))
    }, [reload])

    const Add = () => {
      setShowAddForm(true)
    }
    const CloseAdd = () => {
      setShowAddForm(false)
    }
    const onSubmit = () => {
      setShowAddForm(false)
      setReloading(true)
    }

    const onEdit = (id: number) => {
      Navigate(`/dashboard/programs/${id}`)
    }

    const onArchive = (id: number, type: boolean) => {
      if(type)
        setShowUnArchiveModal(true)
      else
        setShowArchiveModal(true)
      setArchiveID(id)
    }

    const closeModal = () => {
      setShowArchiveModal(false)
      setShowUnArchiveModal(false)
    }

    const ArchiveProgram = () => {
      updateProgram(archiveId, {is_archived : true}).then(res=>{
        closeModal()
        setReloading(prev => !prev)
        addAlert({type: 'success', title: 'success', description: 'Program Updated Successully!', autoClose: true})
        setArchiveID(0)
    }).catch(err => {
        console.log(err)
        addAlert({type: 'error', title: 'error', description: 'Failed To Submit!', autoClose: true})
    })
    }
    
    const UnArchiveProgram = () => {
      updateProgram(archiveId, {is_archived : false}).then(res=>{
        closeModal()
        setReloading(prev => !prev)
        addAlert({type: 'success', title: 'success', description: 'Program Updated Successully!', autoClose: true})
        setArchiveID(0)
    }).catch(err => {
        console.log(err)
        addAlert({type: 'error', title: 'error', description: 'Failed To Submit!', autoClose: true})
    })
    }

  return (
    <div className="mt-6 ml-6">
     <div className= 'text-lg md:text-xl text-fw-blue font-semibold'><span className='pr-2'>Programs</span> {!showAddForm && <Button className='bg-green-400 text-white' icon={<PlusCircleOutlined />} onClick={Add}>Add</Button>}</div>
     <div className={'mt-6 mr-2'}>
        {loading ? <PageSpinner /> : showAddForm ? <AddProgramForm onClose={CloseAdd} onFinish={onSubmit} /> : programs?.map(program => <ProgramListItem program={program} key={program.id} onEdit={onEdit} onArchive={onArchive}/>)}
        <Modal visible={showArchiveModal} centered={true} title='Are you Sure?' onCancel={closeModal} onOk={ArchiveProgram} cancelText='No' okText='Yes'><p>Do You Want to Archive This Program?</p></Modal>
        <Modal visible={showUnArchiveModal} centered={true} title='Are you Sure?' onCancel={closeModal} onOk={UnArchiveProgram} cancelText='No' okText='Yes'><p>Do You Want to UnArchive This Program?</p></Modal>
    </div>
    </div>
  )
}

export default MyPrograms