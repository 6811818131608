import * as React from 'react';
import {  createContext } from 'react';
import {  IAlert, IAlertProps } from './Alert';

export interface IAlertContext {
    actions: {
        addAlert: (alert :any) => void,
        removeAlert: (alert: any) => void
    }
}

interface IAlertContextstate {
    alerts: IAlert[]
}

export type AlertContextType = IAlertContext | any;

export const AlertContext = createContext<AlertContextType>({});
export const AlertContextConsumer = AlertContext.Consumer;

export default class AlertContextProvider extends React.Component< any, IAlertContextstate> {

    public constructor(props: any, context?: any) {
        super(props, context);
        this.state = {
          alerts: []
        };
      }


    private getRandomId = (): number =>{
        return Math.floor(100000 + Math.random() * 900000);
    }  

    private addAlert = (newAlert: IAlertProps): number => {
        const a: IAlertProps = {...newAlert, alertId: this.getRandomId()} 
        const alerts = [...this.state.alerts, a];
        this.setState({alerts});
        return a.alertId;
    }

    private removeAlert = (id: number) => {
        const alerts = this.state.alerts;
        const index = alerts.findIndex(al => al.alertId === id)
        alerts.splice(index, 1);
        this.setState({alerts});
    }

    public render() {
        const { state } = this;
        const actions = {
            addAlert: (newAlert: IAlert) => this.addAlert(newAlert),
            removeAlert: (index: IAlert) => this.removeAlert(index)
        }
        const context = {actions,state};

        return (
            <AlertContext.Provider value={context}>
                {this.props.children}
            </AlertContext.Provider>
        )
    }
}
