import Tooltip from "./Tooltip";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined"

interface ISectionSeparator {
    title: string;
    small?: boolean;
    toolTip?: string
    colorSecondary?: boolean;
}

const SectionSeparator  = ({title, small=false, toolTip='', colorSecondary=false}:ISectionSeparator) => (
    <div className={'flex items-center flex-nowrap mb-1'}>
        <p className={`pr-2 ${small ? 'text-sm' : 'text-lg'} font-bold ${colorSecondary ? 'text-fw-blue-light' : 'text-fw-orange'} whitespace-nowrap`}>{title}</p>
        {toolTip && <Tooltip content={toolTip} direction="right" >
            <InfoCircleOutlined className={'text-gray-400 self-center mr-2'} />
        </Tooltip>}
        <hr style={{borderColor: '#E2DFDF', width: '100%', marginTop: 10, marginBottom: 10 }} />
    </div>
)

export default SectionSeparator;