import { useNavigate, useParams } from "react-router";
import AddProgramForm from "./AddProgramForm";
import { useEffect, useState } from "react";
import { getProgram } from "../../services/UserService";
import { IProgram } from "../../types";
import PageSpinner from "../../components/base/PageSpinner";

const EditProgram = () => {
    const [programs, setPrograms] = useState<IProgram>()
    const [loading , setLoading] = useState<Boolean>(true)
    const {id} = useParams()
    const Navigate = useNavigate();
    
    useEffect(() => {
        getProgram(Number(id)).then(res => {
            setPrograms(res?.data)
            setLoading(false)
        }).catch(err=> console.error(err))
    }, [id])

    const onClose = () => {
        Navigate('/dashboard/programs')
    }

    const onFinish = () => {
        Navigate('/dashboard/programs')
    }

  return (
    <div className={'mt-6 ml-6'}>
        {loading ? <PageSpinner />:<AddProgramForm id={Number(id)} EditValues={programs} onClose={onClose} onFinish={onFinish}/>}
    </div>
  )
}

export default EditProgram