import * as React from 'react';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { AlertType } from './AlertEnums';
import { useEffect } from 'react';
import useAlert from './useAlert';
import { classNames } from '../../Utils';
import Icon, { Icons } from '../Icon';

export interface IAlertProps {
    type: AlertType;
    title?: string;
    description: string;
    clearFunc?: () => void;
    autoClose?:boolean;
    alertId: number;
}

export type IAlert = IAlertProps | any;

export const Alert = ({ type, title, description, clearFunc, autoClose, alertId }: IAlertProps) => {

    const styleClass = {
        root: classNames(
            'z-50',
            'w-full',
            'bg-white',
            'shadow-lg',
            'pointer-events-auto',
            'mt-4',
            'rounded-lg overflow-hidden',
            'transition', 
            'ease-in-out', 
            'duration-200'
        ),
        container: (alertType: string) => classNames(
            'shadow-xs',
            'overflow-hidden',
            alertType === 'success' && 'bg-green-400', 
            alertType === 'error' && 'bg-red-400', 
            alertType === 'info' && 'bg-blue-500',
            alertType === 'warning' && 'bg-orange-400',
            'opacity-75'
        ),
        title:  (alertType: string) => classNames(
            'text-sm',
            'leading-5',
            'font-medium',
            'text-white'
        ),
        description:  (alertType: string) => classNames(
            'mt-1',
            'text-sm',
            'leading-4',
            'text-white',
            'font-light'
        )
    }

    const { removeAlert } = useAlert();
    const duration:number = 10000; // 2seconds

    useEffect(() => {
        if(autoClose) {
            setTimeout(removeAlert , duration);
        }
        return () => {
            clearTimeout();
        }
    })

    const remove = (id:number) => {
        if (clearFunc) {
            clearFunc();
            removeAlert(id);
            return;
        }
        removeAlert(id);
    }
    
    const renderSwitch = (alertType: AlertType) => {
       
        switch(alertType) {
            case 'success' :
                return Icons.CHECK_GREEN_CIRLCE;
            case 'info' :
                return Icons.INFO_BLUE;
            case 'warning' :
                return Icons.WARNING_ORANGE;
            case 'error' :
                return Icons.DANGER_GRAY;
            default:
                return Icons.INFO_BLUE;
        }
    }

    const charLength = 150;
   
    return (
        <div style={{maxWidth: '30rem'}} className={styleClass.root}>
            <div className={styleClass.container(type || "info")}>
                <div className={'p-4'}>
                <div className={`flex items-center`}>
                    <div className={'flex'}>
                        <Icon styleClass={'w-5 h-5 mt-1'} icon={renderSwitch(type)} />
                    </div>
                    <div style={{marginTop: 2}} className="ml-3 w-0 flex-1 pt-0.5">
                        {title && <p className={styleClass.title(type || "info")}>
                            {(title)}
                        </p>}
                        <p className={styleClass.description(type || "info")}>
                            {description ? description.length > charLength ? ((description.substring(0, charLength)) + '...') : (description) : ''}
                        </p>
                    </div>
                    <div className="ml-3 shrink-0 flex">
                       <CloseOutlined className={'text-white'} onClick={(e) => {
                            e.preventDefault();
                            remove(alertId);
                            }} />
                    </div>
                </div>
                </div>
            </div>
    </div>);
}
