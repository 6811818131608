import React, { useState } from "react";

interface ITooltip {
    direction: 'top' | 'right' | 'left' | 'bottom';
    content: JSX.Element | string;
    children: JSX.Element;
    delay?: number;
}

const Tooltip = ({direction, content, children, delay}:ITooltip) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`Tooltip-Tip ${direction || "top"}`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
