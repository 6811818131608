import { Avatar } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useMenu } from "../../context/MenuContext";
import useWindowDimensions from "../../hooks/UseWindowDimenstions";
import { baseAddress } from "../../services/Request";
import { getOrganizationProfile, getUniversityProfile } from "../../services/UserService";
import { IBusiness, IUniversity } from "../../types";
import { getProfileImgUrl } from "../../Utility";
import { classNames } from "../../Utils";
import Notifications from "../account/Notifications";
import CloseClickOutside from "./ClickCloseOutside";

const styleClass = {
    menuWrapper: (show: boolean) => classNames(
        'flex',
        'flex-col',
        'bg-fw-blue',
        'absolute',
        'top-full',
        'z-50',
        'w-screen',
        'transition-all ease-in-out duration-300',
        show ? 'left-0' : '-left-full'
    ),
    menu: (active:boolean, first:boolean, hide:boolean) => classNames(
        'flex',
        'flex-row',
        'p-5',
        'items-center',
        active ? 'font-bold': 'font-normal',
        active ? 'text-fw-orange' : 'text-gray-400 hover:text-fw-orange',
        !active ? `${first ? 'border-t' : 'border-t-0'} border border-b-half border-r-0 border-l-0  border-gray-700 hover:bg-fw-white-active hover:opacity-75` : 'bg-fw-white-active',
        'text-md',
        'cursor-pointer',
        'transition duration-500 ease-in-out',
        'h-12',
        hide && 'hidden'
    ),
    menuIcon: classNames(
        'mr-6', 
        'h-full', 
        'flex', 
        'items-center' 
    )
}

const FWHeader = ()=> {
    const {isLoggedIn, currentUser} = useAuth();
    const [organization, setOrganization] = useState<IBusiness | null>(null);
    const [university, setUniversity] = useState<IUniversity | null>(null);
    const [isStaff, setIsStaff] = useState<boolean>(false);
    const [isUniversity, setIsUniversity] = useState<boolean>(false);
    const {width} = useWindowDimensions()
    const navigate = useNavigate();
    const {menuItems, activeMenu, setActiveMenu,menuExpanded} = useMenu()
    const [showDetail, setShowDetail] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const {logOut} = useAuth();

    useEffect(()=> {
        if(currentUser && currentUser.is_staff) {
            const {profile} = currentUser
            setIsStaff(true)
            if(profile.business) {
                getOrganizationProfile(profile.business)
                .then((res) => {
                    if(res?.data) {
                        setOrganization(res.data);
                    }
                }).catch(err => console.log(err))
            }
        }
        else if(currentUser && currentUser.is_university_staff) {
            const {profile} = currentUser
            setIsUniversity(true)
            if(profile.university) {
                getUniversityProfile(profile.university)
                .then((res) => {
                    if(res?.data) {
                        setUniversity(res.data);
                    }
                }).catch(err => console.log(err))
            }
        }
    }, [currentUser])

    const showMenu = useMemo(() => {
        return width < 769
    }, [width])

    const getProfileCombinedImgUrl =()=> {
        if(isStaff) {
            const logo = organization?.logo  ? organization?.logo.startsWith('/media') ? baseAddress + organization?.logo : organization?.logo : null;
            return logo ?? 'https://via.placeholder.com/150';
        }
        if(isUniversity) {
            const logo = university?.logo  ? university?.logo.startsWith('/media') ? baseAddress + university?.logo : university?.logo : null;
            return logo ?? 'https://via.placeholder.com/150';
        }
       return currentUser ? getProfileImgUrl(currentUser) : 'https://via.placeholder.com/150';
    }

    const getName = ()=> {
       if(isStaff) {
           return `${organization?.name ?? ''} Staff`;
       }
       else if(isUniversity){
        return `${university?.name ?? ''} Staff`;
       }
       return currentUser?.profile?.first_name ?? 'Job Seeker'; 
    }

    const onMenuClick = (index:number, url:string) => {
        setActiveMenu(index);
        setShowDetail(false)
        const state = index === 1 ? {showAllJobs: true} : undefined;
        navigate(url,{state}) 
    }

    const toggleMenu = () => {
        setShowDetail(prev => !prev)
    }

    const onClickAvatar = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setShowDropdown(prev => !prev)
    }
    
    const onSignOut = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        logOut()
    }

    return(<div className={'h-max relative'}>
                <div className={`${showMenu ? 'bg-fw-blue text-white' : 'bg-fw-light-gray shadow-md' } w-full flex justify-between flex-wrap px-6 pt-4 pb-2 fw_main_header`}>    
                    <div className={'flex-auto flex items-center md:justify-start mb-2 md:mb-0'}>
                        {showMenu && isLoggedIn() && <div onClick={toggleMenu} className={'cursor-pointer text-white mt-0.5 mr-3'}>
                            <img src="/hamburger.svg" alt="" style={{height: 8, width: 'auto', color: 'white'}} />
                        </div>}
                        <img src={showMenu ? `/fw-icon-white.png` : `/fairway_logo_black_text.png`} alt="" className={'-mb-1'} style={{height: showMenu ? 24 : 32, width: 'auto', color: 'white'}} />
                    </div>  
                    <div className={'flex-none w-max flex items-center cursor-pointer justify-between md:justify-start'}>
                        <div className={`text-sm md:text-md ${!showMenu ? 'text-gray-700' : 'text-white' } flex`}>Hi,<span className={'font-semibold pl-1'}>{getName()}</span></div>
                            <CloseClickOutside onClose={() => setShowDropdown(false)}>
                            <>
                                {isLoggedIn() ? <div className={'relative'}>
                                    <div onClick={onClickAvatar}>
                                        <Avatar className={'ml-2 w-5 h-5'} src={getProfileCombinedImgUrl()} />
                                    </div>
                                {showDropdown && 
                                    <div onClick={onSignOut} className={'absolute top-full right-0 z-[100] mt-1 bg-white text-gray-500 p-2 w-20 h-auto shadow rounded'}>
                                        Sign out 
                                    </div>}
                                </div> : null}
                            </>
                            </CloseClickOutside>
                        {currentUser && !currentUser?.is_staff && !currentUser?.is_university_staff &&  <Notifications userId={currentUser?.id} />}
                    </div>
                </div>
                {menuExpanded === 'none' &&
                    <div className={styleClass.menuWrapper(showDetail)}>
                        {menuItems && menuItems.map(menu =>  
                            <div key={menu.id} onClick={() => onMenuClick(menu.id, menu.url)} className={styleClass.menu(activeMenu === menu.id, menu.id===0, menu.hidden)}>
                                <div className={styleClass.menuIcon}>
                                    {menu.id === activeMenu ? menu.activeIcon ? menu.activeIcon : menu.icon : menu.icon}
                                </div>
                                <p>{menu.name}</p>
                            </div>
                        )}
                </div>}
            </div>)
}

export default FWHeader;