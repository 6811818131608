import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import { Form, Input, Button, Alert } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import useAlert from "../toast/useAlert";

interface IRequestAccess {
    onLogin :() => void
}

const RequestAccess:FC<IRequestAccess> = ({onLogin}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError ] = useState<string>('');
    const [form] = Form.useForm();
    const authContext = useAuth();
    const {addAlert} = useAlert();

    const navigate = useNavigate();

    const onSignup =(values: any)=> {
        if(values && values.email && values.password) {
            setLoading(true);
            //TODO next
            // authContext.logIn({email: values.email, password:values.password, onSuccess, onError})
        }
    }

    const onSuccess = ()=> {
        setLoading(false);
        setTimeout(()=> {
            navigate('/dashboard')
        }, 300)
    }
    const onError = (errData:any)=> {
        addAlert({type: 'error', title: 'error', description: JSON.stringify(errData), autoClose:false})
        setLoading(false)  
    }

    return (
        <div className={'flex flex-col items-center'}>
            <Form
                form={form}
                name="login"
                initialValues={{ remember: true}}
                onFinish={onSignup}
                className={'w-full'}
            >
                <Form.Item 
                    className={'mb-4 mt-4 md:mt-12'}
                    name="text"
                    rules={[{ required: true, message: 'Please input your text!' }]}
                >
                    <Input placeholder={'University or Institution Name'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>

                <Form.Item 
                    className={'mb-4 mt-4'}
                    name="number"
                    rules={[{ required: true, message: 'Please input your VAT ID!' }, {type: 'number', message: 'Please enter a valid VAT ID'}]}
                >
                    <Input placeholder={'VAT ID'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>

                <Form.Item 
                    className={'mb-4 mt-4'}
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'Please enter a valid email'}]}
                >
                    <Input placeholder={'admin person email address'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>
        
                <Form.Item
                    className={'mb-0'}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder={'password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>
                <Button className="mt-4" loading={loading} disabled={loading} block type="primary" htmlType="submit">
                    Request Access
                </Button>

                <div className={'w-full flex flex-row items-center my-2 justify-between'}>
                    <p className={'text-xs text-gray-400 font-medium pt-1 cursor-pointer'}>Already Registered?</p>
                    <p onClick={onLogin} className={'text-xs text-fw-orange font-medium pt-1 cursor-pointer'}>Sign In</p>
                </div>
            </Form>
            {error ? (
                <Alert message={error} type="error" closable afterClose={() => setError('')} />
            ) : null}
        </div>
    );
}
export default RequestAccess;