interface IBullet {
    text:string;
    children?: JSX.Element;
    noMargin?: boolean;
    className?: string;
}

const Bullet = ({text, children, noMargin, className=''}:IBullet) => {
    return(
        <div className={`flex items-center ${noMargin ? '' : 'mb-1'} ${className}`}>
            <img alt="icon" className={'pr-2 flex shrink-0'} src={'/clarity_dot-circle-line.svg'} width="25px" height="25px"/>
            {children ? children : <p className={'font-thin text-sm ml-2 text-gray-400'}>{text}</p>}
        </div>
    )
}

export default Bullet;