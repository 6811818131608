import { Form, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { FC, useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import { addMotivationAnswer, getMotivationQuestions } from "../../../services/MotivationService";
import { IMotivationQuestion } from "../../../types";

interface IMotivationAnswer {
    selectionId: number
    onSubmit: () => void
    onCancel: () => void
}

const MotivationAnswer:FC<IMotivationAnswer> = ({selectionId, onSubmit, onCancel}) => {

    const [form] = useForm()
    const [questions, setQuestions] = useState<IMotivationQuestion[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getMotivationQuestions(selectionId).then(res => {
            setQuestions(res?.data)
        }).catch(err => console.log(err)).finally(() => setLoading(false))
    }, [selectionId])


    const onSaveMotivation = async (values: any) => {
        const endpoints = []
        for (const prop in values) {
            endpoints.push({question: +prop, answer: values[prop] })
        }
        setLoading(true)
        Promise.all(endpoints.map((endpoint) => addMotivationAnswer(selectionId, endpoint))).then((res)=> {
            onSubmit()
        }).catch(err => console.log(err)).finally(() => setLoading(false))
    }

    const submitModal = () => {
        form.submit()
    }

    return(
        <Modal 
            title={'Answer Motivation Question'} className={'w-[60%] min-h-[70%]'} 
            onCancel={onCancel} 
            onSubmit={submitModal} 
            submitDisabled={loading}
         >
            <Form
                name="answerMotivation"
                onFinish={onSaveMotivation}
                form={form}
                autoComplete="off"
                className={'w-3/4 flex flex-col'}
                layout={'vertical'}
            >   
                {loading ?
                <Spin/> :
                questions.map((q: IMotivationQuestion) =>
                    (<Form.Item 
                        label={q.question}
                        className={'w-full'}
                        name={q.id}
                        rules={[{ required: true, message: 'This answer is required!' }]}
                    >
                        <TextArea placeholder={'Put your answer here'} showCount maxLength={q.answer_length} rows={5} />
                    </Form.Item>))
                }   
            </Form>
        </Modal>
    )
}

export default MotivationAnswer