import { useContext } from 'react';
import { AlertContext}  from './AlertContext';

function useAlert() {
  const context = useContext(AlertContext);

  return { alerts: context.state.alerts,  addAlert: context.actions.addAlert, removeAlert: context.actions.removeAlert };
}

export default useAlert;
