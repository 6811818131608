import { Form, Input, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useMemo, useState } from 'react'
import Modal from '../../../components/base/Modal'
import { addExaminer, updateExaminer } from '../../../services/ExamService'
import { IExaminer } from '../../../types'

interface IExaminerModal {
    selectionId: number
    editingMode: boolean
    onClose: () => void
    onSubmit: () => void
    examiner?: IExaminer
}

const ExaminerModal = ({ selectionId, editingMode, onClose, onSubmit, examiner}: IExaminerModal) => {

    const [examinerForm] = Form.useForm()
    const [submitLoading, setSubmitLoading] = useState(false)
    const submitForm = () => {
        examinerForm.submit()
    }

    const submitExaminer = (values: any) => {
        setSubmitLoading(true)
        const {institution_name, location, description, responsible_person_name, responsible_person_email,
            responsible_person_phone, default_examiner
        } = values
        if(editingMode && examiner) {
            const updatedExaminer: IExaminer = {
                id: examiner.id,
                institution_name: institution_name,
                description: description,
                location: location,
                responsible_person_name: responsible_person_name,
                responsible_person_email: responsible_person_email,
                responsible_person_phone: responsible_person_phone,
                is_user_input: true,
                default_examiner: default_examiner
            }
            updateExaminer(selectionId, updatedExaminer).then((res) => {
            }).finally(() => {
                setSubmitLoading(false)
                onSubmit?.()
            })
        } else {
            const newExaminer: Omit<IExaminer, 'id'> = {
                institution_name: institution_name,
                description: description,
                location: location,
                responsible_person_name: responsible_person_name,
                responsible_person_email: responsible_person_email,
                responsible_person_phone: responsible_person_phone,
                is_user_input: true,
                default_examiner: default_examiner
            }
            addExaminer(selectionId, newExaminer).then((examinerRes) => {
            }).finally(() => {
                setSubmitLoading(false)
                onSubmit?.()
            })
        }
    }

    const getExaminerIntialValues = useMemo(()=> {
        if(editingMode && examiner) {
            const {institution_name, location, description, responsible_person_name, responsible_person_email,
                responsible_person_phone} = examiner
            return {
                institution_name, 
                location, 
                description, 
                responsible_person_name, 
                responsible_person_email,
                responsible_person_phone
            };
        }
        return {}
    }, [editingMode, examiner])

    return(
        <Modal title={'Examiner Details'} className={'w-[70%] min-h-[80%]'} onCancel={onClose} 
            onSubmit={submitForm} submitText={editingMode ? 'Save' : 'Add' } submitDisabled={submitLoading} >
            <Form
                name="examinerForm"
                onFinish={submitExaminer}
                className={'w-2/3 grid grid-cols-2 gap-2'}
                form={examinerForm}
                autoComplete="off"
                layout={'horizontal'}
                requiredMark={false}
                initialValues={getExaminerIntialValues}
            >
                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="institution_name"
                    rules={[{ required: true, message: 'Institution name Required!' }]}
                >
                    <Input placeholder={'Institution Name'}/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="location"
                    rules={[{ required: true, message: 'Institution address Required!' }]}
                >
                    <Input placeholder={'Institution city'}/>
                </Form.Item>
                
                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="responsible_person_name"
                    rules={[{ required: true, message: 'Responsible person name is required!' }]}
                >
                    <Input placeholder={'Responsible person'}/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-2'}
                    name="responsible_person_email"
                    rules={[{ required: true, message: 'Email is required!' }]}
                >
                    <Input placeholder={'Responsible Email'} type={'email'} />
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-2'}
                    name="responsible_person_phone"
                    rules={[{ required: true, message: 'Phone number is required!' }]}
                >
                    <Input placeholder={'Responsible phone number'} type={'tel'} />
                </Form.Item>
                <Form.Item 
                    className={'col-span-1 md:col-span-4 row-span-2'}
                    name="description"
                >
                    <TextArea placeholder={'Description (optional)'} rows={3} />
                </Form.Item>
                <Form.Item 
                    className={'col-span-1 md:col-span-4 row-span-2'}
                    name="default_examiner"
                    label={'Set up as Default Examiner'}
                >
                    <Switch  />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ExaminerModal