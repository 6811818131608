import { Badge, Button, Spin } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { downloadAttendanceSheet, downloadGradeSheet, downloadInfoSheet, getExam, uploadGradeSheet } from "../../../services/ExamService";
import ExamComplete from "./ExamComplete";
import FileUploader from "../../../components/base/FileUploader";
import { IExam, IJob } from "../../../types";
import moment from "moment";
import useAlert from "../../../components/toast/useAlert";

interface IExamWagonDetail {
    selectionId: number
    job: IJob
    reloadParent: () => void
}

const ExamWagonDetail:FC<IExamWagonDetail> = ({selectionId, job, reloadParent}) => {

    const {addAlert} = useAlert()
    const [loading, setLoading] = useState(false)
    const [finishExam, setFinishExam] = useState(false)
    const [exam, setExam] = useState<IExam>()
    const [downloadingAttendance, setDownloadingAttendance] = useState(false)
    const [downloadingGrade, setDownloadingGrade] = useState(false)
    const [downloadingInfo, setDownloadingInfo] = useState(false)
    const [examResultFile, setExamResultFile] = useState<any>(null)
    const [uploading, setUploading] = useState(false)
    const [reload, setReload] = useState(false)
    
    useEffect(() => {
       setLoading(true)
       getExam(selectionId).then((res) => {
          setExam(res?.data[0])
       }).finally(() => setLoading(false))
       
    }, [selectionId, reload])

    const onSubmitFinishModal = () => {
        setFinishExam(false)
        reloadParent()
    }   

    const onDownloadGrade = () => {
        setDownloadingGrade(true)
        downloadGradeSheet(selectionId).then((res) => {
            return {data : res?.data, name: res?.headers['filename'] ?? `job-${job?.title ?? ''}-Grade_sheet.xlsx`}
            }).then(({data, name}) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(data);
                link.download = name;
                link.click();
        }).finally(() => setDownloadingGrade(false))
    }

    const onDownloadInfo = () => {
        setDownloadingInfo(true)
        downloadInfoSheet(selectionId).then((res) => {
            return {data : res?.data, name: res?.headers['filename'] ?? `job-${job?.title ?? ''}-Info_sheet.docx`}
            }).then(({data, name}) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(data);
                link.download = name;
                link.click();
        }).finally(() => setDownloadingInfo(false))
    }

    const onDownloadAttendance = () => {
        setDownloadingAttendance(true)
        downloadAttendanceSheet(selectionId).then((res) => {
            return {data : res?.data, name: res?.headers['filename'] ?? `job-${job?.title ?? ''}-Attendance_sheet.xlsx`}
            }).then(({data, name}) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(data);
                link.download = name;
                link.click();
        }).finally(() => setDownloadingAttendance(false))
    }

    const uploadResultFile = () => {
        if(!examResultFile) {
            alert('No File selected');
            return;
        }
        const formData = new FormData();
        formData.append("grade_file", examResultFile);
      
        if(selectionId) {
            setUploading(true)
            uploadGradeSheet(selectionId, formData).then((res)=> {
                addAlert({type: 'success', description: 'Grade sheet uploaded successfully', autoClose: false})
            }).then(() => {
                setReload(prev => !prev)
                reloadParent()
            })
            .catch(error => {
                if(error.response.status !== 401) {
                    const errorMessage = error.response.data?.grade_file?.join(',') || 'Error uploading file'
                    addAlert({type: 'error', title: 'error', description: errorMessage, autoClose: true})
                }
            }).finally(() => {
                setUploading(false)
                setExamResultFile(null)
            })
        }
    }

    const examFinishingIsDisabled = useMemo(() => {
      return moment().isBefore(moment(exam?.date), 'day') || !exam?.grade_uploaded
    }, [exam])

    return(
        <div className={'flex flex-col gap-1 text-gray-500 mt-4 ml-2'}>
            <p className={'text-lg font-bold'}>Current Step: <span className={'text-fw-orange'}>Examination</span></p>

            {loading ? <div className={'flex flex-col gap-1'}><Spin /> </div> :
            <>
            <div className={'flex items-center gap-4 mb-1'}>
                <p className={'text-base font-medium text-fw-orange'}>Available Operations</p>
                <Button onClick={onDownloadAttendance} disabled={downloadingAttendance} type={'ghost'} className={'w-max'}>Download Attendance Sheet</Button>
                <Button onClick={onDownloadGrade} disabled={downloadingGrade} type={'ghost'} className={'w-max'}>Download Grade Sheet</Button>
                <Button onClick={onDownloadInfo} disabled={downloadingInfo} type={'ghost'} className={'w-max'}>Download infosheet</Button>
            </div>
            <div className={'flex gap-2 items-center mt-2'}>
                <Button onClick={() => setFinishExam(true)} disabled={examFinishingIsDisabled} type={'primary'} className={'w-max'}>Finish Exam Step</Button>
                {!exam?.grade_uploaded && <Badge status={exam?.grade_uploaded ? 'success' : 'error'} text={'Grade Sheet Not Uploaded'} />}
                {moment().isBefore(moment(exam?.date), 'day') && <Badge status={!moment().isBefore(moment(exam?.date), 'day') ? 'success' : 'error'} text={'Exam is not over yet'} />}
            </div>
            <div className={'flex items-center gap-4 mb-2 font-medium'}>
                <p className={'text-sm'}>Exam Date:
                    <span className={'pl-2 text-fw-orange'}>
                    {moment(exam?.date).isBefore(moment(), 'day') ? 'Exam is over' : moment(exam?.date).format('DD/MM/YYYY')}    
                    </span>
                </p>
            </div>
            <>
                <p className={'text-base font-medium text-fw-orange'}>Upload Exam Result</p>
                {exam?.grade_uploaded &&
                    <p className={'text-sm text-fw-green'}>Exam Result has already been uploaded. Please upload again to replace results.</p>}
                <div className={'flex gap-4 items-center'}>
                    {moment(exam?.date).isSameOrBefore(moment(), 'day') ? (
                        <>  
                            <div className={'flex flex-col gap-2'}>
                                <div style={{ maxWidth: 200 }}>
                                    <FileUploader
                                        onFileSelectSuccess={(file) => setExamResultFile(file)}
                                        onFileSelectError={({ error }) => alert(error)}
                                        fileType={'xlsx'} />
                                </div>
                            </div>
                            <Button onClick={uploadResultFile} disabled={uploading} type={'default'} className={'w-max'}>Upload</Button>
                            {examResultFile && <span className={'text-xs text-gray-500'}>Selected File:{examResultFile?.name}</span>}
                        </>)
                    : <p className={'text-sm text-gray-500'}>Exam is not over yet. You can only upload once Exam is over.</p>}    
                </div>
            </>
            </>}
            {finishExam &&
            <ExamComplete 
                selectionId={selectionId}
                onClose={() => setFinishExam(false)}
                onSubmit={onSubmitFinishModal}
            />}
        </div>
    )
}

export default ExamWagonDetail