import axios from "axios";
import { IApplicationStatus, IJob } from "../types";
import Request from "./Request"

export const getOrganizationJobs = (limit?: number) => {
  return Request('GET', `/jobs/?limit=${limit ?? 10}`);
} 

export const getUserAppliedJobs = () => {
  return Request('GET', '/jobs/applied/');
} 

export const applyToJob = (jobId:number) => {
  return Request('POST', `/jobs/${jobId}/apply/`);
} 

export const createJob = (job:Partial<IJob>) => {
  return Request('POST', '/jobs/', job);
} 

export const editJob = (job:Partial<IJob>) => {
  return Request('PUT', `/jobs/${job?.id}/`, job);
} 

export const getJob= (jobId:number) => {
  return Request('GET', `/jobs/${jobId}/`);
} 

export const deleteJob = (jobId:number) => {
  return Request('DELETE', `/jobs/${jobId}/`);
} 

export const getJobApplicants = (jobId:number) => {
  return Request('GET', `/jobs/${jobId}/applicants/`);
} 

export const searchJob = (searchVal:string, employerId?: number, industries?: string) => {
  return Request('GET', `/jobs/?search=${searchVal}${employerId ? `&employer=${employerId}` : ''}${industries ?? ''}`);
} 

export const getJobIndustries = () => {
  return axios.get(`https://api.github.com/gists/7cc8b5992ddb275b6804893d88af8f2e`);
}

export const updateApplicationStatus = (body: IApplicationStatus) => {
  return Request('POST', `/applicationstatus/`, body);
} 

export const withdrawApplication = (jobId:number)=> {
  return Request('POST', `/jobs/${jobId}/unapply/`);
}

