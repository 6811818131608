import { Badge, Card, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useMemo } from "react";
import { updateCVEducation } from "../../services/CVService";
import { ICVEducation } from "../../types";
import Modal from "../base/Modal";
import useAlert from "../toast/useAlert";

interface IVerifyEducation {
    fullName: string
    cvId: number
    education: ICVEducation
    onSubmit: () => void
    onClose: () => void
}

const VerifyEducation:React.FC<IVerifyEducation> = ({fullName, cvId, education, onSubmit, onClose}) => {

    const [credentialForm] = Form.useForm();
    const {addAlert} = useAlert();
    const [loading, setLoading] = React.useState(false);

    const submitVerification = (values: any) => {
        setLoading(true);
        const {userName, study, school, hash, holderSignedCredentialDID}  = values;
        axios.post(`https://dev.fairway.work/prism/api/verify/`, {
            userName,
            'holderSignedCredentialDID':{
                'encodedSignedCredential' : holderSignedCredentialDID,
                'proof' :{
                    hash,
                    index : 0,
                    "siblings": []
                }
            },
            "education": {
                study,
                school
            }
        }).then(res => {
            let verified = false;
            if(res.data.flag) {
                addAlert({type: 'success', title: 'success', description: 'Verification successful', autoClose: true})
                verified = true;
            } else {
                addAlert({type: 'error', title: 'error', description: res.data.message, autoClose: true})
            }
            updateCVEducation(cvId, education.id ?? 0, {...education, is_verified: verified}).then(() => {
                onSubmit();
            })
        }).catch(err => {
            addAlert({type: 'error', title: 'error', description: `${err?.message}`, autoClose: true})
        }).finally(() => {
            setLoading(false)
        })
    }

    const onSubmitModal = () => {
        credentialForm.submit()
    }

    const initialValues = useMemo(() => {
      return  {
            userName: fullName,
            study: education.field_of_study,
            school: education.institute_name,
            holderSignedCredentialDID: ''
    }}, [education, fullName])

    return (
        <Modal submitDisabled={loading} className={'w-[60%] min-h-[60%] max-w-xl'} title={'Verify Education Credential'} onCancel={onClose} onSubmit={onSubmitModal}>
            <Form
                name="credentialsForm"
                onFinish={submitVerification}
                className={'w-full flex flex-col gap-1'}
                form={credentialForm}
                autoComplete="off"
                layout={'vertical'}
                requiredMark={false}
                initialValues={initialValues}
                >
                    <Badge className={'mb-3'}>
                        <Card title="Please input details exactly as it it appear in your issued digital credential" size="small">
                            <p className={'text-xs text-gray-500'}>Please edit the education in your CV if the below details doesn't align</p>
                        </Card>
                    </Badge>
                    
                    <Form.Item 
                        name="userName"
                        rules={[{ required: true, message: 'Full name is required!' }]}
                    >
                        <Input placeholder={'Full name of Applicant'} disabled={true} />
                    </Form.Item>
                    <Form.Item 
                        name="study"
                        rules={[{ required: true, message: 'Field of study is required!' }]}
                    >
                        <Input placeholder={'Field of Study, eg. Computer Science'} disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        name="school"
                        rules={[{ required: true, message: 'Institution is required!'}]}
                    >
                        <Input placeholder={'Accrediating Institution, eg. Gondar University'} disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        name="hash"
                        rules={[{ required: true, message: 'hash code is required!'}]}
                    >
                        <Input placeholder={'hash code paste here'} />
                    </Form.Item>
                    <Form.Item 
                        name="holderSignedCredentialDID"
                        rules={[{ required: true, message: 'Holder DID is required!' }]}
                    >
                        <TextArea placeholder={'Holder DID token'} rows={6} />
                    </Form.Item>
            </Form>
        </Modal>
    );
};

export default VerifyEducation;