import React , {useState } from 'react'

export const clearContext = React.createContext<any>(null)

const SearchContext = ({children}:any) => {
    const [clear , setClear] = useState(false)
  return (
    <clearContext.Provider value={{clear, setClear}}>
        {children}
    </clearContext.Provider>
  )
}

export default SearchContext