import * as React from 'react';
import { classNames } from '../../Utils';
import  {Alert, IAlertProps} from './Alert';
import useAlert from './useAlert';


export const AlertContainer =  () => {

    const styleClass = {
        root: classNames(
            'fixed',
            'inset-0', 
            'flex',
            'flex-col',
            'items-end', 
            'justify-start', 
            'px-4', 
            'py-6', 
            'pointer-events-none',
            'sm:p-6', 
            'mt-20 sm:mt-16',
            'h-screen',
            'overflow-auto'
        )
    }

    const { alerts }  = useAlert();

    return (
             
        <div style={{zIndex: 1000}}  className={styleClass.root}>
                {alerts && alerts.length > 0 ?
                    alerts.map(({alertId, ...rest}: IAlertProps, i:number)=>{
                        return  <Alert alertId={alertId} key={i} {...rest} />
                    }) : null
                }
        </div>
    )

}