import { Avatar, Button, DatePicker, Descriptions, Form, Input, InputNumber, Select } from 'antd';
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { baseAddress } from '../services/Request';
import { getOrganizationProfile, getUniversityProfile, updateOrganizationLogo, updateOrganizationProfile, updateUniversityLogo, updateUniversityProfile, updateUser, updateUserPhoto } from '../services/UserService';
import { EthiopianCities, IBusiness, IProfile, IUniversity, IUser } from '../types';
import { isEmpty } from '../Utils';
import { useNavigate } from 'react-router-dom';
import { getProfileImgUrl, resizeImage } from '../Utility';
import FileUploader from '../components/base/FileUploader';
import PageSpinner from '../components/base/PageSpinner';
import UpdatePassword from './account/UpdatePassword';
import FWSubHeader from '../components/base/FWSubHeader';
import Page from '../components/base/Page';
import useAlert from '../components/toast/useAlert';
import useDevice from '../hooks/useDevice';


const Account = () => {

    const {currentUser, reload} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const {addAlert} = useAlert();
    const { isMobile, isTablet } = useDevice();

    const [userEditingMode, setUserEditingMode] = useState<boolean>(false);
    const [profileEditingMode, setProfileEditingMode] = useState<boolean>(false);
    const [orgEditingMode, setOrgEditingMode] = useState<boolean>(false);
    const [isOrganization, setIsOrganization] = useState<boolean>(false);
    const [isUniversityStaff ,setIsUniversityStaff] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showBackBtn, setShowBackBtn] = useState<boolean>(false);

    const [organizationProfile, setOrganizationProfile] = useState<IBusiness>({} as IBusiness);
    const [universityProfile, setUniversityProfile] = useState<IUniversity>({} as IUniversity);
    const [user, setUser] = useState<IUser>({} as IUser);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [photoFile, setPhotoFile] = useState<any>(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false)

    const contentStyle: CSSProperties = { fontWeight: 'bold', whiteSpace: 'nowrap' }
    const { Option } = Select;

    const toggleEdit = () => {
        setUserEditingMode(prev => !prev);
    }

    const toggleOrgEdit = () => {
        setOrgEditingMode(prev => !prev);
    }

    useEffect(()=> {
        if(currentUser) {
            setIsOrganization(currentUser?.is_staff ?? false)
            setIsUniversityStaff(currentUser?.is_university_staff ?? false)

            if(currentUser?.is_staff && currentUser.profile.business) {
                setLoading(true);
                getOrganizationProfile(currentUser.profile.business)
                .then((res) => {
                    if(res?.data) {
                        setOrganizationProfile(res.data);
                    }
                }).catch(err => console.log(err)).finally(()=> {setLoading(false)})
            } 
            else if(currentUser?.is_university_staff && currentUser.profile.university) {
                setLoading(true);
                getUniversityProfile(currentUser.profile.university)
                .then((res) => {
                    if(res?.data) {                      
                        setUniversityProfile(res.data);
                    }
                }).catch(err => console.log(err)).finally(()=> {setLoading(false)})
            } 
            else {
                setUser(currentUser);                
            }
        }

        if(location.state?.isFromCv) {
            setShowBackBtn(true);
        }
    }, [currentUser, location])

    const getUserIntialValues =()=> {
        if(!isEmpty(user)) {
            const {first_name, last_name, phone_number, secondary_phone, gender, photo, date_of_birth, nationality, city, subcity, woreda, house_no, total_years_of_experience, expected_salary, has_drivers_license} : IProfile = user?.profile; 
            return {first_name,last_name,phone_number,secondary_phone, gender,photo, date_of_birth: date_of_birth ? moment(date_of_birth) : null, nationality, city, subcity, woreda, house_no, total_years_of_experience, expected_salary, has_drivers_license};
        }
        return {};
    }

    const getOrgIntialValues =()=> {
        if(!isEmpty(organizationProfile)) {
            const { year_established, name, description, city, sector, number_of_employees } : IBusiness = organizationProfile; 
            return {name, description, city, sector, number_of_employees, year_established: year_established ? moment(year_established) : null};
        }
        return {};
    }
    const getUniIntialValues =()=> {
        if(!isEmpty(universityProfile)) {
            const { year_established, name, description, city, proofspace_serviceID, address, website } : IUniversity = universityProfile; 
            return {name, description, city, proofspace_serviceID, address, website, year_established: year_established ? moment(year_established) : null};
        }
        return {};
    }

    const updateUserProfile = (values: IProfile) => {
        const updatedUser:IUser = {...user, profile: {...values, date_of_birth: values.date_of_birth ? moment(values.date_of_birth).format('YYYY-MM-DD') : null}};
        setLoading(true);
        updateUser(updatedUser).then((res)=> {
            reload(res?.data?.id)
            setUserEditingMode(false); 
            setLoading(false);           
        }).catch(err => console.log(err))
    }

    const updateOrgProfile = (values: IBusiness) => {
        Object.assign(values, {id: organizationProfile?.id, year_established: values.year_established ? moment(values.year_established).format('YYYY-MM-DD') : null})
        setLoading(true);
        updateOrganizationProfile(values).then((res)=> {
            setOrganizationProfile(res?.data)
            setOrgEditingMode(false);
            setLoading(false);
        }).catch(err => console.log(err))
    }

    const updateUniProfile = (values: IUniversity) => {
        Object.assign(values, {id: universityProfile?.id, year_established: values.year_established ? moment(values.year_established).format('YYYY-MM-DD') : null})
        setLoading(true);
        updateUniversityProfile(values).then((res)=> {
            setOrganizationProfile(res?.data)
            setOrgEditingMode(false);
            setLoading(false);
        }).catch(err => console.log(err))
    }

    const updateOrgLogo = ()=> {
        if(!selectedFile) {
            alert('No logo selected');
            return;
        }
        const formData = new FormData();
        formData.append("logo", selectedFile);
      
        if(organizationProfile?.id) {
            updateOrganizationLogo(organizationProfile?.id, formData).then((res)=> {
                setOrganizationProfile(res?.data);
                setOrgEditingMode(false);
                setProfileEditingMode(false);
                setSelectedFile(null);
            }).catch(err => console.log(err))
        }
        else if(universityProfile?.id) {
            updateUniversityLogo(universityProfile?.id, formData).then((res)=> {
                setUniversityProfile(res?.data);
                setOrgEditingMode(false);
                setProfileEditingMode(false);
                setSelectedFile(null);
            }).catch(err => console.log(err))
        }
    }

    const updateJobSeekerPhoto = async ()=> {
        if(!photoFile) {
            alert('No Photo selected');
            return;
        }
        const resizedImage =  await resizeImage(photoFile)
        const formData = new FormData();
        formData.set('profile.photo', resizedImage ? resizedImage : photoFile)
        
        if(user?.id) {
            updateUserPhoto(user?.id, formData).then((res)=> {
                setUserEditingMode(false); 
                setPhotoFile(null)
                setProfileEditingMode(false);
                reload(res?.data?.id)      
            }).catch(err => {
                console.log(err)
                addAlert({type: 'error', title: 'error', description: 'Upload failed', autoClose: true})
            })
        }
    }

    const onCancelUpload = () => {
        setSelectedFile(null);
        setPhotoFile(null);
        setProfileEditingMode(false);
    }

    const getProfileCombinedImgUrl =()=> {
        if(isOrganization || isUniversityStaff) {
            const logo = organizationProfile?.logo  ? organizationProfile?.logo.startsWith('/media') ? baseAddress + organizationProfile?.logo : organizationProfile?.logo : null;
            return logo ?? 'https://via.placeholder.com/150';
        }
        return currentUser ? getProfileImgUrl(currentUser) : 'https://via.placeholder.com/150';
    }

    const dateFormat = 'DD/MM/YYYY';

    const UserProfile = ()=> (
                    <>
                    <div className={'w-auto flex flex-col items-center md:items-start'}>
                    <div style={{width:170}} className={'mb-6 h-auto rounded-full border-gray-300 border-2 p-2 grow-0 shrink-0'}>
                            <Avatar src={getProfileCombinedImgUrl()} size={150} />
                            {!profileEditingMode && <Button size={'small'} onClick={()=> setProfileEditingMode(true)} className={'mt-2 ml-4'}>Change Photo</Button>}
                        </div>
                        {profileEditingMode && 
                        <>
                            <div style={{maxWidth: 200}}>
                                <FileUploader
                                    onFileSelectSuccess={(file) => setPhotoFile(file)}
                                    onFileSelectError={({ error }) => alert(error)}
                                />
                                {photoFile && <span className={'text-sm text-gray-500 pl-2'}>Selected File:{photoFile?.name ?? 'No Photo Selected'}</span>}
                            </div>
                            <div className={'flex gap-x-2'}>
                                <Button size={'small'} className={'mt-5'} onClick={updateJobSeekerPhoto} type="primary">Update Logo</Button>
                                <Button size={'small'} className={'mt-5'} onClick={onCancelUpload} type="default">Cancel Logo</Button>
                            </div>
                        </>}
                    </div>
                    <div className={'flex-auto'}>
                        {!userEditingMode ?
                            <div className='flex flex-col justify-center items-center md:items-start'>
                                <h1 className='md:pl-[40px] text-[16px] md:text-[20px] font-semibold'>Job Seeker Profile</h1>
                                <div className={'md:pl-[40px] pb-3 flex flex-col md:flex-row gap-3'}>
                                    <Button className={'max-w-[200px] h-7 md:h-auto'}onClick={() => setShowPasswordModal(prev => !prev)} type="default">Update Password</Button>
                                    <Button className={'max-w-[200px] h-7 md:h-auto'} onClick={() => toggleEdit()} type="primary">Edit</Button>
                                </div>
                                <div className='mx-auto'>
                                    <Descriptions
                                    // title="Job Seeker Profile"
                                    // size={'default'}
                                    column={isMobile ? 1: 2}
                                    className={'md:pl-10 max-w-[225px] md:max-w-full'}
                                    size={isMobile ? 'small' : 'default'}
                                >   
                                    <Descriptions.Item contentStyle={contentStyle} label="First Name">{user?.profile?.first_name}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Last Name">{user?.profile?.last_name}</Descriptions.Item>
                                    <Descriptions.Item className={'items-baseline'} contentStyle={contentStyle} label="Email">
                                        <span className={isTablet ? 'max-w-[125px] flex items-center truncate': 'flex items-center truncate'}>
                                            {user?.email} 
                                            {user?.profile?.email_verified &&  <img title={'email verified'} alt="email verified" className={'pl-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px" />}
                                            {/* // <span className={'ml-2 rounded-lg opacity-80 cursor-pointer text-xs text-white bg-fw-orange px-2 py-1'}>Not Verified Click to Verify</span> */}
                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Phone Number">{user?.profile?.phone_number}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} className={isTablet? 'max-w-[125px] truncate': ''} label="Secondary Phone">{user?.profile?.secondary_phone === null ? 'N/A' : user?.profile?.secondary_phone}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Date of Birth">{user?.profile?.date_of_birth ? moment(user?.profile?.date_of_birth).format('DD/MM/YYYY') : 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Gender">{!user?.profile?.gender ? 'N/A' : user?.profile?.gender === 'M' ? 'Male' : 'Female'}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Nationality">{user?.profile?.nationality === null ? 'N/A' : user?.profile?.nationality}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="City">{user?.profile?.city  === null ? 'N/A' : user?.profile?.city }</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Subcity">{user?.profile?.subcity  === null ? 'N/A' : user?.profile?.subcity }</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Woreda">{user?.profile?.woreda  === null ? 'N/A' : user?.profile?.woreda}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="House Number">{user?.profile?.house_no  === null  ? 'N/A' : user?.profile?.house_no }</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Total Years of Experience">{user?.profile?.total_years_of_experience  === null ? 'N/A' : user?.profile?.total_years_of_experience}</Descriptions.Item>
                                    <Descriptions.Item contentStyle={contentStyle} label="Expected Salary">{user?.profile?.expected_salary  === null ? 'N/A' : user?.profile?.expected_salary}</Descriptions.Item>

                                    <Descriptions.Item contentStyle={contentStyle} label="Driver's License">{user?.profile?.has_drivers_license?  <img alt="icon" className={'pl-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px" /> :
                                        'Not Verified'}</Descriptions.Item>

                                    <Descriptions.Item className={'max-w-[125px] truncate'} contentStyle={contentStyle} label="Credentials Verified">
                                        {user?.profile?.credential_verified ?  <img alt="icon" className={'pl-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px" /> :
                                        'Not Verified'}
                                    </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </div>
                            :
                            <Form
                                name="userAccount"
                                onFinish={updateUserProfile}
                                autoComplete="off"
                                className={'w-full grid grid-cols-2 md:grid-cols-2 gap-x-4 md:pl-10'}
                                initialValues={getUserIntialValues()}
                            >
                                <Form.Item
                                    name="first_name"
                                    rules={[{ required: true, message: 'First name is required!' }]}
                                >
                                    <Input placeholder={'First name'} />
                                </Form.Item>

                                <Form.Item
                                    name="last_name"
                                    rules={[{ required: true, message: 'Last name is required!' }]}
                                >
                                    <Input placeholder={'Last name'} />
                                </Form.Item>

                                <Form.Item
                                    name="phone_number"
                                    rules={[{ required: true, message: 'Phone number is required!' }]}
                                >
                                    <Input className={'w-full'} maxLength={10} placeholder={'09********'} />
                                </Form.Item>

                                <Form.Item
                                    name="secondary_phone"
                                    rules={[{ required: false}]}
                                >
                                    <Input className={'w-full'} maxLength={10} placeholder={'09********'} />
                                </Form.Item>

                                <Form.Item
                                    name="gender"
                                    rules={[{ required: true, message: 'Gender is required!' }]}
                                >
                                    <Select placeholder={'Gender'} onChange={() => console.log('')}>
                                        <Option value="M">Male</Option>
                                        <Option value="F">Female</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name={"date_of_birth"}
                                >   
                                    <DatePicker className={'w-full'} format={dateFormat} placeholder={'Date of Birth'} />
                                </Form.Item>

                                <Form.Item
                                    name="nationality"
                                    rules={[{ required: true, message: 'Nationality is required!' }]}
                                >
                                    <Input placeholder={'Nationality'} />
                                </Form.Item>
                                
                                <Form.Item
                                    name="city"
                                    rules={[{ required: true, message: 'City is required!' }]}
                                >
                                    <Input placeholder={'City'} />
                                </Form.Item>

                                <Form.Item
                                    name="subcity"
                                    rules={[{ required: true, message: 'SubCity is required!' }]}
                                >
                                    <Input placeholder={'Subcity'} />
                                </Form.Item>

                                <Form.Item
                                    name="woreda"
                                    rules={[{ required: true, message: 'Woreda is required!' }]}
                                >
                                    <Input placeholder={'Woreda'} />
                                </Form.Item>

                                <Form.Item
                                    name="house_no"
                                    rules={[{ required: true, message: 'House Number is required!' }]}
                                >
                                    <Input placeholder={'House Number'} />
                                </Form.Item>

                                <Form.Item
                                    name="total_years_of_experience"
                                    rules={[{ required: true, message: 'Total number of Experience is required!' }]}
                                >   
                                    <Select
                                        placeholder="Total number of Experience"
                                        // defaultValue="1"
                                        // style={{ width: 120 }}
                                        // onChange={handleChange}
                                        options={[
                                            { value:"", label: "Total number of Experience", disabled: true },
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                            { value: '5', label: '5+' },
                                            { value: '10', label: '10+' },
                                            { value: '15', label: '15+' },
                                        ]}
                                        />
                                </Form.Item>

                                <Form.Item
                                    name="expected_salary"
                                    rules={[{ required: true, message: 'Expected Salary is required!' }]}
                                >
                                    <Input placeholder={'Expected Salary'} />
                                </Form.Item>

                                <Form.Item
                                    name="has_drivers_license"
                                    rules={[{ required: true, message: `Driver's License is required!` }]}
                                >   
                                    <Select
                                        placeholder="Driver License"
                                        // defaultValue=Driver"false"
                                        // style={{ width: 120 }}
                                        // onChange={handleChange}
                                        options={[
                                            { value:"", label: "Driver License", disabled: true },
                                            { value: true as any, label: 'Yes' },
                                            { value: false as any, label: 'No' },
                                        ]}
                                        />
                                </Form.Item>

                                <div className={'col-span-2 col-start-1 md:col-span-1 md:col-start-2 flex items-baseline gap-4 mr-0'}>
                                <Button onClick={() => toggleEdit()} block type="default">
                                    Cancel
                                </Button>
                                <Button block className={'mt-4 md:mt-0'} type="primary" htmlType="submit">
                                    Update
                                </Button>
                                </div>
                            </Form>}
                            {showPasswordModal &&
                                <UpdatePassword 
                                    onCancel={() => setShowPasswordModal(false)}
                                    onSubmit={() => setShowPasswordModal(false)}
                                />
                            }
                    </div>
            </>
    )

    const OrganizationProfile = ()=> (
        <>
        <div className={'w-auto flex flex-col'}>
            <div style={{width:170}} className={'mb-6 h-auto rounded-full border-gray-300 border-2 p-2 grow-0 shrink-0'}>
                <Avatar src={getProfileCombinedImgUrl()} size={150} />
                {!profileEditingMode && <Button size={'small'} onClick={()=> setProfileEditingMode(true)} className={'mt-2'}>Change Photo</Button>}
            </div>
            {profileEditingMode && 
            <>
                <div style={{maxWidth: 200}}>
                    <FileUploader
                        onFileSelectSuccess={(file) => {setSelectedFile(file)}}
                        onFileSelectError={({ error }) => alert(error)}
                    />
                    {selectedFile && <span className={'text-sm text-gray-500'}>Selected File:{selectedFile?.name ?? 'No File Selected'}</span>}
                </div>

                <div className={'flex gap-x-2'}>
                    <Button size={'small'} className={'mt-5'} onClick={updateOrgLogo} type="primary">Update Logo</Button>
                    <Button size={'small'} className={'mt-5'} onClick={onCancelUpload} type="default">cancel Logo</Button>
                </div>
            </>}
        </div>
        <div className={'flex-auto'}>
            {!orgEditingMode ?
                <Descriptions
                    title="Organization Profile"
                    size={'default'}
                    column={1}
                    extra={<Button onClick={() => toggleOrgEdit()} type="primary">Edit</Button>}
                >
                    <Descriptions.Item contentStyle={contentStyle} label="Registered Business Name">{organizationProfile?.name}</Descriptions.Item>
                    {organizationProfile?.description && <Descriptions.Item contentStyle={contentStyle}>{organizationProfile?.description}</Descriptions.Item>}
                    <Descriptions.Item contentStyle={contentStyle} label="Year Established">{organizationProfile?.year_established ? moment(organizationProfile?.year_established).format('YYYY') : 'n/a'}</Descriptions.Item>
                    {organizationProfile?.number_of_employees && <Descriptions.Item contentStyle={contentStyle} label="Number of employees">{organizationProfile?.number_of_employees}</Descriptions.Item>}
                    <Descriptions.Item contentStyle={contentStyle} label="Sector">{organizationProfile?.sector}</Descriptions.Item>
                    <Descriptions.Item contentStyle={contentStyle} label="Location">{organizationProfile?.city}</Descriptions.Item>
                    <Descriptions.Item contentStyle={contentStyle} label="Company Verified">
                        {1+1 === 2 ?  <img alt="icon" className={'pl-1 ml-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px" /> :
                         'Not Verified'}
                    </Descriptions.Item>
                </Descriptions>
                :
                <Form
                    name="organizationAccount"
                    initialValues={getOrgIntialValues()}
                    onFinish={updateOrgProfile}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 md:pl-10'}
                >
                    <Form.Item
                        name="name"
                        className={'col-span-1 md:col-span-2'}
                        rules={[{ required: true, message: 'Business name is required!' }]}
                    >
                        <Input placeholder={'Business Name'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1 md:col-span-2 row-span-2'}
                        name="description"
                    >
                        <TextArea placeholder={'Company description'}  rows={4} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="year_established"
                    >
                        <DatePicker placeholder={'Year of Establishment'} format={'YYYY'} picker={'year'} className={'w-full'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="number_of_employees"
                    >
                        <InputNumber placeholder={'Number of Employees'} className={'w-full'}/>
                    </Form.Item>

                    <Form.Item
                        name="sector"
                        className={'col-span-1'}
                        rules={[{ required: true, message: 'Sector is required!' }]}
                    >
                        <Input placeholder={'Sector'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="city"
                        rules={[{ required: true, message: 'Please input city of headquarters!'}]}
                    >
                        <Select
                            style={{ width: '100%', height: '100%' }}
                            placeholder="Location"
                            listItemHeight={10} listHeight={250} 
                            maxLength={5}
                            allowClear
                            showSearch
                            >
                            {EthiopianCities.map((cityName, i) => {
                                return  <Option key={i} value={cityName}>{cityName}</Option>
                            })} 
                        </Select>
                    </Form.Item>
                    <Button  onClick={() => toggleOrgEdit()} block type="default">
                        Cancel
                    </Button>
                    <Button block className={'mt-4 md:mt-0'} type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form>}
        </div>
    </>
)

    const UniversityProfile = ()=> (
        <>
        <div className={'w-auto flex flex-col'}>
            <div style={{width:170}} className={'mb-6 h-auto rounded-full border-gray-300 border-2 p-2 grow-0 shrink-0 flex flex-col justify-center items-center'}>
                <Avatar src={universityProfile?.logo} size={150} />
                {!profileEditingMode && <Button size={'middle'} onClick={()=> setProfileEditingMode(true)} className={'mt-6 bg-fw-blue-light text-white'}>Update Profile Pic</Button>}
            </div>
            {profileEditingMode && 
            <>
                <div style={{maxWidth: 200}}>
                    <FileUploader
                        onFileSelectSuccess={(file) => {setSelectedFile(file)}}
                        onFileSelectError={({ error }) => alert(error)}
                    />
                    {selectedFile && <span className={'text-sm text-gray-500'}>Selected File:{selectedFile?.name ?? 'No File Selected'}</span>}
                </div>

                <div className={'flex gap-x-2'}>
                    <Button size={'small'} className={'mt-5'} onClick={updateOrgLogo} type="primary">Update Logo</Button>
                    <Button size={'small'} className={'mt-5'} onClick={onCancelUpload} type="default">cancel Logo</Button>
                </div>
            </>}
        </div>
        <div className={'flex-auto'}>
            {!orgEditingMode ?
                <Descriptions
                    title="University Profile"
                    size={'default'}
                    column={1}
                    extra={<Button onClick={() => toggleOrgEdit()} type="primary">Edit</Button>}
                >
                    <Descriptions.Item contentStyle={contentStyle} label="Univeristy Name">{universityProfile?.name}</Descriptions.Item>
                    {universityProfile?.description && <Descriptions.Item ><p className='line-clamp-2' style={{ overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2}}>{universityProfile?.description}</p></Descriptions.Item>}
                    <Descriptions.Item contentStyle={contentStyle} label="Year Established">{universityProfile?.year_established ? moment(universityProfile?.year_established).format('YYYY') : 'n/a'}</Descriptions.Item>
                    {universityProfile?.website && <Descriptions.Item contentStyle={contentStyle} label="Website">{universityProfile?.address}</Descriptions.Item>}
                    {universityProfile?.address && <Descriptions.Item contentStyle={contentStyle} label="Address">{universityProfile?.website}</Descriptions.Item>}
                    <Descriptions.Item contentStyle={contentStyle} label="Location">{universityProfile?.city}</Descriptions.Item>
                    <Descriptions.Item contentStyle={contentStyle} label="University Verified">
                        {universityProfile?.is_verified ?  <img alt="icon" className={'pl-1 ml-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px" /> :
                        'Not Verified'}
                    </Descriptions.Item>
                </Descriptions>
                :
                <Form
                    name="UniversityAccount"
                    initialValues={getUniIntialValues()}
                    onFinish={updateUniProfile}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 md:pl-10'}
                >
                    <Form.Item
                        name="name"
                        className={'col-span-1 md:col-span-2'}
                        rules={[{ required: true, message: 'Univeristy name is required!' }]}
                    >
                        <Input placeholder={'Univeristy Name'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1 md:col-span-2 row-span-2'}
                        name="description"
                    >
                        <TextArea placeholder={'Univeristy description'}  rows={4} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="year_established"
                    >
                        <DatePicker placeholder={'Year of Establishment'} format={'YYYY-MM'} picker={'month'} className={'w-full'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="proofspace_serviceID"
                        label="Proofspace Service ID"
                    >
                        <InputNumber placeholder={'Proofspace Service ID'} className={'w-full'} disabled/>
                    </Form.Item>

                    <Form.Item
                        name="address"
                        className={'col-span-1'}
                        rules={[{ required: false}]}
                    >
                        <Input placeholder={'Address'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1'}
                        name="city"
                        rules={[{ required: true, message: 'Please input city of headquarters!'}]}
                    >
                        <Select
                            style={{ width: '100%', height: '100%' }}
                            placeholder="Location"
                            listItemHeight={10} listHeight={250} 
                            maxLength={5}
                            allowClear
                            showSearch
                            >
                            {EthiopianCities.map((cityName, i) => {
                                return  <Option key={i} value={cityName}>{cityName}</Option>
                            })} 
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="website"
                        className={'col-span-2'}
                        rules={[{ required: false}]}
                    >
                        <Input placeholder={'Website'} />
                    </Form.Item>

                    <Button  onClick={() => toggleOrgEdit()} block type="default">
                        Cancel
                    </Button>
                    <Button block className={'mt-4 md:mt-0'} type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form>}
        </div>
    </>
    )
        
    return (
        <Page>
            <>
            <FWSubHeader title='Account Page' className='flex-col'>
                {(showBackBtn && !isOrganization && !isUniversityStaff) ?
                    <span onClick={() => navigate(-1)} className={'flex ml-4 items-center cursor-pointer'}>
                    <ArrowLeftOutlined className={'mr-2 text-fw-orange font-medium scale-150'} />
                    <p className={'text-xs text-gray-600'}>Back to CV</p>
                </span> : null}
            </FWSubHeader>
            <div className={'bg-white shadow rounded-xl py-4 w-auto max-w-2xl lg:max-w-3xl xl:max-w-4xl'}>
                <div className={'flex flex-col md:flex-row w-full p-4 gap-4'}>
                    {loading ? <PageSpinner /> :
                    isUniversityStaff ? <UniversityProfile />: !isOrganization ? <UserProfile/> : <OrganizationProfile />}
                </div>
            </div>
            </>
        </Page>
    )
}

export default Account;