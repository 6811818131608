import * as React from "react";
import { ImgHTMLAttributes } from "react";
export enum Icons {
  CHECK_GREEN_CIRLCE = "check-green-circle",
  CV_GRAY = "cv-gray",
  CV_ORANGE = "cv-orange",
  INFO_BLUE = "info-blue",
  WARNING_ORANGE = 'warning-orange',
  DANGER_GRAY = 'danger-gray'
}

interface IInputProps extends ImgHTMLAttributes<HTMLImageElement> {
  icon: Icons;
  styleClass?: string;
  className?: string;
  noPointerEvent?: boolean;
}

export default class Icon extends React.PureComponent<IInputProps, any> {
  public static IconType = Icons;
  public render() {
    const { icon, styleClass,noPointerEvent, className, ...rest } = this.props;

    if (className) {
      return (
        <div className={className}>
          <img
            alt="icon"
            draggable={false}
            src={`/icons/${icon}.svg`}
            className={(styleClass ? styleClass : '') + " icon inline"}
            style={noPointerEvent?{pointerEvents: 'none'}:{}}
            {...rest}
          />
        </div>
      );
    } else {
      return (
        <img
          alt="icon"
          draggable={false}
          src={`/icons/${icon}.svg`}
          className={(styleClass ? styleClass : '') + " icon inline"}
          style={noPointerEvent?{pointerEvents: 'none'}:{}}
          {...rest}
        />
      );
    }
  }
}
