import { Button, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getMotivation } from '../../../services/MotivationService'
import { IMotivation } from '../../../types'
import MotivationSetupModal from './MotivationSetupModal'

interface IMotivationModal {
    selectionId: number
}

const MotivationModal:React.FC<IMotivationModal>  = ({selectionId}) => {
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [reload, setReload] = useState(false)
    const [motivationLoading, setMotivationLoading] = useState(false)

    const [motivation, setMotivation] = useState<IMotivation | undefined>(undefined)

    useEffect(() => {
        setMotivationLoading(true)
        getMotivation(selectionId).then((res) => {
            setMotivation(res?.data[0])
        }).catch(ex => console.log(ex)).finally(() => setMotivationLoading(false))
    }, [selectionId, reload])

    const onSubmitMotivation = () => {
        setShowModal(false)
        setReload(prev => !prev)
    }

    const onEditMotivation = () => {
        setShowEditModal(true)
    }

    return(<div className={'pl-2 pt-4'}>
                {motivationLoading ? <Spin /> :
                    motivation ?
                        <div className={'flex flex-col gap-2 rounded-md bg-gray-50 text-gray-600 shadow m-2 p-4'}>
                            <p className={'text-base font-semibold underline'}>{`Motivation Created`}</p>
                            <p className={'font-medium'}>{`Deadline: ${moment(motivation.deadline).format('DD-MM-YYYY')}`}
                                <Button size={'small'} type="link" onClick={onEditMotivation}>Edit</Button>
                            </p>
                            <p className={'font-light'}>Is Released: {motivation.is_released ? 'yes' : 'no' }</p>
                            <p className={'text-xs'}>Note: Motivation questions can't be updated once released.</p>
                        </div>
                :
                <>
                <p>No Motivation Questions have been found!</p>
                <Button className={'mt-2'} onClick={() => setShowModal(prev => !prev)} size={'small'} type={'primary'}>Add Motivation</Button>
                </>}
                {showModal &&
                    <MotivationSetupModal 
                        selectionId={selectionId}
                        onClose={() => setShowModal(false)}
                        onSubmit={onSubmitMotivation}
                    />}
                {showEditModal && motivation?.id && <MotivationSetupModal 
                    selectionId={selectionId}
                    onClose={() => setShowEditModal(false)}
                    onSubmit={onSubmitMotivation}
                    motivation={motivation}
                />}   
            </div>
    )
}

export default MotivationModal