import { Steps, Form, Input, DatePicker, Select, Divider, Button, Empty, Alert } from "antd"
import {PlusOutlined, EditOutlined, DeleteOutlined, EnvironmentOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { addCVExperience, deleteCVExperience, getCVExperience, updateCVExperience } from "../../services/CVService"
import { ICVExperience } from "../../types"
import moment from "moment"
import Checkbox from "antd/lib/checkbox/Checkbox"
import useAlert from "../../components/toast/useAlert"
import PublishBtn from "../../components/cv/PublishBtn";
import { noteToJobSeeker } from "../../Utility";
import Bullet from "../../components/base/Bullet";
import PageSpinner from "../../components/base/PageSpinner";
import FWSubHeader from "../../components/base/FWSubHeader";
import Page from "../../components/base/Page";

const CVEdit2  = ()=> {
    const {Step} = Steps;
    const {cvId} = useAuth();
    const navigate = useNavigate();
    const {addAlert} = useAlert();
    const { Option } = Select;
    const monthFormat = 'YYYY/MM';
    
    const [selectedExperience, setSelectedExperience] = useState<ICVExperience>();
    const [cvExperiences, setCVExperiences] = useState<ICVExperience[]>([])
    const [achievments, setAchievments] = useState<string[]>([])
    // const [notables, setNotables] = useState<string[]>([])
    const [newAchievement, setNewAchievement] = useState<string>('')
    // const [newNotable, setNewNotable] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isPresent, setIsPresent] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [experienceForm] = Form.useForm();

    useEffect(()=> {       
        if(cvId) {
            setLoading(true);
            getCVExperience(cvId)
            .then(res => {
                const profiles:ICVExperience[] = res?.data;
                setCVExperiences(profiles);
            })
            .finally(()=> setLoading(false))
        }
    }, [cvId, reload])

    const onNewAchievmentChange = (event:any) => {
        setNewAchievement(event.target.value)
    };
    
    const addAchievement = () => {
        setAchievments(prev => [...prev, newAchievement])
        setNewAchievement('')
    };

    // const onNewNotableChange = (event:any) => {
    //     setNewNotable(event.target.value)
    // };
    
    // const addNotable= () => {
    //     setNotables(prev => [...prev, newNotable])
    //     setNewNotable('')
    // };

    const onSubmitExperience = (values:ICVExperience)=> {
        if(cvId) {
            setLoading(true)
            const formattedStartDate =  moment(values?.start_date).format('YYYY-MM-DD');
            const formattedEndDate = (values.end_date && !isPresent) ? moment(values?.end_date).format('YYYY-MM-DD') : undefined;
            const data= {...values, start_date: formattedStartDate, end_date: formattedEndDate};
            const api = (!isCreating && selectedExperience?.id) ? updateCVExperience(cvId, selectedExperience?.id, data) : addCVExperience(cvId,data );
                        
            api
            .then(res => {
                addAlert({type: 'success', title: 'success', description: `${isCreating ? 'added' : 'updated'} successfully`, autoClose: true})
                setSelectedExperience(undefined);
                setAchievments([]);
                // setNotables([]);
                setNewAchievement('')
                // setNewNotable('');
                setReload(prev => !prev);
                if(isCreating) {
                    setIsCreating(false);
                }
                experienceForm.resetFields();
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> {
                setLoading(false)
            })
        }
    }

    const onEditClicked = (id?:number)=> {
        if(id) {
            setIsCreating(false)
            const selected = cvExperiences.find(exp => exp.id === id);
            if(selected) {
                const {start_date, end_date, ...rest} = selected;
                if(!end_date){setIsPresent(true)}
                const selectedExp = {...rest, start_date: moment(start_date), end_date: end_date ? moment(end_date) : undefined}
                setSelectedExperience(selectedExp)
                if(selectedExp?.achievements?.length >0) {
                    setAchievments(selectedExp?.achievements)
                }
                // if(selectedExp?.notable_achievements?.length >0) {
                //     setNotables(selectedExp?.notable_achievements)
                // }
            }
        }
    }

    const onCancelEdit = ()=> {
        setSelectedExperience(undefined);
        if(isCreating) {
            setIsCreating(false)
        }
        experienceForm.resetFields();
    }

    const onDeleteClicked = (id?:number)=> {
        if(id) {
            deleteCVExperience(cvId,id)
            .then(res => {
                addAlert({type: 'success', title: 'success', description: 'Deleted succesfully', autoClose: true})
                setReload(prev => !prev);
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> {
                setLoading(false)
                setSelectedExperience(undefined)
            })
        }
    }

    const onNextClick = ()=> {
        navigate(`/dashboard/cv/edit/3/${cvId}`)
    }

    const onAddNewExperience = () => {
        setSelectedExperience(undefined);
        setIsCreating(true)
    }

    useEffect(() => {
        experienceForm.setFieldsValue(!isCreating ? selectedExperience ? selectedExperience : [] : [])
    }, [experienceForm, isCreating, selectedExperience])

    const onChangeStep = (newStep: number) => {
        if(isCreating) {
            return
        }
        if(newStep === 0) {
            navigate(`/dashboard/cv/edit/1`)
        }
        if(newStep === 2) {
            navigate(`/dashboard/cv/edit/3/${cvId}`)
        }
        if(newStep === 3) {
            navigate(`/dashboard/cv/edit/4/${cvId}`)
        }
        if(newStep === 4) {
            navigate(`/dashboard/cv/edit/5/${cvId}`)
        }
    }

    return(
        <Page>
        <FWSubHeader title={'Edit Curriculum Vitae _ Work Experience'} children={(
                <PublishBtn loading={loading} disabled={loading || !!selectedExperience || isCreating} />
            )} />
        <Steps responsive className={'px-4 mt-4 sm:mb-4 w-full max-w-3xl xl:max-w-4xl'} onChange={onChangeStep} current={1} size={'small'}>
            <Step title="Personal Info" />
            <Step title="Experience"  />
            <Step title="Education" />
            <Step title="Achievements" />
            <Step title="Certifications" />
        </Steps>
            <div className={'bg-white p-4 shadow rounded-md md:rounded-xl py-4 w-auto max-w-3xl xl:max-w-4xl'}>
            <div className="w-full h-2 flex justify-end -mb-2"><CloseOutlined onClick={() => navigate('/dashboard/cv', {state: {isFromCv:true}})} className="hover:text-fw-orange cursor-pointer"/></div>
                <div className={'flex flex-col w-full mt-6'}>
            {loading ? <PageSpinner /> :
            <>
            {(selectedExperience || isCreating) && <Form
                name="addExperience"
                onFinish={onSubmitExperience}
                autoComplete="off"
                className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-5'}
                form={experienceForm}
            >   
                    <Form.Item
                        className={'col-span-1 md:col-span-3'}
                        name="title"
                        rules={[{ required: true, message: 'Your Job title is required!' }]}
                    >
                        <Input placeholder={'Position or Title'} />
                    </Form.Item>

                    <Form.Item
                        name={"start_date"}
                        rules={[{ required: true, message: 'Start date is required!'}]}
                    >
                        <DatePicker className={'w-full'} format={monthFormat} placeholder={'start date'} />
                    </Form.Item>

                    <Form.Item
                        name={"end_date"}
                    >   
                        <DatePicker className={'w-full'} disabled={isPresent} format={monthFormat} placeholder={'end date'} />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox checked={isPresent} onChange={e => setIsPresent(e.target.checked)}>Up to Present</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="company_name"
                        rules={[{ required: true, message: 'Company is required!'}]}
                    >
                        <Input placeholder={'Company name'} />
                    </Form.Item>
                    <Form.Item
                        name="company_city"
                        rules={[{ required: true, message: 'Company city is required!'}]}
                    >
                        <Input placeholder={'Company city'} />
                    </Form.Item>
                    <Form.Item
                        name="company_country"
                        rules={[{ required: true, message: 'Company country is required!'}]}
                    >
                        <Input placeholder={'Company country'} />
                    </Form.Item>
                    <Form.Item
                        className={'col-span-1 md:col-span-3'}
                        name="company_website"
                    >
                        <Input placeholder={'Company website or social media profile'} />
                    </Form.Item>
                
                <Form.Item 
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        name="achievements"
                        rules={[{ required: true, message: 'Please add as skill here'},
                                { type: 'array', min:2, message: 'Please add at least 4 impact statements'},
                                { type: 'array', max:10, message: "These can't be more than 10"}
                             ]}
                    >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Add Impact and results here"
                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={newAchievement} onChange={onNewAchievmentChange} />
                                <span
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={addAchievement}
                                >
                                <PlusOutlined /> Add an Impact
                            </span>
                            </div>
                        </div>
                        )}
                    >
                        {achievments.map((item,i) => (
                            <Option key={i} value={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Hide for a while */}
                {/* <Form.Item 
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        name="notable_achievements"
                        rules={[{ required: true, message: 'Please add as skill here'},
                                { type: 'array', min:2, message: 'Please add at least 2 notable achievements'},
                                { type: 'array', max:10, message: "These can't be more than 10"}
                             ]}
                    >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Add notable achievements here"
                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={newNotable} onChange={onNewNotableChange} />
                                <span
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={addNotable}
                                >
                                <PlusOutlined /> Add a notable achievment
                            </span>
                            </div>
                        </div>
                        )}
                    >
                        {notables.map((item,i) => (
                            <Option key={i} value={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    rules={[{ required: true, message: 'Reference name is required'}]}
                    name="reference_name"
                >
                    <Input placeholder={'Refrence name'} />
                </Form.Item>

                <Form.Item
                    rules={[{ required: true, message: 'Reference email is required'},
                        {type: 'email', message: 'Please enter a valid email'}
                    ]}
                    name="reference_email"
                >
                    <Input type={'email'} placeholder={'Reference email address'} />
                </Form.Item>

                <Form.Item
                    name="reference_phone"
                >
                    <Input placeholder={'Reference Phone number'} />
                </Form.Item>
                <div className={'flex items-center gap-x-3'}>
                    <Button loading={loading} disabled={loading} size={'small'}  block type={'primary'} htmlType="submit">{isCreating ? 'Add Experience' : 'Update'}</Button>              
                    <Button onClick={onCancelEdit} size={'small'}  block type={'default'}>Cancel</Button>  
                </div>            
            </Form>}

            {/* List of Experiences */}
            {cvExperiences?.length === 0 && !isCreating && <Empty className={'mb-4'} description={'No items found, Click add button to input your work 🧳 experience...(is Optional if you are a fresh graduate)' } />}
            {(!selectedExperience && !isCreating) && <Button onClick={onAddNewExperience} className={'w-max'} loading={loading} disabled={loading} size={'small'} type={'primary'} ghost>Add new Experience</Button>}
            {cvExperiences?.length > 0 ? cvExperiences.filter(exp => exp.id !== selectedExperience?.id).map(experience => 
                (<div className={'mt-4'}>
                    <p className={'text-base text-gray-900 font-semibold pb-1 flex items-center'}>{experience.title} <span> 
                        <EditOutlined onClick={() => onEditClicked(experience?.id)} className={'ml-2 mb-1 text-fw-orange'} />
                        <DeleteOutlined onClick={() => onDeleteClicked(experience?.id)} className={'ml-2 mb-1 text-red-700'} />
                        </span> </p>
                        <div className={'flex justify-between flex-wrap mb-2 text-gray-500'}>
                            <p className={'text-sm'}><b>{experience.company_name}</b> | {moment(experience?.start_date)?.format('MMM YYYY') ?? ''} - {experience?.end_date ? moment(experience?.end_date)?.format('MMM YYYY') : 'Present'}</p>
                            <span className={'inline-flex items-center'} >
                                <EnvironmentOutlined className={'mr-2 font-thin'} /> {`${experience?.company_city ?? ''} ${experience?.company_country ?? ''}`}
                            </span>
                        </div>
                        <div className={'flex justify-between flex-wrap mb-2 text-gray-500'}>
                            <p className={'text-xs'}><b>{experience?.reference_name}</b> | {experience?.reference_email} (Reference)</p>
                            <p className={'text-xs'}>{experience?.reference_phone}</p>
                        </div>
                    {experience.achievements?.map((duty:string,j:number) => <Bullet key={j} text={duty} /> )}
                </div>)
            ) : null}
            
            <Alert className={'col-span-1 md:col-span-3 mt-4 mb-1 text-xs'} message={noteToJobSeeker} type={'warning'} showIcon />
            
            <div className={'w-full flex justify-end'}>
                <div className={'mt-4 flex flex-col md:flex-row gap-4 w-full md:w-1/2 justify-items-end'}>
                    <Button onClick={() => navigate(-1)} block type="default">Previous</Button>
                    <Button loading={loading} disabled={loading|| !!selectedExperience || isCreating}  block type="primary" onClick={onNextClick}>{'Save and Proceed'}</Button>
                </div>
            </div>
            </>}
            </div>
        </div>
     </Page>
    )
}

export default CVEdit2;