import React, { FC } from 'react'
import Modal from "../../components/base/Modal";
import SignUp from './SignUp';

interface ISignUpModal {
    onLoginSubmit: () => void
    onClose: () => void
    fromModal: boolean
    onLogin: () => void
    onBusiness?: () => void
}

const SignUpModal:FC<ISignUpModal> = ({fromModal, onLoginSubmit, onClose, onLogin, onBusiness}) => {
  return (
    <Modal 
    className='w-[400px] -ml-3 sm:!m-auto'
    title="Register"  
    subTitle = 'please login or register to continue' 
    onCancel={onClose} 
    onSubmit={onLoginSubmit}
    hideActionButtons={true}>
    <SignUp fromModal={fromModal} onLogin={onLogin} onBusiness={onBusiness}/>
</Modal>
  )
}

export default SignUpModal