import React, { useState } from 'react'
import Modal from '../../components/base/Modal'
import PieChartFW from '../../components/selection/PieChartFW'
import SelectionFilter from '../../components/selection/Filter/SelectionFilter'
import ExamComplete from './exam/ExamComplete'

const ComponentsDemo:React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>()
    const [showSelectionModal, setSelectionModal] = useState<boolean>(false)
    const [, setShowIntialFilterModal] = useState<boolean>(false)
    const [showExamWagon, setShowExamWagon] = useState<boolean>(false)
    const [, setShowMotivationModal] = useState<boolean>(false)
    const [, setScreeningModal] = useState<boolean>(false)

    const KeypointPieData = () => [
        {
          name: 'open',
          value: 10,
        },
        {
          name: 'done',
          value: 90,
        },
      ]

    return (<div className={'text-gray-800 p-6'}>
        <h1>Selection Module Components</h1>
        <h4>Stats</h4>
        {/* <SelectionStats 
           logoSrc={'https://logos-world.net/wp-content/uploads/2020/04/Huawei-Logo-2018-present.jpg'}
           currentApplicants={350} 
           eligibleApplicants={20} 
           totalApplicants={0} 
           isOpen={false}            
        /> */}
        <button onClick={() => setShowModal(prev => !prev)}>Toggle Modal</button>
        <PieChartFW data={KeypointPieData()} />
        <h4>Wagon</h4>
            {showModal && <Modal title={'test modal'} subTitle={'Some descriptive subtitlte here'}  onSubmit={() => console.log('')} onCancel={() => setShowModal(false)} />}
        <h4>Initial Filtering Modal</h4>
            {showModal && <Modal title={'test modal'} subTitle={'Some descriptive subtitlte here'}  onSubmit={() => console.log('')} onCancel={() => setShowModal(false)} />}
        <h4>Filter</h4>
        <button onClick={() => setSelectionModal(prev => !prev)}>Toggle Selection Modal</button>
        {showSelectionModal && <SelectionFilter onSubmit={() => setSelectionModal(false)} onClose={() => setSelectionModal(false)} />}
        <button onClick={() => setShowIntialFilterModal(prev => !prev)}>Toggle Initial Filter Modal</button>
        <button onClick={() => setShowExamWagon(prev => !prev)}>Toggle Exam Modal</button>
        <button onClick={() => setShowMotivationModal(prev => !prev)}>Toggle Motivation Modal</button>
        <button onClick={() => setScreeningModal(prev => !prev)}>Toggle Screening Modal</button>
        {/* {showExamWagon && <ExamWagon onClose={() => setShowExamWagon(false)} /> } */}
        {showExamWagon && 
        <ExamComplete selectionId={2} 
          onClose={() => setShowExamWagon(false)} 
          onSubmit={() => console.log('')} />
        }
        {/* {showMotivationModal && <MotivationModal onClose={() => setShowMotivationModal(false)} /> } */}
        {/* {showMotivationModal && <MotivationEvaluationModal onClose={() => setShowMotivationModal(false)} onSubmit={function (): void {
        throw new Error('Function not implemented.')
      } } /> } */}

      {/* {showMotivationModal && <MotivationComplete onClose={() => setShowMotivationModal(false)} onSubmit={function (): void {
        throw new Error('Function not implemented.')
      } } /> } */}

      {/* {showScreeningModal && <ScreeningWagon onClose={() => setScreeningModal(false)} onSubmit={function (): void {
        throw new Error('Function not implemented.')
      } } />} */}

      {/* {showScreeningModal && <ScreeningEvaluation candidateCVIds={[6,17]} onClose={function (): void {
        throw new Error('Function not implemented.')
      } } onSubmit={function (): void {
        throw new Error('Function not implemented.')
      } } />} */}

        <div className={'my-4 flex gap-12'}>  
        </div>
    </div> )
}

export default ComponentsDemo