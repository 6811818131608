import { useNavigate } from "react-router-dom"
import Chip from "./Chip"

const FWHeroJob = ({job,}: any) => {
  const navigate = useNavigate()
  const GotoJob = (id: number, e:any) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('go to job ', navigate, id);
    window.location.pathname = `/dashboard/jobs/${id}`
  }

  return (
    <div className="w-full h-36 border-solid border border-[#ECEDF2] rounded-lg hover:shadow-lg flex items-center gap-5 p-5 cursor-pointer" onClick={(e) => GotoJob(job.id, e)}>
        <img src={job.business?.logo || '/fw-icon-white.png'} alt="logo" className="w-14 h-14 rounded-lg"/>
        <div>
            <div className="font-semibold text-lg text-gray-600">{job.title}</div>
            <div className="flex gap-2 text-gray-400">
              <div className="flex flex-col gap-x-2 lg:flex-row">
                  <div className="flex items-center gap-1"> <img src="/icons8-time-50.png" alt="icon" className="h-4 w-4"/><div className="max-w-[80px] md:lg:max-w-[100px] text-sm md:text-md text-ellipsis whitespace-nowrap overflow-hidden">{job.deadline}</div></div>
                  <div className="flex items-center gap-1"> <img src="/icons8-briefcase-50.png" alt="icon" className="h-4 w-4"/><div className="max-w-[80px] md:lg:max-w-[100px] text-sm md:text-md text-ellipsis whitespace-nowrap overflow-hidden"> {job.sub_industry || "General" }</div></div>
              </div>
              <div className="flex flex-col gap-x-1 lg:flex-row">
                  <div className="flex items-center gap-1"> <img src="/icons8-location-50.png" alt="icon" className="h-4 w-4"/><div className="max-w-[80px] md:lg:max-w-[100px] text-sm md:text-md text-ellipsis whitespace-nowrap overflow-hidden"> {job.location[0]}</div> </div>
                  <div className="flex items-center gap-1"> <img src="/icons8-euro-money-50.png" alt="icon" className="h-4 w-4"/><div className="max-w-[80px] md:lg:max-w-[100px] text-sm md:text-md text-ellipsis whitespace-nowrap overflow-hidden"> {job.salary || 'Company Scale'}</div></div>
              </div>
            </div>
            <div className="flex gap-3 mt-2">
              <Chip val={job.length_of_employment === 0 ? 'Full Time' : 'Contractual'} className="bg-green-100 text-green-500 px-4 py-1"/>
              <Chip val={job.business?.name || 'No name'} className="bg-yellow-100 text-yellow-500 px-4 py-1"/>
            </div>
        </div>
    </div>
  )
}

export default FWHeroJob