import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import KeyOutlined from "@ant-design/icons/lib/icons/KeyOutlined";
import { Form, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getBusinessInvitation, acceptBusinessInvitation } from "../../services/UserService";
import useAlert from "../toast/useAlert";

const SignUpBusiness = () => {

    const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
    const {addAlert} = useAlert();
    const navigate = useNavigate();
    const {token} = useParams()
    const [email, setEmail] = useState<string>('')
    const [companyName, setCompanyName] = useState<string>('')

    useEffect(() => {
        if(token) {
            getBusinessInvitation(token).then((res: any) => {
                if(res?.data?.accepted) {
                    addAlert({type: 'error', title: 'error', description: 'Invitation is already accepted. Please sign In', autoClose:false})
                    navigate('/')
                    return;
                }
                setEmail(res.data.email)
                setCompanyName(res.data?.business.name)
            }).catch((ex) => {
                console.log('ex', ex)
                addAlert({type: 'error', title: 'error', description: 'Invitation code is invalid/expired. Please try again or request a new one.', autoClose:false})
            })
        }
    }, [addAlert, navigate, token])

    const onSignUp =(values: any) => {
        if(values && values.invitationCode && values.password) {
            const {invitationCode, password} = values
            setSignUpLoading(true);
            acceptBusinessInvitation({invitation_code: invitationCode, password: password})
            .then((response) => {
                if(response?.status === 201) {
                    console.log('sign up successfull')
                    addAlert({type: 'success', title: 'success', description: 'Invitation Successful. Please login again!', autoClose:true})
                }
            }, (error) => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(error?.response?.data), autoClose:false})
            }).catch(err => console.log(err))
            .finally(() => {
                setSignUpLoading(false);
            })
        }
    }

    const onLoginClick = () => {
        navigate('/')
    }

    return(
    <div className={'flex flex-col items-center'}>  
        <Form
            name="signUp"
            onFinish={onSignUp}
            autoComplete="off"
            className={'w-full'}
        >
             <p className={'text-xs font-medium text-fw-blue mt-4'}>Accept email invite</p>
             <p className={'text-xs text-gray-400 mb-2'}>Please set password below</p>
             {email && companyName && 
                <>
                    <Form.Item className={'mb-3 mt-2'}>   
                        <Input value={email} disabled={true} placeholder={'invitation code'} prefix={<KeyOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
                    <Form.Item className={'mb-3 mt-2'}>   
                        <Input value={companyName} disabled={true} placeholder={'invitation code'} prefix={<KeyOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
                </>}

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!'},
                ({getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.length >= 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password should be at least 8 characters!'));
                    },
                }),
                ({getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || isNaN(Number(value))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Password can't be all numeric!"));
                    },
                }),
                ]}
            >
                <Input.Password placeholder={'password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
            </Form.Item>

            <Button loading={signUpLoading} disabled={signUpLoading} block type="primary" htmlType="submit">Confirm Invitation</Button>

            <div className={'w-full flex flex-row items-center mt-1 justify-center'}>
                <p className={'text-xs mt-1 text-gray-400'}>Already registered?</p>
                <p className={'pt-1 text-xs pl-2 text-fw-orange font-medium cursor-pointer'} onClick={onLoginClick}>Log In</p>
            </div>
        </Form>
    </div>
    );
}

export default SignUpBusiness;