import { createContext, useContext, useEffect, useState } from "react";
import { LogInUser, TelegramSuccessResponse } from "../services/AuthService";
import { getUser } from "../services/UserService";
import { IUser } from "../types";
import { isEmpty } from "../Utils";

export interface ILoginDetails {
  email: string, 
  password: string, 
  onSuccess:(e?:any)=>void, 
  onError:(e:any)=>void
} 

export interface ITGLoginDetails {
  body: TelegramSuccessResponse 
  onSuccess:(e?:any)=>void, 
  onError:(e:any)=>void
} 

export interface IAuthContext {
  logIn: (e:ILoginDetails) => void;
  logInWithTelegram: (e:ITGLoginDetails) => void;
  logOut: (e?:()=>void)=> void;
  isLoggedIn: () => boolean;
  currentUser: IUser | null;
  cvId: number;
  reload: (e:number) => void;
}


const AuthContext =  createContext<IAuthContext>({} as IAuthContext);

const AuthContextProvider = (props:any)=> {
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [cvId, setCVId] = useState<number>(0);
    
    useEffect(()=> {
      if(!currentUser) {
        const storedUser = localStorage.getItem('fw-currentUser');
        const storedCVId = localStorage.getItem('fw-cvId');
        if(storedUser !== null) {
          const parsedUser = JSON.parse(storedUser);
          setCurrentUser(parsedUser);
        }
        if(storedCVId !== null) {
          setCVId(+storedCVId)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isLoggedIn = () => {
      if(localStorage.getItem('fw-token')) {
        return true;
      }
      return false;
    }

    const logIn =({email, password, onSuccess, onError }:ILoginDetails)=> {
      if(email && password) {
          LogInUser({email, password})
          .then((res) => {
              if(res?.status === 200) {
                  const {access, refresh} = res?.data;
                  storeToken(access, refresh)
                  setCurrentUser(res?.data?.user);
                  localStorage.setItem('fw-currentUser', JSON.stringify(res?.data?.user))   
                  if(res?.data?.cv?.cv) {
                    localStorage.setItem('fw-cvId', res?.data?.cv?.cv);
                    setCVId(res?.data?.cv?.cv)   
                  }
                  onSuccess()
              }
          }).catch(error => {
              if(error.response.status !== 401) {
                console.log(error.response.data) 
                onError(error.response.data);
              }
          })
      } else {
        const err = new Error('email or password is missing');
        onError(err);
      }
  }

  // const parseReadableStreamToJson = async (data: any) => {
  //   const dataParsed = (await data.getReader().read()).value
  //   const str = String.fromCharCode.apply(String, dataParsed);
  //   return JSON.parse(str);
  // }

  const logInWithTelegram =({body, onSuccess, onError }:ITGLoginDetails)=> {
    if(!isEmpty(body)) {
      const {id, first_name, last_name, auth_date, photo_url, hash, username} = body;
      const dynamic = `https://dev.fairway.work/auth/complete/telegram/?id=${id}${first_name ?`&first_name=${first_name}` : ''}${last_name ?`&last_name=${last_name}` : ''}&auth_date=${auth_date}${photo_url ?`&photo_url=${photo_url}` : ''}&hash=${hash}${username ?`&username=${username}` : ''}`

      fetch(dynamic)
      .then((response) => {
        return response.json();
      }).then(function(data) {
        const {access, refresh} = data
        storeToken(access, refresh)
        setCurrentUser(data?.user);
        localStorage.setItem('fw-currentUser', JSON.stringify(data?.user))   
        if(data?.cv?.cv) {
          localStorage.setItem('fw-cvId', data?.cv?.cv);
          setCVId(data?.cv?.cv)   
        }
        onSuccess()
      });
    } else {
      const err = new Error('email or password is missing');
      onError(err);
    }
}

    const logOut = (onLogout?:()=>void)=> {
        localStorage.removeItem('fw-token');
        localStorage.removeItem('fw-refreshToken');
        localStorage.removeItem('resetCode');
        localStorage.removeItem('fw-currentUser')
        localStorage.removeItem('fw-cvId')
        window.location.replace('/');
        onLogout?.();
    }

    const reload =(id:number)=> {
      if(id) {
          getUser(id).then(res =>{
              setCurrentUser(res?.data)
              localStorage.setItem('fw-currentUser', JSON.stringify(res?.data))
          })
      }
    }

    const context:IAuthContext = {
      logIn,
      logInWithTelegram,
      logOut,
      isLoggedIn,
      currentUser,
      cvId,
      reload
    }

    return <AuthContext.Provider {...props} value={context}  />
}

const storeToken = (access:string, refresh?: string)=> {
    localStorage.setItem('fw-token', access);
    if(refresh) {
      localStorage.setItem('fw-refreshToken', refresh);
    }
}

const useAuth = () => {
  const customHook: IAuthContext = useContext(AuthContext);
  return customHook;
};

export {useAuth, AuthContextProvider, storeToken};