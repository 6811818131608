import { Progress } from 'antd'
import React, { useMemo } from 'react'

interface IVettedChart {
    selected: number
    total: number
    newWagon?: string
}   

const VettedChart:React.FC<IVettedChart> = ({selected, total, newWagon}) => {

    const getPercent = useMemo(() => {
        if(total === 0) {
            return 100
        }
        return Math.floor((selected/total) * 100)
    }, [selected, total])

    return(<div className='w-full flex justify-center items-center'>
            <div className={'flex flex-col items-center'}>
                <Progress strokeColor={'#FDB82C'} type={'circle'} size={'default'} percent={getPercent} width={180}  />
                <p className={'text-fw-blue-light pt-2 text-center font-bold'}>
                    <span className={'pr-2 text-3xl text-fw-orange'}>{selected}</span>
                     <span className={'pr-2 text-lg'}>matching Candidates</span>
                </p>
                <p className={'text-gray-400 text-lg pt-1 text-center font-bold'}>Total Candidates on this step: {total}</p>

                {newWagon && <div className={'w-full bg-fw-orange text-white text-center text-sm mt-3 p-2 whitespace-nowrap'}>
                    <b>{selected}</b> will be moved to <b>{newWagon}</b>
                </div>}
            </div>
        </div>)
}

export default VettedChart