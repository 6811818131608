import { classNames } from "../../Utils";


interface IFWBadge {
    text: string;
    icon?: JSX.Element;
    background?: string;
    className?: string;
    textClass?:string;
}

const FWBadge = ({text, icon, background, className='', textClass=''}:IFWBadge)=> {

    const styleClass = {
        root: classNames(
            'flex', 
            'justify-between', 
            'items-center',
            'rounded-lg', 
            textClass ? textClass : 'text-gray-500', 
            'font-normal', 
            'capitalize', 
            'px-4',
            'py-1', 
            'w-auto',
            'fw_badge',
            className
        )
    }
    
    return (<div style={{background}} className={styleClass.root}>
                {icon}
                <p className={'w-full text-center'}>{text}</p> 
            </div>)
}

export default FWBadge;