import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AnimatedImages from './AnimatedImages';

const AnimatedCorners = () => {
  const [showCorners, setShowCorners] = useState(false);

  useEffect(() => {
    setShowCorners(true);
  }, []);

  const cornerOne = (
    <div className={'flex gap-2 w-full truncate'}>
        <img height="40px" width="auto" src="https://jobzilla.wprdx.com/demo2/wp-content/uploads/2022/08/icon-2-2.png" alt="" />
        <div className={'flex flex-col'}>
            <p className={'text-xl font-bold text-white'}>40+</p>
            <p className={'text-sm text-white'}>Jobs Posted</p>
        </div> 
    </div>
  )

  const cornerTwo = (
    <div className={'flex gap-2 w-full truncate'}>
        <img height="40px" width="auto" src="https://jobzilla.wprdx.com/demo2/wp-content/uploads/2022/08/icon-1-2.png" alt="" />
        <div className={'flex flex-col'}>
            <p className={'text-xl font-bold text-white'}>50+</p>
            <p className={'text-sm text-white'}>Employers Registered</p>
        </div> 
    </div>
  )

  const cornerThree = (
    <div className={'flex gap-2 w-full truncate'}>
        <img height="40px" width="auto" src="https://jobzilla.wprdx.com/demo2/wp-content/uploads/2022/07/icon3.png" alt="" />
        <div className={'flex flex-col'}>
            <p className={'text-xl font-bold text-black'}>5K+</p>
            <p className={'text-sm text-black'}>Telegram Users</p>
        </div> 
    </div>
  )

  const cornerFour = (
    <div className={'flex gap-2 w-full truncate'}>
        <img height="40px" width="auto" src="https://jobzilla.wprdx.com/demo2/wp-content/uploads/2022/07/icon3.png" alt="" />
        <div className={'flex flex-col'}>
            <p className={'text-xl font-bold text-white'}>Telegram Bot</p>
            <p className={'text-sm text-white'}>Fairway work bot</p>
        </div> 
    </div>
  )

  const corners = [
    { id: 1, className: 'top-left bg-fw-green', text: cornerOne },
    { id: 2, className: 'bottom-right bg-fw-blue-light', text: cornerTwo },
    { id: 3, className: 'top-right bg-fw-grey', text: cornerThree },
    { id: 4, className: 'bottom-left bg-fw-orange', text: cornerFour },
  ];

  

  return (
    <div className={'w-[600px] h-[550px] relative'}>
        <AnimatedImages />
      <TransitionGroup component={null}>
        {showCorners &&
          corners.map(({ id, className, text }) => (
            <CSSTransition key={id} timeout={500} classNames="corner-transition"
                // Delay each corner's animation based on its index
              style={{ transitionDelay: `${id * 750}ms` }}
            >
              <div className={`rounded-md shadow-lg w-min h-auto p-2 absolute ${className}`}>{text}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </div>
  );
};

export default AnimatedCorners;

