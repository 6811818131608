import { AlertContainer } from './components/toast/AlertContainer';
import AlertContextProvider from './components/toast/AlertContext';
import { AuthContextProvider } from './context/AuthContext';
import Router from './routes/Router';
import { firebaseConfig } from './firebase'
import { useCallback, useEffect } from 'react';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from "firebase/performance";
import { initializeApp } from 'firebase/app';
import { Provider, ErrorBoundary } from '@rollbar/react'; 
import { MenuContextProvider } from './context/MenuContext';
import InternetDetector from './components/base/InternetDetector';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  
  const initializeFirebase = useCallback(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    getAnalytics(firebaseApp);
    getPerformance(firebaseApp);
  }, [])

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    initializeFirebase()
  }, [initializeFirebase])

  const {POST_CLIENT_ITEM_ACCESS_TOKEN, NODE_ENV} = process.env;

  const rollbarConfig = {
    accessToken: POST_CLIENT_ITEM_ACCESS_TOKEN,
    environment: NODE_ENV === 'development' ? 'development' : 'production',
  };
  

  return (
    <InternetDetector>
      <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <AuthContextProvider>
          <AlertContextProvider>
            <AlertContainer />
            <MenuContextProvider>
            <Router />
            </MenuContextProvider>
          </AlertContextProvider>
        </AuthContextProvider>
      </ErrorBoundary>
      </Provider>
    </InternetDetector>
  );
}

export default App;
