import ResetPassword from "../ResetPassword"

const ResetPassPage = () => {

  return (
    <div className="w-screen h-screen flex justify-center items-center">
        <div className="flex items-center justify-center h-[550px]">
            <div className="hidden md:block bg-fw-blue-light p-10 rounded-l-xl shadow-lg text-white text-center h-full w-[400px]">
                <img src="./undraw_Educator_1.png" className="w-[320px] h-auto" alt="educator" />
                <div className="text-4xl font-bold max-w-xs pt-8">University/ Institution Platform</div>
                <div className="text-lg px-4 pt-6"> Manage all your students credentials here</div>
            </div>
            <div className="flex-grow-1 min-w-[300px] p-10 rounded-r-xl shadow-lg h-full w-[330px] md:w-[400px]">
                <div className="flex gap-4 justify-center items-center -mb-8">
                    <div className="flex flex-col justify-center items-center mt-[30px]">
                        <img className="h-[70px] w-[70px] z-10" src="./logo_only (2).png" alt="logo" />
                        <img className="h-[100px] -mt-[55px] z-5" src="./fairway_text_only.png" alt="logo" />
                    </div>
                    
                    <img src="./Group (2).png" className="mr-2" alt="anchor" />
                    <img className="h-[70px]" src="./Gov(2).png" alt="institutionn" />
                </div><ResetPassword /></div>
        </div>
    </div>
    
  )
}

export default ResetPassPage