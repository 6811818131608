import { FC, useState } from 'react'
import Modal from "../../components/base/Modal";
import BusinessSignup from './BusinessSignup';

interface IBusinessSignupModal {
    onLoginSubmit: () => void
    onClose: () => void
}

const BusinessSignupModal:FC<IBusinessSignupModal> = ({onLoginSubmit, onClose}) => {
    const [subTitle, setSubTitle] = useState('Please fill in the information below to register as a Business')

    const onDone = () => {
        setSubTitle('Succesfully received your information')
    }
    return(
        <Modal
            className='w-[400px] -ml-3 sm:!m-auto'
            title="Business Information Form"  
            subTitle ={subTitle}
            onCancel={onClose} 
            onSubmit={onLoginSubmit}
            hideActionButtons={true}>
            <BusinessSignup onDone={onDone}/>

            {/* <Login onSignup={onSignup} redirectTo={redirectTo} /> */}
        </Modal>
    )
}

export default BusinessSignupModal