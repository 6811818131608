import React, { FC } from 'react'
import Modal from "../base/Modal";
import ResetPassword from './ResetPassword';



interface IForgetPasswordModal {
    onSubmit: () => void
    onClose: () => void
    onLogin: () => void
}

const ForgetPasswordModal:FC<IForgetPasswordModal> = ({ onSubmit, onClose, onLogin}) => {
  return (
    <Modal 
    className='w-[400px] -ml-3 sm:!m-auto'
    title="Reset Password"  
    subTitle = 'please enter your email to reset password' 
    onCancel={onClose} 
    onSubmit={onSubmit}
    hideActionButtons={true}>
    <ResetPassword onLogin={onLogin} onSubmit={onSubmit}/>
</Modal>
  )
}

export default ForgetPasswordModal