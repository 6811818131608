export const parseJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const classNames= (...args) => {
    const classes = [];

    for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (!arg) {
            continue;
        }

        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push((this && this[arg]) || arg);
        } else if (Array.isArray(arg)) {
            classes.push(classNames.apply(this, arg));
        } else if (argType === 'object') {
            for (const key in arg) {
                // eslint-disable-next-line no-undef
                if (hasOwn.call(arg, key) && arg[key]) {
                    classes.push((this && this[key]) || key);
                }
            }
        }
    }
    return classes.join(' ');
}


export const isEmpty = (obj) => {
    if(!obj || typeof obj !== 'object') { return; }
    // eslint-disable-next-line no-unused-vars
    for(const i in obj) {
        if(obj) {
          return false;
        } 
    } 
    return true;
}

export const arrayIsEmpty= (emptyArray)=> {
   return typeof emptyArray != "undefined" && emptyArray != null && emptyArray.length != null && Array.isArray(emptyArray)
        && emptyArray.length === 0
}

export const convertNumberToK = (number) => {
    if (number > 999) {
        return `${(number / 1000).toFixed(0)}k`;
    }
    return number;
}
