import Request from "./Request"
interface IBusinessRequestForm {
    company_name:string
    representative_name: string
    email:string
    representative_phone_number: number
    business_sector?: string
    message?: string
}

export const BusinessRequestForm = (body:IBusinessRequestForm) => {
    return Request('POST', `/waitlist/business/`, body);
} 