import { classNames } from '../Utils';
import { Button } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { IMenuItem, useMenu } from '../context/MenuContext';
import { useLocation } from 'react-router-dom';
import FWHeader from '../components/base/FWHeader';
import FAB from '../components/base/FAB';
import { useEffect } from 'react';

const Dashboard = ()=> {
    const {isLoggedIn, logOut, currentUser} = useAuth();
    const {menuItems, activeMenu, setActiveMenu, menuExpanded, setMenuExpanded} = useMenu()
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(()=>{
        const url = location.pathname.split('/')[2];
        switch (url) {
            case 'credentials':
                setActiveMenu(4)
                break;
            case 'programs':
                setActiveMenu(5)
                break;
            case 'account':
                setActiveMenu(6)
                break;
            case 'cv':
                setActiveMenu(2)
                break;
            default:
                break;
        }
        
        // currentUser?.is_university_staff && setActiveMenu(4);
    },[location])

    

    const styleClass =  {
        hamburger: classNames(
            'py-5', 
            'pl-6',
            'text-white', 
            'cursor-pointer'
        ),
        menuWrapper: classNames(
            'flex',
            'flex-col',
            'h-full',
            'pt-[20vh]'
        ),
        menu: (active:boolean, first:boolean, hide:boolean) => classNames(
            'flex',
            'flex-row',
            'p-6',
            'items-center',
            active ? 'font-bold': 'font-normal',
            active ? 'text-fw-orange' : 'text-gray-400 hover:text-fw-orange',
            !active ? `${first ? 'border-t' : 'border-t-0'} border border-b-half border-r-0 border-l-0  border-gray-700 hover:bg-fw-white-active hover:opacity-75` : 'bg-fw-white-active',
            'text-lg',
            'cursor-pointer',
            'transition duration-500 ease-in-out',
            'h-16',
            hide && 'hidden'
        ),
        menuIcon: classNames(
            'mr-6', 
            'h-full', 
            'flex', 
            'items-center' 
        )
    }

    const onMenuClick = (index:number, url:string) => {
        setActiveMenu(index);
        const state = index === 1 ? {showAllJobs: true} : undefined;
        navigate(url,{state}) 
    }

    const toggleMenu = ()=> {
        setMenuExpanded(menuExpanded === 'compact' ? 'expanded' : menuExpanded === 'expanded' ? 'compact' : 'none');
    }

    const onSignOut =()=> {
        logOut();
    }
    
    return(
            <div className={`dashboard ${isLoggedIn() ? menuExpanded : 'none'}`}>
                {isLoggedIn() && <aside className={`bg-fw-blue`}>
                    {/* Humberger Menu and Logo*/}
                    <div>
                        <div onClick={toggleMenu} className={styleClass.hamburger}>
                            <img src="/hamburger.svg" alt="" style={{height: 10, width: 'auto', color: 'white'}} />
                        </div>
                        <div className={'bg-fw-white-faint pl-[13px]'}>
                            {menuExpanded === 'expanded' ?
                            <img src={'/fw-logo-white-text.png'} alt="" style={{height:54, width: 'auto'}} /> :
                            <img src={'/fw-icon-white.png'} alt="" style={{height:54, width: 'auto'}} />}
                        </div>
                    </div>

                    {/* Menu Items */}
                    <div className={styleClass.menuWrapper}>
                        {menuItems && menuItems.map((menu: IMenuItem) =>  
                            <div key={menu.id} onClick={() => onMenuClick(menu.id, menu.url)} className={styleClass.menu(activeMenu === menu.id, menu.id===0, menu.hidden)}>
                                <div className={styleClass.menuIcon}>
                                    {menu.id === activeMenu ? menu.activeIcon ? menu.activeIcon : menu.icon : menu.icon}
                                </div>
                                {menuExpanded === 'expanded' && <p>{menu.name}</p>}
                            </div>
                        )}
                    </div>
                    
                    <div className={'sticky bottom-0'}>
                        <Button className={'p-0 md:p-auto rounded-none'} onClick={onSignOut} block type="primary">Sign Out</Button>
                    </div>
                </aside>}

                <main>
                <FWHeader />
                <div className={`overflow-auto ${!isLoggedIn() ? 'bg-fw-gray' : ''}`}>
                    <Outlet  />
                    {currentUser?.is_university_staff && <FAB />}
                </div>
                </main>
            </div>
    )
}

export default Dashboard;