import React, { useEffect, useState } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import { getOrganizationJobs } from '../../services/JobService';
import { IJob } from '../../types';
import PageSpinner from '../../components/base/PageSpinner';
import { Empty } from 'antd';
import JobListItem from '../../components/jobs/JobListItem';

const SeeMoreJobs = () => {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const showAllJobs = location.state?.showAllJobs;
    const onJobApply = ()=> {
        navigate('/')
        //show signup screen
    }

    useEffect(() => {
        setLoading(true);
        getOrganizationJobs()
        .then(res => {
            if(res?.data && Array.isArray(res?.data)) setJobs(res.data.reverse());
        })
        .catch(err => console.log(err))
        .finally(()=> setLoading(false))
    }, [])
  return (
    <div className={'mt-6'}>
    {loading ? <PageSpinner /> :
    (jobs?.length >0 ? jobs.map((job,i) => <JobListItem key={i} job={job} onApply={onJobApply} isStaff={false} userMyJobs={showAllJobs} />) : <Empty description={'Login or SignUp to apply to jobs!'}/>)}
</div>
  )
}

export default SeeMoreJobs
