import { Button, Form, Slider } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../../../components/base/Modal'
import { FilterStyleClass } from '../../../components/selection/Filter/FilterHelper'
import SelectionFilter from '../../../components/selection/Filter/SelectionFilter'
import VettedChart from '../../../components/selection/Filter/VettedChart'
import WagonWarning from '../../../components/selection/WagonWarning'
import useAlert from '../../../components/toast/useAlert'
import { finishExamWagon, setExamFilter } from '../../../services/ExamService'

interface IExamComplete {
    selectionId: number
    onClose: () => void
    onSubmit: () => void
}

const ExamComplete:React.FC<IExamComplete> = ({selectionId, onSubmit, onClose}) => {
    const [form] = Form.useForm();
    const marks = {
        0: '0',
        100: '100'
    }

    const {addAlert} = useAlert()
    const [saving, setSaving] = useState(false)
    const [result, setResult] = useState<{selected: number, total: number}>()
    const [showFilter, setShowFilters] = useState(false)
    const [reload, setReload] = useState(false)
    const [value, setValue] = useState(50)

    const onChange = useCallback((val: number) => {
        setSaving(true)
        setValue(val)
        setExamFilter(selectionId, {grade: val}).then(res => {
            setResult(res?.data.result)
        }).catch((err) => {
            addAlert({type: 'error', title: 'error', description: 'something went wrong', autoClose: true})
        }).finally(() => setSaving(false))
    }, [addAlert, selectionId])

    useEffect(() => {
        onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, reload])

    const submit = () => {
        setSaving(true)
        finishExamWagon(selectionId).then(res => {
            onSubmit()
        }).catch(err => {
            addAlert({type: 'error', title: 'error', description: JSON.stringify(err), autoClose: true})
        }).finally(() => setSaving(false))
    }

    return(
        <Modal
         title='Complete Exam Step'
         onCancel={onClose}
         onSubmit={submit}
         className={'min-h-[70%] w-[70%]'}
         submitDisabled={saving}
        >
            <div className={FilterStyleClass.item.root}>
            <section className={FilterStyleClass.item.leftSection}>
                <Form
                    name="addJob"
                    onFinish={onSubmit}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-4'}
                    form={form}
                >
                 <p className={FilterStyleClass.item.subTitle}>Exam Result</p>
                <Slider
                   className={'col-span-1 md:col-span-3 row-span-2'}
                   marks={marks} 
                   min={0} 
                   max={100} 
                   step={1}
                   defaultValue={50}
                   value={value}
                   tooltipVisible
                   onChange={onChange}
                   disabled={saving}
                />
                <WagonWarning />
                <Button className={'mt-2'} onClick={() => setShowFilters(true)} size={'small'} type={'primary'}>Edit Filters</Button>
             </Form>
            </section>
            <section className={FilterStyleClass.item.rightSection}>
                {result && <VettedChart selected={result.selected} total={result.total} />}
            </section>
            {showFilter && selectionId &&
                <SelectionFilter 
                    selectionId={selectionId}
                    onSubmit={() => {
                        setShowFilters(false)
                        setReload(prev => !prev)
                    }}
                    onClose={() => setShowFilters(false)}
                />} 
        </div>
        </Modal>
    )
}

export default ExamComplete