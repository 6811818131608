import { Form, Slider, Button } from 'antd'
import React, { FC, useMemo, useState } from 'react'
import { saveMotivationFilters } from '../../../services/SelectionService'
import { FilterStyleClass, IFilter } from './FilterHelper'
import VettedChart from './VettedChart'

const MotivationFilter:FC<IFilter> = ({filter, onCancel, onSave, result, selectionId, rawData }) => {

    const marks = {
        0: '0',
        5: '5'
    }

    const [saveLoading, setSaveLoading] = useState(false)
    const getDefaultValues = useMemo(() => {
        return {
            rating: rawData.rating !== null ? +rawData.rating : 0,
        }
    }, [rawData])

    const onSubmit = (values: any) => {
        const {rating} = values
        setSaveLoading(true)
        const body = {
            rating: rating
        }
        saveMotivationFilters(selectionId, body).then((res) =>{
            onSave()
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setSaveLoading(false)
        })
    }

    return(
        <div className={FilterStyleClass.item.root}>
            <section className={FilterStyleClass.item.leftSection}>
                <p className={FilterStyleClass.item.title}>{filter.title} (Selected)</p>
                <Form
                    name="addJob"
                    onFinish={onSubmit}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-4'}
                    initialValues={getDefaultValues}
                    layout={'vertical'}
                >
                 <p className={FilterStyleClass.item.subTitle}>Motivation Result</p>
                 <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="rating"
                >
                <Slider
                   className={'col-span-1 md:col-span-3 row-span-2'}
                   marks={marks} 
                   min={0} 
                   max={5} 
                   step={0.5}
                   tooltipVisible
                />
                </Form.Item>
                <div className={FilterStyleClass.item.actionBtns}>
                    <Button size={'small'} onClick={onCancel} type={'default'}>Cancel</Button>
                    <Button loading={saveLoading} disabled={saveLoading} size={'small'} htmlType='submit'  type={'primary'} >Save</Button>
                </div>
             </Form>
            </section>
            <section className={FilterStyleClass.item.rightSection}>
                <VettedChart selected={result.selected} total={result.total} />
            </section>
        </div>
    )
}

export default MotivationFilter