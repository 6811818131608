import { Tooltip } from 'antd'
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import React from 'react'
import { classNames } from '../../../Utils'
import { IPieData } from '../PieChartFW'
import { IFilterSummaryData } from './FilterHelper'


const FilterSummary:React.FC<IFilterSummaryData> = ({id, title, data, onClick}) => {
    const styleClass = {
        root: classNames(
            'bg-gray-100', 
            'w-48', 
            'min-h-44',
            'h-auto', 
            'max-h-[190px]',
            'rounded-md',
            'p-3',
            'shadow-sm',
            'cursor-pointer',
            'hover:shadow-md'
        ),
        title: classNames(
            'text-gray-600',
            'font-bold',
            'text-lg'
        ),
        dataText: classNames(
            'text-gray-500',
            'text-sm'
        )
    }

    const maxStringLen = 34

    const isOverflowing = (text: string | number) => text?.toString()?.length > maxStringLen

    const trimText = (text: string | number) => {
        if(typeof text === 'string' && text === '') {
            return 'n/a'
        }
        return isOverflowing(text) ? text?.toString()?.slice(0, maxStringLen-1) : text
    }
    
    return(
        <div className={styleClass.root} onClick={() => onClick(id)}>
            <p className={styleClass.title}>{title}</p>
                {data.map(({name, value}:IPieData) => (
                    <p className={styleClass.dataText}>
                        <b className={'pr-2'}>{name}</b>     
                        <span className={'pr-2'}>{trimText(value)}</span>
                        {isOverflowing(value) && <Tooltip title={value}><InfoCircleOutlined className={'text-xss'} /></Tooltip>}
                    </p>
                ))}
        </div>
    )
}

export default FilterSummary