import { useEffect , useMemo, useState} from 'react'
import { BankOutlined } from '@ant-design/icons'
import Request from '../../services/Request'
import { AutoComplete } from 'antd'
interface IEmpoyerList {
    onSelect : ( id:number,name: string) => void
    className?: string
}

const EmployerList = ({onSelect, className = ''}:IEmpoyerList) => {
    const [selectedEmployer, setSelectedEmployer] = useState<number>(0)
    const [employers , setEmployers] = useState<Array<{name: string, id: number}>>([])

    useEffect(() => {
        Request('GET', '/business/').then(res => res?.data).then(arr=> {
            setEmployers([{id:0, name: 'All'},...arr])  
        })
    }, [])

    const selectedEmployerName = useMemo(() => {
        return employers?.find(item => item.id === selectedEmployer)?.name ?? 'All'
    }, [selectedEmployer, employers])

    return (
        <div className='flex items-center'>
        <BankOutlined className='ml-2'/>
        <AutoComplete
            bordered={false}
            style={{ width: 150 }}
            options={employers.map(item => ({label: item.name, value: item.id}))}
            defaultActiveFirstOption={true}
            placeholder="Select Employer"
            defaultValue={'All'}
            value={selectedEmployerName}
            onSelect={(value, option) => {
                setSelectedEmployer(+value)
                onSelect(+value, selectedEmployerName)
            }}
            // filterOption={(inputValue, option) =>
            //     option!.value.toLowerCase().indexOf(inputValue.toUpperCase()) !== -1
            // }
            className={className}
        />
        </div>
        
    )
}

export default EmployerList