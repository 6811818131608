import { Carousel, Rate, Spin } from "antd";
import StepForwardOutlined from '@ant-design/icons/lib/icons/StepForwardOutlined'
import StepBackwardOutlined from '@ant-design/icons/lib/icons/StepBackwardOutlined'
import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "../../../components/base/Modal";
import CVDetail from "../../cv/CVDetail";
import { CarouselRef } from "antd/lib/carousel";
import { getCVsForRating, rateCV } from "../../../services/ScreeningService";
import { ICV } from "../../../types";

interface IScreeningEvaluation {
    selectionId: number
    candidateCVIds: {id: number, cvId: number}[]
    onClose: () => void
    onSubmit: () => void
    cvs: ICV[]
}

const ScreeningEvaluation:React.FC<IScreeningEvaluation> = ({selectionId, onClose, onSubmit, candidateCVIds, cvs}) => {

    const ref = useRef<CarouselRef>(null)
    const [loading, setLoading] = useState(false)
    const [currentCVIndex, setCurrentCVIndex] = useState<number>(0)
    const [reload, setReload] = useState<boolean>(false)
    const [resetScroll, setResetScroll] = useState<boolean>(false)
    const [ratings, setRatings] = useState<{id: number, cvId: number, rating: number}[]>([])

    useEffect(() => {
        setLoading(true)
        getCVsForRating(selectionId).then((res) => {
            const data = res?.data || []
            const newRatings = data.map((item: any) => {
                return {
                    id: item.id,
                    cvId: item.cv.cv,
                    rating: item.is_rated ? item.rating : 0
                }
            })
            setRatings(newRatings)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [selectionId, reload])

    const onRateCV = (val: number) => {
        if(val >0 && !loading) {
            setLoading(true)
            rateCV(selectionId, candidateCVIds[currentCVIndex].id, {rating: val}).then(res => {
                setReload(prev => !prev)
            }).finally(() => setLoading(false))
        }
    }

    const onMoveNext = () => {
        ref?.current?.next()
        setCurrentCVIndex(prev => prev+1)
        setResetScroll(prev => !prev)
    }

    const onMoveBack = () => {
        ref?.current?.prev()
        setCurrentCVIndex(prev => prev-1)
        setResetScroll(prev => !prev)
    }

    const cvRating = useMemo(() => {
        const rating = ratings.find(item => item.cvId === candidateCVIds[currentCVIndex].cvId)
        return rating?.rating || 0
    }, [ratings, candidateCVIds, currentCVIndex])

    return(
        <Modal resetScroll={resetScroll} title={'Evaluate CVs'} className={'w-[80%] h-[96vh] mt-2 max-h-full bg-gray-200 relative'} onCancel={onClose} 
        onSubmit={onSubmit} hideCurve={true} cancelText={'Close'} submitText={'Save'} >
            <div className={'flex justify-between items-center px-2 rounded-md absolute top-12 z-50 bg-white w-max border-fw-orange border-solid border-[0.5px]'}> 
                <p className={'mr-2 font-medium'}>{`Rate this CV (${currentCVIndex + 1} of ${candidateCVIds?.length})`}</p>
                {currentCVIndex > 0 && <StepBackwardOutlined disabled={loading} onClick={onMoveBack} className={'mr-1 mt-2 text-fw-orange cursor-pointer text-lg'}  />}
                {loading ? <Spin className={'scale-75'} /> : <Rate value={cvRating} disabled={loading} onChange={onRateCV} allowHalf className={'scale-75'} />}
                {currentCVIndex < candidateCVIds.length -1 && <StepForwardOutlined disabled={loading} onClick={onMoveNext} className={'ml-1 mt-2 text-fw-orange cursor-pointer text-lg'} />}
                <p className={'text-gray-400 ml-2 text-sm'}>Please rate CVs by clicking on stars. You can navigate to see profiles.</p>
            </div>
            <Carousel ref={ref} className={'text-gray-600 mt-2'} dots={false}>
                {candidateCVIds.map((candidate: {id: number, cvId: number}, key) => <CVDetail key={key} id={candidate.cvId} />)}
            </Carousel>
        </Modal>
    )
}

export default ScreeningEvaluation