import React, { FC } from 'react'
import Login from "../../components/onboarding/Login";
import Modal from "../../components/base/Modal";

interface ILoginModal {
    onLoginSubmit: () => void
    onClose: () => void
    onSignup:() => void
    redirectTo : string
    onForgetPassword : () => void
}

const LoginModal:FC<ILoginModal> = ({onLoginSubmit, onClose, onSignup, redirectTo, onForgetPassword}) => {
    return(
        <Modal
            className='w-[400px] -ml-3 sm:!m-auto'
            title="Login"  
            subTitle = 'please login or register to continue' 
            onCancel={onClose} 
            onSubmit={onLoginSubmit}
            hideActionButtons={true}>
            <Login onSignup={onSignup} redirectTo={redirectTo} onForgetPassword={onForgetPassword} />
        </Modal>
    )
}

export default LoginModal