import React from 'react'
import { classNames } from '../../Utils'

interface IChip {
  val: number | string
  textColor?: 'grey' | 'red' | 'blue' | 'white'
  bgColor?: 'grey' | 'white' | 'blue' | 'red' | 'green'
  onClick?: (e?: any) => void
  disabled?: boolean
  className?: string
}

const Chip: React.FC<IChip> = ({
  val,
  textColor = 'grey',
  bgColor = 'grey',
  onClick,
  disabled = false,
  className = '',
}: IChip) => {
  const styleClass = {
    root: classNames(
      'mr-1',
      'px-2',
      'w-auto',
      'h-[22px]',
      'rounded-full',
      'text-xs',
      'border',
      'border-d-grey-lightest',
      'flex',
      'items-center',
      'justify-center',
      textColor === 'grey' && 'text-d-fontchip',
      textColor === 'blue' && 'text-d-darkblue',
      textColor === 'red' && 'text-d-danger',
      textColor === 'white' && 'text-white',
      bgColor === 'grey' && 'bg-d-grey-lightest',
      bgColor === 'white' && 'bg-white',
      bgColor === 'blue' && 'bg-fw-blue',
      bgColor === 'red' && 'bg-red-500',
      bgColor === 'green' && 'bg-fw-green',
      onClick && 'hover:border-fw-blue',
      disabled && 'cursor-not-allowed opacity-50',
      className
    ),
  }

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  return (
    <div onClick={handleClick} className={styleClass.root}>
      {val}
    </div>
  )
}

export default Chip