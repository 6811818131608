import React from 'react'
import { classNames } from '../../Utils';

interface IFWSubHeader {
   title?: string;  
   children?: JSX.Element | null;
   className?: string;
}

const FWSubHeader = ({title, children, className = ''}:IFWSubHeader)=> {

    const styleClass = {
        root: classNames(
            'bg-fw-light-gray', 
            'w-full', 
            'flex', 
            'gap-2', 
            'flex-wrap', 
            'mb-2',
            className
        ),
        title: classNames(
            'text-lg', 
            'md:text-xl', 
            'text-fw-blue', 
            'font-semibold'
        )
    }

    return(<div className={styleClass.root}>    
                <p className={styleClass.title}>{title}</p>
                {children}
            </div>)
}

export default FWSubHeader;