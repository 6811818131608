import React, { CSSProperties, useMemo } from 'react'
import { baseAddress } from '../../services/Request'
import { ISelection } from '../../types'
import { convertMaptoArr } from '../../Utility'
import { classNames, isEmpty } from '../../Utils'
import Chip from '../base/Chip'
import ProgressBar from '../base/ProgressBar'
import PieChartFW from './PieChartFW'

interface ISelectionStats {
    selection: ISelection
    applicationIsClosed: boolean
}

const SelectionStats:React.FC<ISelectionStats> = ({selection, applicationIsClosed}:ISelectionStats) => {

    const styleClass = {
        root: classNames(
            'flex',
            'flex-col',
            'lg:flex-row',
            'gap-4'
        ),
        logo: classNames(
            'w-[250px]',
            'h-[140px]',
            'p-4',
            'border',
            'border-fw-blue',
            'border-dashed',
            'rounded',
            'bg-white',
            'flex',
            'justify-center',
            'items-center'
        ),
        box: (autoHeight?: boolean) => classNames(
            'border',
            'border-fw-blue',
            'border-dashed',
            'rounded',
            'bg-white',
            'px-4',
            'py-2',
            'w-[320px]',
            autoHeight ? 'h-auto w-auto md:w-max' : 'h-[140px]',
            'flex',
            'flex-col',
            'justify-start',
            'md:justify-between'
        ),
        title: classNames(
            'text-fw-orange',
            'text-base',
            'font-bold'
        ),
        subtitle: classNames(
            'font-semibold',
            'text-xs',
            'text-fw-orange',
            'min-w-[50px]',
            'whitespace-nowrap'
        )
    }

    const {current_filtered_applicants, number_of_applicants, eligible_applicants, applicant_universities, applicant_field_of_study, applicant_skills, train} = selection

    const imageURL = useMemo(() => {
        // return selection?.business?.logo ? `${baseAddress}/${selection?.business?.logo}`: '/logo-dummy-min.jpg'
        return selection?.business?.logo ? `${selection?.business?.logo}`: '/logo-dummy-min.jpg'
    }, [selection])

    const legendStyle = () => ({writingMode: 'vertical-lr', margin: 0, fontWeight: 400, color: '#FDB82C'})
    return(
        <div className={styleClass.root}>
            <div className={styleClass.logo}>
                <img src={imageURL} alt="" width={'auto'} height={'120px'} />
            </div>
            <div className={styleClass.box()}>
                <div className={'flex justify-between items-center'}>
                    <p className={styleClass.title}>Current/Total <span className={'text-xs'}>Applicants</span></p>
                    <span className={'font-bold text-sm text-fw-blue'}>{current_filtered_applicants?.length ?? 0}/{number_of_applicants ?? 0}</span>
                </div>
                {eligible_applicants !== null ?
                <ProgressBar value={eligible_applicants} total={number_of_applicants} color={'green'} label={'Eligible/Total'} showPercentage={eligible_applicants > -1} /> :
                <p className={'text-gray-400 text-xs mb-0.5'}>No data on Eligible Applicants</p>
                }

                {current_filtered_applicants !== null ?
                <ProgressBar value={current_filtered_applicants?.length} total={number_of_applicants} color={'green'} label={'Onboarded/Total'} showPercentage={current_filtered_applicants?.length > -1} /> :
                <p className={'text-gray-400 text-xs mb-0.5'}>No data on Current Applicants</p>
                }
                
                <div className={'flex items-center gap-2'}>
                    <div className={'flex-auto flex justify-between items-center'}>
                        <p className={styleClass.subtitle}>Current Action</p>
                        <span className={'pl-1 font-bold text-sm text-fw-blue'}>{train?.length > 0 ? train?.length : 'N/A'}</span>
                    </div>
                    <div className={'flex-auto flex justify-between items-center'}>
                        <p className={styleClass.subtitle}>Application</p>
                        <Chip val={!applicationIsClosed ? 'open' : 'closed'} textColor={'white'} className={'!mr-0'} bgColor={!applicationIsClosed ? 'green' : 'red'}  />
                    </div>
                </div>
            </div>
            <div className={`${styleClass.box(true)} !flex-row !py-0 items-center gap-4 flex-wrap h-auto md:h-[140px] md:flex-nowrap`}>
                <p className={'!font-semibold text-base'} style={legendStyle() as CSSProperties}>Applicant Stats</p>
                {!isEmpty(applicant_universities) ? 
                    <div className={'items-center flex'}>
                        <p style={legendStyle() as CSSProperties}>Universities</p>
                        <PieChartFW data={convertMaptoArr(applicant_universities)} />
                    </div> :  <p className={'text-gray-400 text-xs mb-0.5'}>No stats on Universities</p>}
                {!isEmpty(applicant_field_of_study) ? 
                <div className={'items-center flex'}>
                <p style={legendStyle() as CSSProperties}>Fields of Study</p>
                <PieChartFW data={convertMaptoArr(applicant_field_of_study)} />
                </div> :  <p className={'text-gray-400 text-xs mb-0.5'}>No stats on field of Study</p>}
                    {!isEmpty(applicant_skills) ? 
                    <div className={'items-center flex'}>
                    <p style={legendStyle() as CSSProperties}>Skills</p>
                    <PieChartFW data={convertMaptoArr(applicant_skills)} />
                </div> :  <p className={'text-gray-400 text-xs mb-0.5'}>No stats on Skills</p>}
            </div>
    </div>
    )
}

export default SelectionStats