import React from 'react';

const FWHeroFooterSimple = () => {
  return (
    <div>
        <div className='border-fw-blue-light border-opacity-10 border-solid border-t border-b-0 border-x-0 max-w-7xl mx-auto'>
            <div className='flex flex-col justify-between sm:flex-row items-center mx-2 sm:mx-[6vw] py-6'>
            <img src="/fairway_logo_black_text.png" alt="logo" className='h-10 w-auto sm:mb-0 sm:h-16' />
            <div className='text-center sm:text-left'>&copy; 2023 Fairway.oy All Right Reserved.</div>
            <div className='flex justify-center sm:justify-end gap-4 sm:gap-8 mt-3 md:mt-0'>
                <img src="/icons8-facebook-50.png" alt="social logo" className='w-5 h-5' />
                <img src="/icons8-instagram-50.png" alt="social logo" className='w-5 h-5' />
                <img src="/icons8-linkedin-50.png" alt="social logo" className='w-5 h-5' />
                <img src="/icons8-twitter-50.png" alt="social logo" className='w-5 h-5' />
            </div>
            </div>
        </div>
    </div>

  );
};

export default FWHeroFooterSimple;
