import React from "react";
import { PieChart, Pie, Cell, Tooltip, TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

export interface IPieData {
    name: string
    value: number | string
}

interface IPieChartFW {
    data: IPieData[]
}

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div className="bg-gray-100 rounded-sm text-xs p-1">
          <p className="label">{payload?.[0].name}: 
          <b>
            {payload?.[0].value}
          </b>
          </p>
        </div>
      );
    }
  
    return null;
  };

const PieChartFW:React.FC<IPieChartFW> = ({data}) => {
    const COLORS = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#d7658b", "#c80064"]
    return(<PieChart width={130} height={130}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={62}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
          </PieChart>)
}

export default PieChartFW