import { IExam, IExaminer } from "../types";
import Request from "./Request"

export const releaseExam = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/exam/release/`, {"release": true});
}

export const getExam = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/exam/`);
}

export const setExam = (selectionId: number, exam: Omit<IExam, 'id'>) => {
    return Request('POST', `/selection/${selectionId}/exam/`, exam);
}

export const deleteExam = (selectionId: number, examId: number) => {
    return Request('DELETE', `/selection/${selectionId}/exam/${examId}/`);
}

export const updateExam = (selectionId: number, examId: number, exam: Partial<IExam>) => {
    return Request('PATCH', `/selection/${selectionId}/exam/${examId}/`, exam);
}

export const addExaminer = (selectionId: number, exam: Omit<IExaminer, 'id'>) => {
    return Request('POST', `/selection/${selectionId}/exam/institution/`, exam);
}

export const getExaminers = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/exam/institution/`);
}

export const getExaminer = (selectionId: number, examinerId: number) => {
    return Request('GET', `/selection/${selectionId}/exam/institution/${examinerId}/`);
}

export const updateExaminer = (selectionId: number, examiner: Partial<IExaminer>) => {
    return Request('PUT', `/selection/${selectionId}/exam/institution/${examiner.id}/`, examiner);
}

export const deleteExaminer = (selectionId: number, examinerId: number) => {
    return Request('DELETE', `/selection/${selectionId}/exam/institution/${examinerId}/`);
}

export const assignExaminer = (selectionId: number, examId: number, examinerId: number) => {
    return Request('PATCH', `/selection/${selectionId}/exam/${examId}/`, {exam_institution: examinerId});
}

export const downloadAttendanceSheet = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/exam/attendance/`, {download: true}, undefined, 'blob');
}

export const downloadGradeSheet = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/exam/grade_sheet/`, {download: true} , undefined, 'blob');
}

export const downloadInfoSheet = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/exam/infosheet/`, {download: true} , undefined, 'blob');
}

export const uploadGradeSheet = (selectionId: number, body: FormData) => {
    return Request('POST', `/selection/${selectionId}/exam/upload/`, body);
}

export const getExamFilter = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/examfilter/`);
}

export const getExamInfo = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/get_exam_info/`);
}

export const setExamFilter = (selectionId: number, body: {grade: number}) => {
    return Request('POST', `/selection/${selectionId}/examfilter/`, body);
}

export const finishExamWagon = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/exam/finish/`, {finish: true});
}