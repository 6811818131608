import { Button } from 'antd'
import React, { useState } from 'react'

interface ILandingHeader {
  login: () => void
  onSignUp: () => void
  onBusinessSignUp: () => void
}
const LandingHeader = ({login, onSignUp, onBusinessSignUp}: ILandingHeader) => {
    const [open, setOpen] = useState(false);
  
    return (
            <section className={`mx-auto  flex items-center justify-between sm:h-10 md:justify-center py-8 px-8 max-w-7xl`}>
        <div className="flex items-center flex-1">
            {/* md:absolute md:inset-y-0 md:left-6 */}
            <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/home" aria-label="Home">
                    <img src={'/fairway_logo_black_text.png'} height="50" width="auto" alt="fairway logo"/>
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                    <button type="button" id="main-menu" aria-label="Main menu" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" className="h-6 w-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                    </button>
                </div>
            </div>
        </div>
        <div className="hidden md:flex md:space-x-5 lg:space-x-10 mr-40 lg:w-auto lg:mr-[25px] md:m-0 md:w-full md:justify-center md:items-center">
            <a href="#search"
                className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Find Jobs</a>
            <a href="#howitworks"
                className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">How It Works</a>
            <a href="#companies"
                className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Companies</a>
        </div>
        <div className="hidden md:static md:flex gap-2 md:items-center md:justify-end md:inset-y-0 md:right-4">
        <Button type="ghost" htmlType="submit" onClick={onBusinessSignUp} className='mr-3 bg-fw-green'>
                Business Register
            </Button>
            <Button type="default" htmlType="submit" onClick={onSignUp} className='mr-3'>
                        Sign Up
                    </Button>
            <Button type="primary" htmlType="submit" onClick={login}>
                Log In
            </Button>
        </div>
        <div
                className={
                  open
                    ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right md:hidden z-20"
                    : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
                }
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="/fairway_logo_black_text.png"
                          alt="fairway logo"
                        />
                      </div>
                      <div className="-mr-2">
                        <button
                          type="button"
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fw-blue-light"
                          onClick={() => setOpen(!open)}
                        >
                          <span className="sr-only">Close menu</span>
                          {/* Heroicon name: outline/x */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        <a
                          href="#search"
                          onClick={()=> setOpen(prev => !prev)}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/chart-bar */}
                          <img src="/job.svg" alt="job"  className="flex-shrink-0 h-6 w-6 text-fw-blue-light"/>
                          {/* <svg
                            className="flex-shrink-0 h-6 w-6 text-fw-blue-light"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                            />
                          </svg> */}
                          <span className="ml-3 text-base font-medium text-gray-900">
                            Find Jobs
                          </span>
                        </a>
                        <a
                          href="#howitworks"
                          onClick={()=> setOpen(prev => !prev)}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/cursor-click */}
                          <img src="/help.svg" alt="job"  className="flex-shrink-0 h-6 w-6 text-fw-blue-light"/>
                          {/* <svg
                            className="flex-shrink-0 h-6 w-6 text-fw-blue-light"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          </svg> */}
                          <span className="ml-3 text-base font-medium text-gray-900">
                            How It Works
                          </span>
                        </a>
                        <a
                          href="#companies"
                          onClick={()=> setOpen(prev => !prev)}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/shield-check */}
                          <img src="/company.svg" alt="job"  className="flex-shrink-0 h-6 w-6 text-fw-blue-light"/>
                          {/* <svg
                            className="flex-shrink-0 h-6 w-6 text-fw-blue-light"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                            />
                          </svg> */}
                          <span className="ml-3 text-base font-medium text-gray-900">
                            Compaines
                          </span>
                        </a>
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div>
                    <Button
                        onClick={onBusinessSignUp}
                        className="mb-4 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-fw-blue bg-fw-green hover:bg-fw-blue"
                      >
                        Business Register
                      </Button>
                      <Button
                        onClick={onSignUp}
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-fw-blue bg-fw-orange hover:bg-fw-blue"
                      >
                        Sign up
                      </Button>
                      <p className="mt-4 text-center text-base font-medium text-fw-blue">
                        Existing customer?
                        <Button className="text-fw-blue-light hover:bg-fw-blue border-none" onClick={login}>
                          Login
                        </Button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
    </section>
    )
}

export default LandingHeader