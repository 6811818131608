import { ICV, ICVAchievement, ICVCertification, ICVEducation, ICVExperience, ICVProfile } from "../types";
import Request from "./Request"

export const getMyCVDetail = () => {
    return Request('GET', '/cv/');
} 

export const getMyCV = () => {
    return Request('GET', `/cv/`);
} 

export const getFullCV = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/`);
} 

export const getCVProfile = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/profile/`);
} 

export const updateCVProfile = (cvId:number, body:ICVProfile) => {
  return Request('POST', `/cv/${cvId}/profile/`, body);
} 

export const getCVExperience = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/experience/`);
}

export const addCVExperience = (cvId:number, body:ICVExperience) => {
    return Request('POST', `/cv/${cvId}/experience/`, body);
} 

export const updateCVExperience = (cvId:number, itemId: number, body:ICVExperience) => {
    return Request('PUT', `/cv/${cvId}/experience/${itemId}/`, body);
} 

export const deleteCVExperience = (cvId:number, itemId: number) => {
    return Request('DELETE', `/cv/${cvId}/experience/${itemId}/`);
} 

export const getCVEducation = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/education/`);
} 

export const addCVEducation = (cvId:number, body:ICVEducation) => {
    return Request('POST', `/cv/${cvId}/education/`, body);
} 

export const updateCVEducation = (cvId:number, itemId:number, body:ICVEducation) => {
    return Request('PUT', `/cv/${cvId}/education/${itemId}/`, body);
} 

export const deleteCVEducation = (cvId:number, itemId:number) => {
    return Request('DELETE', `/cv/${cvId}/education/${itemId}/`);
} 

export const getCVAchievements = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/achievements/`);
} 

export const addCVAchievement = (cvId:number, body: ICVAchievement) => {
    return Request('POST', `/cv/${cvId}/achievements/`, body);
}

export const updateCVAchievement = (cvId:number, itemId:number, body: ICVAchievement) => {
    return Request('PUT', `/cv/${cvId}/achievements/${itemId}/`, body);
}

export const deleteCVAchievement = (cvId:number, itemId:number) => {
    return Request('DELETE', `/cv/${cvId}/achievements/${itemId}/`);
} 

export const getCVCertifications = (cvId:number) => {
    return Request('GET', `/cv/${cvId}/certifications/`);
}

export const addCVCertification = (cvId:number, body: ICVCertification) => {
    return Request('POST', `/cv/${cvId}/certifications/`, body);
}

export const updateCVCertification = (cvId:number, itemId:number, body:ICVCertification) => {
    return Request('PUT', `/cv/${cvId}/certifications/${itemId}/`, body);
}

export const deleteCVCertification = (cvId:number, itemId:number) => {
    return Request('DELETE', `/cv/${cvId}/certifications/${itemId}/`);
}

export const checkCVExportService = () => {
    return Request('GET', `/_health`, undefined, true);
}

export const exportCVtoPDF = (cv: ICV) => {
    return Request('POST', `/pdf`, cv, true);
}
