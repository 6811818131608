import Request from "./Request"

export const releaseScreening = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/individual_screening/release/`, {"release": true});
}

export const getCVsForRating = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/individual_screening/`);
}

export const rateCV = (selectionId: number, cvId: number, body: {rating: number}) => {
    return Request('PUT', `/selection/${selectionId}/individual_screening/${cvId}/`, body);
}

export const getScreeningFilter = (selectionId: number) => {
    return Request('GET', `/selection/${selectionId}/individualscreeningfilter/`);
}

export const setScreeningFilter = (selectionId: number, body: {rating: number}) => {
    return Request('POST', `/selection/${selectionId}/individualscreeningfilter/`, body);
}

export const finishScreening = (selectionId: number) => {
    return Request('POST', `/selection/${selectionId}/individual_screening/finish/`, {"finish": true});
}
