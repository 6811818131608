import { Button } from 'antd';
import * as React from 'react';
import useNetwork from '../../hooks/useNetwork';


const InternetDetector:React.FC = (props:any) => {
    const {isOnline} = useNetwork()

    if(isOnline) {
        return props.children
    }

    return(
        <div className={`min-w-screen min-h-screen flex justify-center items-center p-5 lg:p-20 overflow-hidden relative bg-gray-100`}>
            <div className="min-h-full w-full max-w-full md:max-w-xl lg:max-w-2xl 2xl:max-w-4xl rounded-none bg-white shadow-lg p-4 md:p-5 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/3 flex flex-col justify-between h-full">
                    <div className="flex flex-col pl-1 mb-4">
                        <h1 className={`text-3xl md:text-4xl font-bold text-yellow-300`}>Whoops!</h1>
                        <p className={'text-gray-400'}>No Internet Connection!</p>
                    </div>
                    <div>
                        <Button onClick={() => window.location.reload()} type={'default'}>Reload</Button>
                    </div>
                </div>
                <div className="w-full md:w-2/3">
                    <img src={'/img/no-internet.svg'} alt="" width="100%" height="auto"/>
                </div>
            </div>
        </div>
    )
}


export default InternetDetector