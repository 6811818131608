import React, { FC, useMemo, useState } from 'react'
import { Button, Form, Select, Slider } from 'antd'
import { FilterStyleClass, IEducationFilterItems } from './FilterHelper'
import VettedChart from './VettedChart'
import { saveEducationFilters } from '../../../services/SelectionService'

const EducationFilter:FC<IEducationFilterItems> = ({selectionId, result, filter, onCancel, onSave, items, rawData}) => {

    const { Option } = Select;
    const [saveLoading, setSaveLoading] = useState(false)

    const getDefaultValues = useMemo(() => {
        return {
            gpa: rawData.skills !== null ? +rawData.gpa : 2,
            fieldOfStudies: rawData.field_of_study !== null ? rawData.field_of_study : [],
            skills:  rawData.skills !== null ? rawData.skills : [],
            universities: rawData.university !== null ? rawData.university : []
        }
    }, [rawData])

    const marks = {
        0: '0',
        4: '4'
    }

    const onSubmit = (values: any) => {
        setSaveLoading(true)
        saveEducationFilters(selectionId, {
            gpa: values.gpa,
            field_of_study: values.fieldOfStudies,
            university: values.universities,
            skills: values.skills
        }).then((res) =>{
            onSave()
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setSaveLoading(false)
        })
    }

    return(
        <div className={FilterStyleClass.item.root}>

            <section className={'flex-1 flex-col p-1 mt-4'}>
                <p className={FilterStyleClass.item.title}>{filter.title} (Selected)</p>
                <Form
                    name="addJob"
                    onFinish={onSubmit}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-2'}
                    layout={'vertical'}
                    initialValues={getDefaultValues}
                >
                 <p className={FilterStyleClass.item.subTitle}>GPA</p>
                 <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="gpa"
                >
                <Slider
                   marks={marks} 
                   min={0} 
                   max={4} 
                   step={0.25}
                   tooltipVisible
                />
                </Form.Item>
                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="universities"
                >
                    <Select
                        style={{ width: '100%', height: '100%' }}
                        placeholder="University"
                        listItemHeight={10} listHeight={250} 
                        maxLength={5}
                        allowClear
                        showSearch
                        mode={'multiple'}
                        >
                        {items.universities.map((cityName, i) => {
                            return  <Option key={i} value={cityName}>{cityName}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="fieldOfStudies"
                >
                    <Select
                        style={{ width: '100%', height: '100%' }}
                        placeholder="Field of Study"
                        listItemHeight={10} listHeight={250} 
                        maxLength={5}
                        allowClear
                        showSearch
                        mode={'multiple'}
                        >
                        {items.fieldOfStudies.map((field, i) => {
                            return  <Option key={i} value={field}>{field}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="skills"
                >
                    <Select
                        style={{ width: '100%', height: '100%' }}
                        placeholder="Skills"
                        listItemHeight={10} 
                        listHeight={250} 
                        maxLength={5}
                        allowClear
                        showSearch
                        mode={'multiple'}
                        >
                        {items?.skills?.map((skill, i) => {
                            return  <Option key={i} value={skill}>{skill}</Option>
                        })} 
                    </Select>
                </Form.Item>
                <div className={'flex gap-x-4'}>
                    <Button size={'small'} onClick={onCancel} type={'default'}>Go back</Button>
                    <Button size={'small'} loading={saveLoading} disabled={saveLoading} htmlType='submit' type={'primary'}>Save</Button>
                </div>
             </Form>
            </section>

            <section className={FilterStyleClass.item.rightSection}>
                <VettedChart selected={result.selected} total={result.total} />
            </section>
        </div>
    )
}

export default EducationFilter