import {Form, Input } from "antd";
import React, { FC, useState } from "react";
import Modal from "../../components/base/Modal";
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import { useForm } from "antd/lib/form/Form";
import useAlert from "../../components/toast/useAlert";
import axios from "axios";
import { baseAddress } from "../../services/Request";

interface IUpdatePassword {
    onCancel: () => void
    onSubmit: () => void
}

const UpdatePassword:FC<IUpdatePassword> = ({onCancel, onSubmit}) => {

    const [loading, setLoading] = useState(false)
    const [form] = useForm()
    const {addAlert} = useAlert()
    
    const onSubmitModal = () => {
        form.submit()
    }

    const onFinish = (values:any)=> {
        if (values.password !== values.confirmpassword) {
            addAlert({type: 'error', title: 'Password Incorrect', description: 'Password doesnot match', autoClose:true})
            return;
        }
        setLoading(true)
        if(values.password && values.confirmpassword && values.password === values.confirmpassword) {
            setLoading(true)
            axios.post(baseAddress + '/users/set_password/', {
                current_password: values.currentpassword,
                new_password: values.confirmpassword,
             }, {timeout: 5000, timeoutErrorMessage: 'Request timed out'})
             .then((response:any) => {
                addAlert({type: 'success', title: 'success', description: 'Password changed successfully!', autoClose:true})
                onSubmit()
             }).catch((err: any) => {
                console.log(err)
                addAlert({type: 'error', title: 'error', description: 'Update password failed. Try again later', autoClose:true})
            }).finally(() => {
                setLoading(false);
             })
        }
    }

    return(<Modal 
                title={'Update Current Password'} 
                onCancel={onCancel} 
                onSubmit={onSubmitModal}
                submitDisabled={loading}
                >
                    <Form
                    form={form}
                    name="password-reset"
                    onFinish={onFinish}
                    autoComplete="off"
                    className={'w-full'}
                    >    
                    <Form.Item
                        className={'mb-4 mt-4'}
                        name="currentpassword"
                        rules={[{ required: true, message: 'Please enter your current password!'}]}
                    >
                        <Input.Password placeholder={'current password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>

                    <Form.Item
                        className={'mb-4 mt-4'}
                        name="password"
                        rules={[{ required: true, message: 'Please confirm your password!'},
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Password should be at least 8 characters!'));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || isNaN(Number(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Password can't be all numeric!"));
                            },
                        })
                        ]}
                    >
                        <Input.Password placeholder={'new password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>
    
                    <Form.Item
                        name="confirmpassword"
                        rules={[{ required: true, message: 'Please confirm your password!'},
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.length >= 8) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Password should be at least 8 characters!'));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || isNaN(Number(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Password can't be all numeric!"));
                            },
                        }),
                        ({getFieldValue }) => ({
                            validator(_, value) {
                              if (!value ||(getFieldValue('password') === getFieldValue('confirmpassword'))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Passwords must match!"));
                            },
                        }),
                        ]}
                    >
                        <Input.Password placeholder={'confirm new password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                    </Form.Item>

                </Form>
            </Modal>)
}

export default UpdatePassword