import React, { useEffect, useState } from 'react'
import BellOutlined from '@ant-design/icons/lib/icons/BellOutlined'
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined'
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined'
import { classNames } from '../../Utils'
import { getNotifications, markNotificationRead } from '../../services/UserService'
import { INotification } from '../../types'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import CloseClickOutside from '../base/ClickCloseOutside'

const Notifications = ({userId}: {userId: number}) => {
    
    const styleClass = {
        root: classNames(
            'w-full',
            'flex',
            'flex-col',
            'gap-y-4',
            'relative'
        ),
        list: classNames(
            'absolute',
            'top-8',
            'right-0',
            'w-max',
            'min-w-[200px]',
            'max-w-[min(300px,_80vw)]',
            'h-max',
            'max-h-[70vh]',
            'overflow-y-auto',
            'rounded-md',
            'flex',
            'flex-col',
            'pt-4',
            'bg-white',
            'z-[60]',
            'shadow-md'
        )
    }

    const [showList, setShowList] = useState(false)
    const [reload, setReload] = useState(false)
    const [notifications, setNotifications] = useState<INotification[]>([])
    const [unSeenNotifs, setUnseenNotifs] = useState(0)
    const Navigate = useNavigate()

    useEffect(()=> {
        getNotifications().then((res) => {
            setNotifications(res?.data)
            setUnseenNotifs(res?.data?.filter((item: INotification) => item.seen === false).length ?? [])
        }).catch(err => {
            console.log(err)
        })
    }, [userId, reload])

    const markAsRead = (notif: INotification, e?:any) => {
        e?.preventDefault()
        e?.stopPropagation()
        markNotificationRead(notif.id, {
            ...notif,
            seen: true
        }).then(() => {
            setReload(prev => !prev)
        })
    }

    const onItemClick = (notification: INotification) => {
        if(notification.job) {
            markAsRead(notification)
            Navigate(`/dashboard/jobs/${notification.job}`)
            setShowList(false)
        }
    }

    const onToggleShowList = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
       setShowList(prev => !prev)
    }
    
    return (
       
        <div className={styleClass.root}>
            <div onClick={onToggleShowList}>
                <BellOutlined className={'text-2xl text-gray-600 font-semibold ml-2 flex items-center'} />
                {unSeenNotifs > 0 && <span className={'z-[10] absolute top-[-8px] right-0 -mr-1 w-5 h-5 tex-xss p-0.5 rounded-full bg-red-500 text-white flex justify-center items-center'}>{unSeenNotifs}</span>}
            </div>
            {showList && 
                <div className={styleClass.list}>
                <CloseClickOutside 
                    onClose={() => setShowList(false)} >
                    <>
                    <div className={'flex items-center justify-between px-4'}>
                        <p className={'text-gray-500 font-medium'}>Notifications</p>
                    </div>
                    <div className={'h-full flex flex-col overflow-y-auto px-4'}>
                        {notifications.map((item: INotification, key) => 
                            <div key={key} onClick={() => onItemClick(item)} className={`${item.seen ? 'opacity-50': ''} hover:bg-slate-50 p-2 pb-4 w-full border-0 border-t border-gray-200 border-solid flex gap-x-4 text-xs`}>
                                <InfoCircleOutlined className={'text-xl flex h-full items-center'} />
                                <div className={'flex flex-col flex-auto'}>
                                    <p className={'font-semibold text-gray-600'}>{`${item.notification_type.type} for ${item.job_title}`}</p>
                                    <p className={'text-gray-500 font-light'}>{item.notification_type.description}</p>
                                </div>
                                {!item.seen && 
                                    <Tooltip title="click to mark as read">
                                        <CheckOutlined title='' onClick={(e) => markAsRead(item,e)} className={'w-8 cursor-pointer text-blue-400'} />
                                    </Tooltip>
                                }
                            </div>
                        )}
                    </div>
                    </>
                </CloseClickOutside>
                </div>
            }
        </div>
       
    )
}

export default Notifications