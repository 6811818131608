import { Button, Card, Checkbox, DatePicker, Form, Input, InputNumber, Select } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import FWSubHeader from "../../components/base/FWSubHeader";
import Page from "../../components/base/Page";
import PageSpinner from "../../components/base/PageSpinner";
import useAlert from "../../components/toast/useAlert";
import { createJob, editJob, getJob } from "../../services/JobService";
import { AccountantDuties, AllFieldsOfStudy, BusinessSkills, EthiopianCities, EthiopianLanguages, genderTypes, IJob } from "../../types";
import { isEmpty } from "../../Utils";

interface ICreateJob {
    isEditing?: boolean;
}

const CreateJob = ({isEditing=false}:ICreateJob)=> {
    const navigate = useNavigate();
    const [duties] = useState<string[]>(AccountantDuties);
    const [skills] = useState<string[]>(BusinessSkills);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasHired, setHasHired] = useState<boolean>(false);
    const [jobLoading, setJobLoading] = useState<boolean>(false);  
    const [job, setJob] = useState<IJob>({} as IJob);
    const [isNegotiable, setIsNegotiable] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false)

    const [form] = Form.useForm();
    const {addAlert} = useAlert();
    const {jobid} = useParams();

    useEffect(()=> {
        if(jobid && isEditing) {
            setLoading(true)
            getJob(+jobid).then(res => {
                setJob(res?.data)
                setHasHired(res?.data?.has_hired)
                setIsNegotiable(+res?.data?.salary === 0)
            }).catch(err => console.log(err))
            .finally(()=> {setLoading(false)});
        }
    }, [jobid,isEditing])
    
    const getJobIntialValues =()=> {
        if(!isEmpty(job) && isEditing) {
            const {title, description, deadline, salary, number_of_positions, field_of_study, experience, employment_type, length_of_employment, location, skills, responsibilities, requirements, level_of_study, stage, has_hired, gender, max_age, languages}:IJob = job; 
            return {title, description, deadline:moment(deadline), salary, 
                    number_of_positions, field_of_study, experience, employment_type, 
                    length_of_employment, location, 
                    skills: (skills && skills?.length > 0) ? skills?.split(',') : [], 
                    responsibilities: (responsibilities && responsibilities.length >0) ? responsibilities : [], 
                    requirements, level_of_study,
                    stage,
                    has_hired,
                    languages,
                    max_age, 
                    gender
                };
        }
        return {};
    }

    const getJobData = (values: any) => {
        const {title, description, deadline, salary, number_of_positions, field_of_study, experience, employment_type, length_of_employment, location, skills, responsibilities, requirements, level_of_study, stage, languages, years_of_experience, gender, max_age } = values; 

        return {
            title,
            description,
            requirements,
            salary: isNegotiable ? 0 : salary,
            experience,
            number_of_positions,
            location,
            level_of_study,
            field_of_study: Array.isArray(field_of_study) ? [...field_of_study] : [field_of_study],
            employment_type,
            length_of_employment,
            skills: skills?.join(','),
            deadline: deadline ? moment(deadline).format('YYYY-MM-DD') : null,
            responsibilities: responsibilities,
            stage: isEditing ? stage : 0,
            has_hired: isEditing ? hasHired : false,
            languages: languages ?? [],
            gender: gender === 'Male' ? 'M' : gender === 'Female' ? 'F' : undefined as 'M' | 'F' | undefined,
            max_age,
            years_of_experience,
        }
    }

    const onDraftSave = () => {
       const draftJob:IJob = getJobData(form.getFieldsValue())
        if(!isEditing) {
            form.validateFields(['title', 'deadline', 'requirements']).then(() => {
                    setJobLoading(true);
                    createJob(draftJob).then(res => {
                        addAlert({type: 'success', title: 'Draft save Success', description: 'Job saved successfully!', autoClose: true})
                        navigate('/dashboard')
                    }).catch(err => {
                        addAlert({type: 'error', title: 'Draft saving Failed', description: JSON.stringify(err?.response?.data), autoClose: false})
                    }).finally(() => {
                        setJobLoading(false);
                    })
            }).catch(err => console.log('err'))
        } else if(job.id) {
            form.validateFields(['title', 'deadline', 'requirements']).then(() => {
                setJobLoading(true);
                editJob({id: job?.id, ...draftJob}).then(res => {
                    addAlert({type: 'success', title: 'Draft save Success', description: 'Job saved successfully!', autoClose: true})
                    navigate(`/dashboard/jobs/${job?.id}`)
                }).catch(err => {
                    addAlert({type: 'error', title: 'Draft saving Failed', description: JSON.stringify(err?.response?.data), autoClose: false})
                }).finally(() => {
                    setJobLoading(false);
                })
            }).catch(err => console.log('err'))
        }
       
    }

    const onSubmit =(values:any)=> {
        const newJob:IJob = getJobData(values)
        if(!isEditing) {
            form.validateFields().then(() => {
                setJobLoading(true);
                Object.assign(newJob, {is_draft: false});   
                createJob(newJob).then(res => {
                    addAlert({type: 'success', title: 'Creation Success', description: 'Job created successfully!', autoClose: true})
                    navigate('/dashboard')
                }).catch(err => {
                    addAlert({type: 'error', title: 'Creation Failed', description: JSON.stringify(err?.response?.data), autoClose: false})
                }).finally(() => {
                    setJobLoading(false);
                })
              }).catch(err => console.log('err', err))
        } else {
            Object.assign(newJob, {id: job?.id, is_draft: false});
            form.validateFields(['title', 'deadline', 'requirements', 'employment_type', 'number_of_positions', 'length_of_employment', 'location' ]).then(() => {    
            setJobLoading(true);
            editJob(newJob).then(res => {
                addAlert({type: 'success', title: 'Edit Success', description: 'Job edited successfully!', autoClose: true})
                navigate(`/dashboard/jobs/${job?.id}`)
            }).catch(err => {
                addAlert({type: 'error', title: 'Edit job Failed', description: JSON.stringify(err?.response?.data), autoClose: false})
            }).finally(() => {
                setJobLoading(false);
            })})
        } 
    }
    
    const { Option } = Select;
    const { TextArea } = Input;

    const getDisabledDate = (currentDate: moment.Moment) => {
      if(currentDate.isSameOrBefore(moment())) {
        return true
      } 
      return false
    }

    const isDraft = useMemo(() => {
        if(isEditing) {
            return job?.is_draft ?? false
        }
    }, [isEditing, job])
    
    return(
        <Page>
        <FWSubHeader title={isEditing ? `Editing Job: ${job?.title ?? ''}` : 'Add New Job Post'} />
        <div className={'h-auto'}>
        {!loading ? <Card className={'w-full lg:w-5/6 max-w-screen-lg md:mr-0 h-mx-content p-2 rounded-lg shadow-md'}>
            <Form
                name="addJob"
                onFinish={onSubmit}
                autoComplete="off"
                className={'flex flex-col gap-1 md:grid grid-cols-1 md:grid-cols-3 md:gap-x-5 auto-cols-auto'}
                form={form}
                initialValues={getJobIntialValues()}
                layout={'vertical'}
            >
                <Form.Item 
                    className={'col-span-1 md:col-span-2'}
                    name="title"
                    rules={[{ required: true, message: 'Please input job title!' }]}
                >
                    <Input placeholder={'Title'} />
                </Form.Item>
                
                <Form.Item 
                    className={'col-span-1'}
                    name="deadline"
                    rules={[{ required: true, message: 'Please input deadline!' }]}
                >
                    <DatePicker disabledDate={getDisabledDate} placeholder={'Application deadline'} className={'w-full'} />
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="description"
                >
                    <TextArea placeholder={'Job description'}  rows={3} />
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="requirements"
                    rules={[{ required: true, message: 'Please input job requirements!' }]}
                >
                    <TextArea placeholder={'Job Requirements'}  rows={5} />
                </Form.Item>

                <div className={'col-span-1 flex items-center justify-between gap-x-4'}>
                    <Form.Item 
                        className={'w-full md:w-1/2'}
                        name="salary"
                    >
                        <InputNumber disabled={isNegotiable} placeholder={'Salary'} className={'w-full'}/>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox defaultChecked={isNegotiable} checked={isNegotiable} onChange={e => setIsNegotiable(e.target.checked)}>Company Scale</Checkbox>
                    </Form.Item>
                </div>

                <Form.Item 
                    className={'col-span-1'}
                    name="number_of_positions"
                    rules={[{ required: true, message: 'Please input no of positions!' }]}
                >
                    <InputNumber placeholder={'Number of positions'} className={'w-full'}/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="level_of_study"
                >
                    <Select
                        showSearch
                        placeholder="Level of Study"
                        filterOption={(input, option) =>
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value={0}>Not Applicable</Option>
                        <Option value={6}>TVET</Option>
                        <Option value={1}>HighSchool Graduate</Option>
                        <Option value={2}>Diploma</Option>
                        <Option value={3}>Bachelors</Option>
                        <Option value={4}>Masters</Option>
                        <Option value={5}>PhD</Option>
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="field_of_study"
                >
                    <Select
                        mode={'tags'}
                        allowClear
                        showSearch
                        placeholder="Field of study"
                        filterOption={(input, option) => 
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {AllFieldsOfStudy.map((field, i) => {
                            return  <Option key={i} value={field.toLowerCase()}>{field}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    name="experience"
                    className={'col-span-1'}
                >
                    <Select
                        allowClear
                        showSearch
                        className={''}
                        placeholder="Level of position"
                        filterOption={(input, option) =>
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value={0}>Intern</Option>
                        <Option value={1}>Fresh Graduate</Option>
                        <Option value={2}>Junior</Option>
                        <Option value={3}>Mid Level</Option>
                        <Option value={4}>Senior Level</Option>
                        <Option value={5}>Director</Option>
                        <Option value={6}>Executive</Option>
                        </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="employment_type"
                    rules={[{ required: true, message: 'Please input employment type!' }]}
                >
                    <Select
                        showSearch
                        className={''}
                        placeholder="Employement Type"
                        filterOption={(input, option) =>
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value={0}>Full time</Option>
                        <Option value={1}>Part time</Option>
                        <Option value={2}>Freelance</Option>
                        </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="length_of_employment"
                    rules={[{ required: true, message: 'Please input length of hire!' }]}
                >
                    <Select
                        showSearch
                        className={'col-span-1'}
                        placeholder="Select Length of Hire"
                        filterOption={(input, option) =>
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value={0}>Permanent</Option>
                        <Option value={1}>Contract</Option>
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="location"
                    rules={[{ required: true, message: 'Please choose place of work!'}]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%', height: '100%' }}
                        placeholder="Place of work"
                        allowClear
                        showSearch
                        >
                        {EthiopianCities.map((cityName, i) => {
                            return  <Option key={i} value={cityName.toLowerCase()}>{cityName}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="country"
                >
                    <Input disabled={true} value={'Ethiopia'} placeholder={'Ethiopia'} />
                </Form.Item>

                <Form.Item 
                        className={'col-span-1'}
                        name="years_of_experience"
                    >
                      <InputNumber placeholder={'Total Years of experience'} className={'w-full'}/>
                </Form.Item>
                    
                <Form.Item 
                    className={'col-span-1 md:col-span-2 row-span-2'}
                    name="skills"
                >
                    <Select
                        mode="tags"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select skills"
                        filterOption={(input, option) => 
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {skills.map((item,i) => (
                            <Option key={i} value={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item>
                
                <Form.Item 
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        name="responsibilities"
                    >
                    <Select
                        mode={'tags'}
                        allowClear
                        placeholder="List of duties and responsibilities"
                        filterOption={(input, option) => 
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                         {duties.map((field, i) => {
                            return  <Option key={i} value={field.toLowerCase()}>{field}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item className={'col-span-1 md:col-span-3 my-0'}>
                    <Checkbox defaultChecked={showMore} checked={showMore} onChange={e => setShowMore(e.target.checked)}>Show more options</Checkbox>
                </Form.Item>
                
                {showMore && <>
                <Form.Item 
                        className={'col-span-1'}
                        name="max_age"
                    >
                      <InputNumber placeholder={'Max Eligible Age'} className={'w-full'}/>
                </Form.Item>

                <Form.Item 
                        className={'col-span-1'}
                        name="gender"
                    >
                    <Select
                        allowClear
                        placeholder="Gender"
                        filterOption={(input, option) => 
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                         {genderTypes.map((field, i) => {
                            return  <Option key={i} value={field}>{field}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                        className={'col-span-1'}
                        name="languages"
                    >
                    <Select
                        mode={'tags'}
                        allowClear
                        placeholder="Required Languages"
                        filterOption={(input, option) => 
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                         {EthiopianLanguages.map((field, i) => {
                            return  <Option key={i} value={field.toLowerCase()}>{field}</Option>
                        })} 
                    </Select>
                </Form.Item>
                </>}
                
                <div className={'col-span-1 md:col-span-3 flex flex-col gap-4 md:grid gap-x-2 grid-flow-col col-start-3 justify-self-end'}>
                    <Button onClick={() => navigate(-1)} block type="default">Cancel</Button>
                    <Button loading={jobLoading} disabled={jobLoading}  block type="primary" onClick={onDraftSave}>{'SaveDraft'}</Button>
                    <Button loading={jobLoading} disabled={jobLoading}  block type="primary" htmlType="submit">{isEditing ? isDraft ? 'Publish' : 'Update' : 'Publish'}</Button>
                </div>
               
            </Form>
        </Card> : <PageSpinner />}
        </div>
        </Page>
    )
} 

export default CreateJob;