import { Button } from "antd";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import Chip from "../../../components/base/Chip";
import { getMotivation, getMotivationAnswers } from "../../../services/MotivationService";
import { IMotivation, ISelection } from "../../../types";
import MotivationComplete from "./MotivationComplete";
import MotivationEvaluationModal from "./MotivationEvaluationModal";

interface IMotivationWagonModal {
    selection: ISelection
    reloadParent: () => void
}

const MotivationWagonDetail:FC<IMotivationWagonModal> = ({selection, reloadParent}) => {

    const [showMotivationEvaluation, setMotivationEvaluation] = useState(false)
    const [finishMotivation, setFinishMotivation] = useState(false)
    const [motivation, setMotivation] = useState<IMotivation>()

    useEffect(() => {
        getMotivation(selection.id).then(res => setMotivation(res?.data[0]))
        getMotivationAnswers(selection.id).then(res => {
            const numberOfUsers = res?.data?.length ?? 0
            const allAnswers = [...res?.data.map((item: any) => item.answers)]
            const isAllRated = !!allAnswers.find(item => item.rating === null)
            if(numberOfUsers === motivation?.received_results && isAllRated) {
                console.log('RATING IS DONE!!!!')
            }
        })
    }, [motivation?.received_results, selection])

    const onSubmitEvaluation = () => {
        setMotivationEvaluation(false)
        reloadParent()
    }

    const onSubmitFinishModal = () => {
        setFinishMotivation(false)
        reloadParent()
    }

    const isDeadlinePassed = useMemo(() => {
        return moment(motivation?.deadline).isBefore(moment(), 'day')   
    }, [motivation])

    return(
        <div className={'flex flex-col gap-1 text-gray-500 mt-4 ml-2'}>
            <p className={'text-lg font-bold'}>Current Step: <span className={'text-fw-orange'}>Motivation</span> </p>
            {motivation?.is_released &&
                <>
                    <p className={'text-sm font-medium'}>Motivation Questions Has beeen Successfully created and released</p>
                    <p className={'text-sm font-medium flex items-center gap-2'}>Candidates are allowed to put their answers until
                        <Chip textColor={'white'} bgColor={'green'} className={'w-max'} val={moment(motivation.deadline).format('LL')} />
                    </p>
                    <p className={'text-sm font-medium flex items-center gap-2'}>Current received results/Total
                        <Chip textColor={'white'} bgColor={'green'} className={'w-9'} val={`${motivation.received_results}/${motivation.total_applicants}`} />
                    </p>
                </>
            }
            <p className={'text-base font-medium mt-2 text-fw-orange'}>Available Operations</p>
            <Button onClick={() => setMotivationEvaluation(true)} disabled={!isDeadlinePassed} type={'primary'} className={'w-max'} >Evaluate Motivation Answers</Button>
            <Button onClick={() => setFinishMotivation(true)} disabled={!isDeadlinePassed} type={'primary'} className={'w-max'} >Finish/Close Motivation Step</Button>
            {!isDeadlinePassed &&  <p className={'text-xs'}>Please note that You can only Evaluate Motivation Answers/Finish Step after the deadline has passed</p>}
    
            {showMotivationEvaluation && 
            <MotivationEvaluationModal
                selectionId={selection.id}
                onClose={() => setMotivationEvaluation(false)}
                onSubmit={onSubmitEvaluation}
            />}

            {finishMotivation && selection.id &&
            <MotivationComplete 
                selectionId={selection.id}
                onClose={() => setFinishMotivation(false)}
                onSubmit={onSubmitFinishModal}
            />}

        </div>
    )
}

export default MotivationWagonDetail