import { Form, Slider, InputNumber, Button } from 'antd'
import React, { FC, useMemo, useState } from 'react'
import { saveExperienceFilters } from '../../../services/SelectionService'
import { FilterStyleClass, IFilter } from './FilterHelper'
import VettedChart from './VettedChart'

const ExperienceFilter:FC<IFilter> = ({filter, onCancel, onSave, result, selectionId, rawData}) => {

    const marks = {
        0: '0',
        2: '2',
        5: '5',
        10: '10',
        20: '20'
    }

    const [saveLoading, setSaveLoading] = useState(false)
    const getDefaultValues = useMemo(() => {
        return {
            number_of_previous_jobs: rawData.number_of_previous_jobs !== null ? +rawData.number_of_previous_jobs : 0,
            years_of_experience: rawData.years_of_experience !== null ? +rawData.years_of_experience : 0,
        }
    }, [rawData])

    const onSubmit = (values: any) => {
        const {number_of_previous_jobs, years_of_experience} = values
        setSaveLoading(true)
        const body = {
            number_of_previous_jobs: number_of_previous_jobs,
            years_of_experience: years_of_experience
        }
        saveExperienceFilters(selectionId, body).then((res) =>{
            onSave()
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setSaveLoading(false)
        })
    }

    return(
        <div className={FilterStyleClass.item.root}>
            <section className={FilterStyleClass.item.leftSection}>
                <p className={FilterStyleClass.item.title}>
                {filter.title} (Selected)
                </p>
                <Form
                    name="addJob"
                    onFinish={onSubmit}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-4'}
                    initialValues={getDefaultValues}
                    layout={'vertical'}
                >
                 <p className={FilterStyleClass.item.subTitle}>Years of Experience</p>
                 <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="years_of_experience"
                >
                    <Slider
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        marks={marks} 
                        min={0} 
                        max={20} 
                        step={1}
                        tooltipVisible
                    />
                </Form.Item>
                <Form.Item 
                    className={'col-span-1 md:col-span-2 row-span-1'}
                    name="number_of_previous_jobs"
                    label={'Number of previous jobs'}
                >
                    <InputNumber className={'w-full'} defaultValue={0}  placeholder={'No of Prev Jobs'}/>
                </Form.Item>
                <div className={'flex gap-x-4 col-span-1 md:col-span-3 row-span-2'}>
                    <Button size={'small'} onClick={onCancel} type={'default'}>Cancel</Button>
                    <Button loading={saveLoading} disabled={saveLoading} size={'small'} htmlType='submit'  type={'primary'} >Save</Button>
                </div>
             </Form>
            </section>

            <section className={FilterStyleClass.item.rightSection}>
                <VettedChart selected={result.selected} total={result.total} />
            </section>
        </div>
    )
}

export default ExperienceFilter