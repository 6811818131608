import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { classNames } from '../../Utils'
import { Button, Popconfirm } from 'antd';

interface IModal {
    title: string
    subTitle?: string
    className?: string
    onCancel: () => void
    onSubmit: () => void
    onPrevious?: () => void
    submitDisabled?: boolean
    submitText?: string
    cancelText?: string
    hideCurve?: boolean
    resetScroll?: boolean
    requiresConfirmation?: boolean
    hideActionButtons?: boolean
    onCancelCustom? : ()=> void
}

const Modal:React.FC<IModal> = ({title, subTitle = '', className = '', children, onCancel, onSubmit, onPrevious, submitDisabled=false, submitText = '', cancelText, hideCurve=true, resetScroll=false, hideActionButtons=false, requiresConfirmation=false, onCancelCustom}) => {

    const styleClass = {
        overlay: classNames(
            'w-screen',
            'h-screen',
            'bg-gray-100',
            'fixed',
            'top-0',
            'left-0',
            'bg-fw-modal-gray',
            'flex',
            'justify-between',
            'items-center',
            'z-50',
            'px-12',
            'py-8'
        ),
        root: classNames(
            'rounded-md',
            'bg-white',
            'w-auto',
            'h-auto',
            'max-h-[90%]',
            'max-w-7xl',
            'min-h-[400px]',
            'min-w-[300px]',
            'mx-auto',
            'mt-[-3%]',
            'p-4',
            'overflow-hidden',
            className
        ),
        header: classNames(
            'flex',
            'justify-between',
            'mb-2'
        ),
        title: classNames(
            'text-lg',
            'text-gray-600',
            'font-semibold',
            'capitalize'
        ),
        subTitle: classNames(
            'text-sm',
            'text-gray-400',
            'font-light',
        ),
        body: classNames(
            'w-full',
            'h-full',
            'overflow-auto'
        ),
        footer: classNames(
            'flex',
            'justify-between',
            'items-end',
            'mt-auto',
            'w-full'
        ),
    }

    const quarterClass = {
        width: '60%',
        maxWidth: 620,
        height: 80,
        marginLeft: '-16px',
        marginBottom: '-16px',
        backgroundColor: '#68D3AC',
        borderBottomLeftRadius: 6,
        clipPath: 'ellipse(50% 100% at 18% 100%)'
    }

    const getRootClass = useMemo(() => ({
        display: 'grid',
        gridTemplateRows: hideActionButtons ? '48px 1fr' : hideCurve ? '48px 1fr 40px' : '48px 1fr 80px',
        gridTemplateColumns: '1fr'
    }), [hideCurve, hideActionButtons])

    const ref = useRef<HTMLDivElement>(null)
    const scrollRef = useRef<HTMLDivElement>(null)
    const handleOutsideClick = useCallback((e: MouseEvent) => {
        if (ref.current && e.target instanceof HTMLElement &&
        !ref.current.contains(e.target)
        ) {
            e.preventDefault()
            e.stopPropagation()
            // onCancel()
            //TODO:
            // DOn't close for now
        }
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
        document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [handleOutsideClick])

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0
        }
    }, [resetScroll])

    return(
        <div className={styleClass.overlay}>
                <div className={styleClass.root} ref={ref} style={getRootClass}>
                    <div className={styleClass.header}>
                        <div className={'flex flex-col'}>
                            <p className={styleClass.title}>{title}</p>
                            <p className={styleClass.subTitle}>{subTitle}</p>
                        </div>
                        <CloseOutlined className={'cursor-pointer'} onClick={() => onCancel()} />
                    </div>
                    <div ref={scrollRef} className={styleClass.body}>
                        {children}
                    </div>
                    <div className={styleClass.footer}>
                        {!hideCurve && <div style={quarterClass} />}
                        {!hideActionButtons && <div className={`flex gap-x-4 ${hideCurve ? 'mr-0 ml-auto' : ''}`}>
                            <Button onClick={onCancelCustom? onCancelCustom : onCancel} type={'default'}>{cancelText ? cancelText : 'Cancel'}</Button>
                            {onPrevious && <Button onClick={onPrevious} type={'default'}>Back</Button>}
                            {requiresConfirmation ?
                            <Popconfirm title="This Action can't be undone. Please confirm." onConfirm={onSubmit}>
                                <Button disabled={submitDisabled} type={'primary'}> {submitText ? submitText : 'Submit'} </Button>
                            </Popconfirm> :
                             <Button disabled={submitDisabled} onClick={onSubmit} type={'primary'}> {submitText ? submitText : 'Submit'} </Button>}
                        </div>}
                    </div>
                </div>
        </div>
    )
}

export default Modal