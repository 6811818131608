import Request from "./Request"

export interface TelegramSuccessResponse {
    auth_date: number
    first_name: string
    hash: string
    id: number
    last_name: string
    photo_url: string
    username: string
}

export const LogInUser = (body:{email:string, password:string}) => {
    return Request('POST', `/session/`, body);
} 

export const LogInWithTelegram = (body:TelegramSuccessResponse) => {
    // return Request('GET', `/auth/complete/telegram?id=${body.id}&first_name=${body.first_name}&username=${body.username}&photo_url=${body.photo_url}&auth_date=${body.auth_date}&hash=${body.hash}`);
    // return Request('GET', `/auth/complete/telegram/?id=${body.id}&first_name=${body.first_name}&auth_date=${body.auth_date}&hash=${body.hash}`);
    return fetch(`https://dev.fairway.work/auth/complete/telegram/?id=6210421645&first_name=Ermi&auth_date=1678461370&hash=e5e35bb5617eb13ef612bc78fcc5fbc498abf691f3b8f809040c89269e0cb82d`)
} 

