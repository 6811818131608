import EnvironmentOutlined from "@ant-design/icons/lib/icons/EnvironmentOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import MobileOutlined from "@ant-design/icons/lib/icons/MobileOutlined";
import LinkedinOutlined from '@ant-design/icons/lib/icons/LinkedinOutlined';
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import { Avatar, Button, Empty , Progress} from "antd";
import { useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { exportCVtoPDF, getFullCV } from "../../services/CVService";
import { AllLevelsOfStudy, ICV, ICVEducation, ICVProfile } from "../../types";
import moment from "moment";
import { arrayIsEmpty } from "../../Utils";
import { AllCountries, getProfileImgUrl, getProfileImgUrlFromItem } from "../../Utility";
import fileDownload from "js-file-download";
import useAlert from "../../components/toast/useAlert";
import Bullet from "../../components/base/Bullet";
import FWBadge from "../../components/base/FWBadge";
import PageSpinner from "../../components/base/PageSpinner";
import SectionSeparator from "../../components/base/SectionSeparator";
// import Verified from "../../components/base/Verified";
import Page from "../../components/base/Page";
import FWSubHeader from "../../components/base/FWSubHeader";
import useDevice from "../../hooks/useDevice";
import VerifyEducation from "../../components/cv/VerifyEducation";

interface ICVDetail {
    id?: number
    className?: string
}

const CVDetail:React.FC<ICVDetail> = ({id, className=''}) => {
    const { addAlert } = useAlert()
    const navigate = useNavigate();
    const {currentUser, cvId:userCVId} = useAuth();
    const {cvId } = useParams();
    const [cv, setCV] = useState<ICV>();
    const [cvProfile, setCVProfile] = useState<ICVProfile>();
    const [loading, setLoading] = useState<boolean>(false);
    const [cvIsEmpty, setCVIsEmpty] = useState<boolean>(false);
    const [cvIsPrintable, setCVIsPrintable] = useState<boolean>(false);
    const [allowEdit, setAllowEdit] = useState<boolean>(true);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false)
    const [selectedEducation, setSelectedEducation] = useState<ICVEducation | null>(null);
    const [showEducationVerification, setShowEducationVerification] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false);
    const {isMobile}  = useDevice()

    const onEditCV = ()=> {
        navigate('/dashboard/cv/edit/1')
    }

    useEffect(()=> {
        setAllowEdit(!currentUser?.is_staff ? true : false)
        const cvToFetch:number = id ? id : !currentUser?.is_staff ? userCVId : cvId ? +cvId : 0; 
        
        if(cvToFetch) { 
            setLoading(true)
            getFullCV(cvToFetch).then(res => {
                setCV(res?.data)
                if(res?.data?.profile?.length > 0) {
                    setCVProfile(res?.data?.profile[0] ?? [])
                    setCVIsPrintable(!arrayIsEmpty(cv?.education) && !arrayIsEmpty(cv?.profile))
                } else {
                    setCVIsEmpty(true)
                    setCVIsPrintable(false)
                }
            }).catch(err => console.log(err))
            .finally(()=> {setLoading(false)});
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCVId, cvId, currentUser, id, reload])

    // const onVerifyEducation = (edu: ICVEducation) => {
    //     setShowEducationVerification(true)
    //     setSelectedEducation(edu)
    // }

    const onDownloadPDF = async () => {
       setDownloadLoading(true)
       if(cv) {
           exportCVtoPDF(cv).then((file) => {
             const {first_name, last_name} = cv?.user?.profile
             const day = moment().format('DD_MM_YYYY')
             const fileName = `${first_name}_${last_name}_CV_${day}.pdf`
             fileDownload(file?.data, fileName)
           }).catch((ex) => {
                console.log(ex)    
                addAlert({type: 'error', title: 'Error', description: 'CV Export failed. Please try again later!', autoClose: true})
           }).finally(() => {
                setDownloadLoading(false)
           })
       } else {
            setDownloadLoading(false)
            addAlert({type: 'error', title: 'Error', description: 'CV Export is not avilable now. Please try again later!', autoClose: true})
       }
    }

    const getNotCompleteMessage =(section:string)=> {
        return `${currentUser?.is_staff ? 'The' : 'Your'} CV ${section} section is NOT complete... 🧾, ${!currentUser?.is_staff ? 'Please click on edit CV to add your cv' : ''}`
    }

    const fullName = useMemo(() => {
      return id ? '****** ****** (Anonymous)' : `${cvProfile?.first_name} ${cvProfile?.last_name}`
    }, [cvProfile, id])

    const languageProficiencyLevels = (level: number) =>{
        switch(level){
            case 0:
                return 'Not Applicable';
            case 1:
                return 'Beginner';
            case 2:
                return 'Intermediate';
            case 3:
                return 'Advanced';
            case 4:
                return 'Fluent';
            case 5:
                return 'Native/Bilingual';

        }
    }

    return(<Page className={id ? `md:pl-0 ${className}` : `${className}`}>
            {!id && <FWSubHeader title={'Curriculum Vitae'}>
                {allowEdit? <div className={`flex ${!isMobile ? 'mb-2' : ''}`}>
                    <Button size={isMobile ? 'small' : 'middle'} onClick={onEditCV} className={'flex items-center font-semibold bg-fw-green text-white text-xs rounded-2xl mr-2 md:mr-0 px-4 py-2 cursor-pointer whitespace-nowrap text-center'}>
                        <EditOutlined className={'mr-2'} /> Edit CV
                    </Button>
                    <Button size={isMobile ? 'small' : 'middle'} onClick={onDownloadPDF} disabled={!cvIsPrintable || downloadLoading} loading={downloadLoading} className={'rounded-full flex items-center ml-4 font-semibold'} type={'primary'}>
                        <DownloadOutlined /> Download PDF
                    </Button>
                </div> : 
                <span onClick={() => navigate(-1)} className={'flex items-center cursor-pointer'}>
                    <ArrowLeftOutlined className={'mr-2 text-fw-orange font-medium scale-150'} />
                    <p className={'text-xs text-gray-600'}>Go Back</p>
                </span>
                }
            </FWSubHeader>}

            {cvId && cv?.profile[0].first_name && <Button size={isMobile ? 'small' : 'middle'} onClick={onDownloadPDF} disabled={!cvIsPrintable || downloadLoading} loading={downloadLoading} className={'rounded-full flex items-center ml-4 font-semibold'} type={'primary'}>
                <DownloadOutlined /> Download PDF
            </Button>}

            <div className={`bg-white mt-2 shadow rounded-xl py-4 w-auto xl:max-w-5xl flex flex-col ${id ? 'ml-0 md:ml-0' : ''}`}>
                {loading ? <PageSpinner /> :
                cvIsEmpty ? 
                <Empty className={'mb-4'} description={`${currentUser?.is_staff ? 'The' : 'Your'} CV is empty...🧾, ${!currentUser?.is_staff ? 'Please click on edit CV to add your cv' : ''}`} /> :
                // All sections
                <section>
                {!cvProfile ?
                <Empty className={'mb-4'} description={getNotCompleteMessage('Profile')} /> :
                <>
                <section className={'w-full flex flex-col md:flex-row gap-x-6 md:gap-x-8 px-6 mb-4 md:mb-6'}>
                    <Avatar src={cvId ? getProfileImgUrlFromItem({cv: cv}) : getProfileImgUrl(currentUser)} className={'rounded-md'} shape={'square'} size={120} style={{minWidth:120}}/>    
                    <div className={'flex flex-col'}>
                        <div className={'text-2xl text-fw-orange font-semibold flex items-center pb-1'}>{fullName}
                           {/* {cv?.user?.profile?.photo && <Verified hoverText={'Identity verified'} />} */}
                        </div>
                        <p className={'text-gray-700 font-medium pb-1'}>{cvProfile?.title}</p>
                        <div className={'text-gray-600 text-sm font-light max-w-xl clip-3-lines'}>
                           {cvProfile?.bio}
                        </div>
                    </div>
                </section>

                {/* Contact */}
                <section className={'w-full px-6 py-3 bg-fw-cv-gray text-gray-500 font-medium grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-5 lg:gap-6 mb-6'}>
                    {id ? <p className={'col-span-2'}>Contact is not available at this level</p> :
                    <>
                        <div className={'flex items-center'}>
                            <MailOutlined className={'mr-2 transform scale-125 text-fw-orange font-thin'} />
                            {cv?.user?.email}
                        </div>
                        <div className={'flex items-center'}>
                            <MobileOutlined className={'mr-2 transform scale-125 text-fw-orange font-thin'} />
                            {cvProfile?.phone_number}
                        </div>
                        <div className={'flex items-center capitalize'}>
                            <EnvironmentOutlined className={'mr-2 transform scale-125 text-fw-orange font-thin'} />
                            {`${cvProfile?.city}, ${AllCountries.find(country => country.code === cvProfile.country)?.name ?? cvProfile?.country}`}
                        </div>
                        <div className={'flex items-center text-xs'}>
                            <LinkedinOutlined className={'mr-2 transform scale-125 text-fw-orange font-thin'} />
                            {cvProfile?.socials && cvProfile?.socials.length > 0 && cvProfile?.socials[0].split('/').slice(4) }
                        </div>
                    </>}
                </section>
                
                {/* Skills */}
                <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Skills' />
                    <div className={'flex flex-wrap gap-x-3 gap-y-2'}>
                        {cvProfile?.skills.map((skill:string,i) =>
                            <FWBadge key={i} text={skill} textClass={'text-white text-base transform scale-90 lg:scale-95 font-medium'} className={'bg-fw-blue-light whitespace-nowrap py-1 px-3 text-capitalize'} />
                        )}
                    </div>
                </section>
                </>}
                
                {/* Experience */}
                {cv?.experience && cv.experience.length >0 &&
                <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Work Experience' />
                    {cv?.experience && cv?.experience?.map((experience, key) => 
                    (<div key={key} className={'mt-1 ml-4'}>
                    <p className={'text-base text-gray-900 font-semibold flex items-center'}>{experience.title} <span> 
                        </span> </p>
                        <div className={'flex justify-between flex-wrap text-gray-500'}>
                            <p className={'text-sm'}><b>{experience.company_name}</b> | {moment(experience?.start_date)?.format('MMM YYYY') ?? ''} - {experience?.end_date ? moment(experience?.end_date)?.format('MMM YYYY') : 'Present'}</p>
                            <span className={'inline-flex items-center'} >
                                <EnvironmentOutlined className={'mr-2 font-thin'} /> {`${experience?.company_city ?? ''} ${experience?.company_country ?? ''}`}
                            </span>
                        </div>
                        <div className={'flex justify-between flex-wrap mb-2 text-gray-500'}>
                            <p className={'text-xs'}><b>{experience?.reference_name}</b> | {experience?.reference_email} (Reference)</p>
                            <p className={'text-xs'}>{experience?.reference_phone}</p>
                        </div>
                    {experience.achievements?.map((duty:string,j:number) => <Bullet key={j} text={duty} /> )}
                    {/* <details className={'pl-2'}>
                                <summary className={'text-gray-500 '}>Prism Details</summary>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Holder DID</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                            message.info('Information copied to clipboard');
                                        }} />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Issuer DID</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                            message.info('Information copied to clipboard');
                                        }} />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Hash Proof</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                                navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                                message.info('Information copied to clipboard');
                                            }
                                        } />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Encoded Credential</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center text-gray-500 break-all'}>
                                        <p className={'text-gray-500'}>eyJpZCI6ImRpZDpwcmlzbTo5ODFlMmNmNjNiZTYxYmM1NTU3NjZkNzFjY2JjYjc5YWNiYWQ1OTU2ZGUxYzUyMGYzOGVlMzcwO...v2QicYntggzteTM7AgAcmuVn7fx</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('eyJpZCI6ImRpZDpwcmlzbTo5ODFlMmNmNjNiZTYxYmM1NTU3NjZkNzFjY2JjYjc5YWNiYWQ1OTU2ZGUxYzUyMGYzOGVlMzcwO...v2QicYntggzteTM7AgAcmuVn7fx')
                                            message.info('Information copied to clipboard');  
                                        }} />
                                    </div>
                                </div>
                            </details> */}
                        </div>)
                    )}
                </section>}

                {/* Education */}
                {arrayIsEmpty(cv?.education) ?
                <Empty className={'mb-4'} description={getNotCompleteMessage('Education')} /> :
                <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Education' /> 
                    {cv?.education && cv?.education.length > 0 ? cv.education.map((education, key) => 
                    (<div key={key} className={'my-1 ml-4'}>
                        <p className={'text-base text-gray-900 font-semibold flex items-center'}>{education?.field_of_study} 
                            {education?.level_of_study > 0 && <span className={'text-sm text-gray-500 font-semibold pl-2'}>| {AllLevelsOfStudy[education?.level_of_study]}</span>}
                            {/* {education.is_verified ? <Verified hoverText={'This Credential is Verified'} /> :
                            <Button type={'default'} className={'ml-2'} size={'small'} onClick={() => onVerifyEducation(education)}>Verify</Button>} */}
                        </p>
                        <div className={'grid grid-cols-1 sm:grid-cols-6 text-gray-500 items-start'}>
                            <div className={'flex flex-col col-span-1 sm:col-span-3'}>
                                <p className={'text-sm'}><b>{education?.institute_name}</b> | 
                                {education?.institute_website && <a target={'_blank'} rel={'noReferrer'} href={education?.institute_website} className={'text-xs underline pl-1'}>{education?.institute_website}</a>}
                                </p>
                                <p className={'text-sm'}>{moment(education?.start_date)?.format('MMM YYYY') ?? ''} - {education?.end_date ? moment(education?.end_date)?.format('MMM YYYY') : 'Present'}</p>
                                <p className={'text-sm'}>
                                    {education?.major && <span className={'pr-2'}>Major: {education?.major}</span>}
                                    {education?.minor && <span>Minor: {education?.minor}</span>}
                                </p>
                            </div>

                            {education?.gpa && <p className={'text-sm sm:justify-self-center'}><b>GPA:{education?.gpa}</b>{education?.gpa && education?.gpa_out_of && ' out of '}{education?.gpa_out_of}</p>}
                            <span className={'inline-flex items-center col-span-1 sm:col-span-2 sm:justify-self-end'} >
                                <EnvironmentOutlined className={'mr-2 font-thin'} />{`${education?.institute_city ?? 'Anywhere'}, ${education?.institute_country ?? ''}`} 
                            </span>
                        </div>
                        {/* {<details className={'pl-2'}>
                                <summary className={'text-gray-500 '}>Prism Details</summary>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Holder DID</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                            message.info('Information copied to clipboard');
                                        }} />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Issuer DID</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                            message.info('Information copied to clipboard');
                                        }} />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Hash Proof</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center'}>
                                        <p className={'text-gray-500'}>did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                                navigator.clipboard.writeText('did:prism:558df0c902d6a14dcfb97a6...94saYLwQ6ki8')
                                                message.info('Information copied to clipboard');
                                            }
                                        } />
                                    </div>
                                </div>
                                <div className={'flex max-w-xl w-auto items-center justify-between mb-2'}>
                                    <p className={'w-[30%] text-gray-700 font-bold'}>Encoded Credential</p>
                                    <div className={'w-[70%] border border-gray-400 border-solid flex gap-2 rounded-md px-2 items-center text-gray-500 break-all'}>
                                        <p className={'text-gray-500'}>eyJpZCI6ImRpZDpwcmlzbTo5ODFlMmNmNjNiZTYxYmM1NTU3NjZkNzFjY2JjYjc5YWNiYWQ1OTU2ZGUxYzUyMGYzOGVlMzcwO...v2QicYntggzteTM7AgAcmuVn7fx</p>
                                        <CopyOutlined className={'text-gray-500 cursor-pointer'} onClick={() => {
                                            navigator.clipboard.writeText('eyJpZCI6ImRpZDpwcmlzbTo5ODFlMmNmNjNiZTYxYmM1NTU3NjZkNzFjY2JjYjc5YWNiYWQ1OTU2ZGUxYzUyMGYzOGVlMzcwO...v2QicYntggzteTM7AgAcmuVn7fx')
                                            message.info('Information copied to clipboard');  
                                        }} />
                                    </div>
                                </div>
                            </details>
                        } */}
                    </div>)) : null}       
                </section>}

                {/* Languages */}
                {cvProfile &&
                <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Languages' />
                    <div className={'flex flex-wrap ml-4'}>
                        {cvProfile?.languages.map((lang,i) => {
                            return <Bullet text={lang.name} key={i} className="w-full text-gray-900">
                                    <div className="w-full flex justify-between">
                                        <div className="text-md min-w-[55px]">{lang.name}</div>
                                        {!isMobile && <Progress strokeWidth={20} showInfo={false} percent={Number(lang.level)*(200/5)} steps={5} />} 
                                        <div className="text-gray-500 min-w-[100px] text-right">{languageProficiencyLevels(Number(lang.level))}</div>
                                    </div>
                                </Bullet>
                        })}
                    </div>
                </section>}

                {/* Achievments */}
                {cv?.achievements && cv.achievements.length >0 &&
                <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Achievements' />
                    {cv?.achievements && cv?.achievements.length > 0 ? cv.achievements.map((achievement, id) => 
                    (<div key={id} className={'mt-1 ml-4'}>
                        <p className={'text-base text-gray-900 font-semibold pb-1 flex items-center'}>{achievement?.achievement_name}</p>
                        <div className={'flex flex-col md:grid grid-cols-1 lg:grid-cols-5 text-gray-500'}>  
                            <div className={'flex flex-col col-span-2'}>
                                <Bullet text="lang1" noMargin={true}>
                                    <p className={'text-base text-gray-900 pb-1'}><b>{achievement?.issuing_organization}</b></p>
                                </Bullet>
                                <p className={'text-sm text-gray-500 pb-1'}>{`${achievement?.issuing_country ?? ''}`}</p>
                                <p className={'text-sm text-gray-500 pb-1'}>{achievement?.organization_phone ?? ''}</p>
                                <p className={'text-sm text-gray-500 pb-1 underline'}>{achievement?.issuing_organization_website ?? ''}</p>
                            </div>
                            <span className={'inline-flex items-center col-span-1 lg:col-span-2'} >
                                {achievement?.description ?? ''}
                            </span>
                            <span className={'inline-flex items-center col-span-1 lg:justify-self-end'} >
                            {moment(achievement?.start_date)?.format('MMM YYYY') ?? ''} - {achievement?.end_date ? moment(achievement?.end_date)?.format('MMM YYYY') : 'Present'}
                            </span>
                        </div>
                    </div>)) : null}
                </section>}

                {/* Certifications */}
                {cv?.certifications && cv.certifications.length >0 && <section className={'px-6 mb-3'}>
                    <SectionSeparator title='Certifications' />
                    {cv.certifications.map((certification, id) =>
                    (<div key={id} className={'mt-4 ml-4'}>
                        <p className={'text-base text-gray-900 font-semibold pb-1 flex items-center'}>{certification?.certificatoin_name}</p>
                        <div className={'grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-5 text-gray-500 items-start'}>  
                            <div className={'flex flex-col col-span-2'}>
                                <Bullet text="lang1" noMargin={true}>
                                    <p className={'text-base text-gray-900 pb-1'}><b>{certification?.issuing_organization}</b></p>
                                </Bullet>
                                <p className={'text-sm text-gray-500 pb-1'}>{certification?.issuing_country}</p>
                                <p className={'text-sm text-gray-500 pb-1 underline'}>{certification?.issuing_organization_website}</p>
                            </div>
                            <span className={'inline-flex items-center col-span-1 sm:col-span-2'} >
                                {certification?.description}
                            </span>
                            <span className={'inline-flex items-center col-span-1 lg:justify-self-end'} >
                            {moment(certification?.start_date)?.format('MMM YYYY') ?? ''} - {certification?.end_date ? moment(certification?.end_date)?.format('MMM YYYY') : 'Present'}
                            </span>
                        </div>
                    </div>))}
                </section>}
                
                <SectionSeparator title='' />
                <div className={'px-6 flex justify-between text-gray-400 bg-gray-50'}>
                    <p className={'font-medium text-lg'}>Fairway recruitment services</p>
                    <p>cv built using: https://fairway.work</p>
                </div>
                </section>}
            </div>
            {showEducationVerification && selectedEducation && cv?.cv &&
                <VerifyEducation
                    cvId={cv?.cv}
                    fullName={`${cvProfile?.first_name} ${cvProfile?.last_name}`}
                    education={selectedEducation}
                    onClose={() => {
                        setShowEducationVerification(false);
                        setSelectedEducation(null);
                    }}
                    onSubmit={() => {
                        setShowEducationVerification(false)
                        setSelectedEducation(null);
                        setReload(prev => !prev);
                    }}
                />}
        </Page>
    )
}

export default CVDetail;