import { Button } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router';

interface IErrorPage {
    is404?: boolean;
}

export const ErrorPage = ({is404=true}:IErrorPage) => {
    const navigate = useNavigate();
    const goBackHome = () => {
        navigate('/dashboard')
    }

    return(
        <div className={`min-w-screen min-h-screen flex justify-center items-center p-5 lg:p-20 overflow-hidden relative ${is404 ? 'bg-blue-100' : 'bg-yellow-100'}`}>
            <div className="min-h-full w-full max-w-full lg:max-w-lg xl:max-w-2xl 2xl:max-w-4xl rounded-none bg-white shadow-lg p-4 md:p-8 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/2 flex flex-col justify-between h-full">
                    <div className="flex flex-col pl-1 mb-4">
                        <h1 className={`text-3xl lg:text-5xl font-bold ${is404 ? 'text-blue-300' : 'text-yellow-300'}`}>oops</h1>
                        <p className={'text-gray-400'}>{is404 ? 'Page not found' : 'Something_went_wrong'}</p>
                    </div>
                    <div>
                        <Button onClick={goBackHome} type={'default'}>Go Back to Home</Button>
                    </div>
                </div>
                <div className="w-full md:w-1/2">
                    <img src={is404 ? '/404.svg' : '/500.svg'} alt="" width="100%" height="auto"/>
                </div>
            </div>
        </div>
    )
}
