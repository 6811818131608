import ResetPassword from "./ResetPassword"
import Page from "../base/Page"

const ResetPasswordPage = () => {
  return (
    <Page>
        <div className="flex justify-center items-center h-screen w-screen">
            <div className="max-w-md md:max-w-2xl shadow-xl p-8">
                <img src="/fairway_logo_black_text.png" alt="logo" className="h-16 w-auto" />
                <ResetPassword/>
            </div>
        </div>
    </Page>
  )
}

export default ResetPasswordPage