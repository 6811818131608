import {EnvironmentOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button} from "antd";
import moment from "moment";
import { useNavigate } from "react-router";
import { IProgram} from "../../types";
import Avatar from "antd/lib/avatar/avatar";
import { useState } from "react";
// import { trimText } from "../../Utility";
import useDevice from "../../hooks/useDevice";
// import { convertNumberToK } from "../../Utils";
import { useMenu } from "../../context/MenuContext";
import useAlert from "../../components/toast/useAlert";
import { useAuth } from '../../context/AuthContext';

interface IProgramListItem {
    program: IProgram
    onEdit: (id:number) => void
    onArchive: (id:number, type: boolean)=> void
}


const ProgramListItem = ({program, onEdit, onArchive}:IProgramListItem)=> {

    const { isMobile } = useDevice();
    // const {currentUser} = useAuth();
    // const navigate = useNavigate();
    // const {isLoggedIn} = useAuth();
    // const {setMenuExpanded} = useMenu()
    // const {addAlert} = useAlert();
    const [applyLoading, setApplyLoading]= useState<boolean>(false)

    const onViewDetail = (e:any) => {
        // if(userMyJobs) {
        //     navigate(`/dashboard/jobs/${job?.id}`, {state: {disableApplication: true}})
        //     return;
        // }
        // navigate(`/dashboard/jobs/${job?.id}`, {state: {disableApplication: true}})
    }   

    const handleIssue = (e:any)=> {
        // e.preventDefault();
        // e.stopPropagation();
        // setApplyLoading(true);
        // if (job?.id && onApply) {
        //     onApply(job.id, ()=> {
        //     setApplyLoading(false)
        //     });
        // }
    }
    const onReject = () => {
        // setRequiredQuestions(false);
        // setApplyLoading(false);
    }

    const onCancel = () => {
        // setRequiredQuestions(false);
        // setApplyLoading(false);
    }
    const onSubmit = (data:any) => {
        // const go = (data:any) => {
        //     RequirementExtraQuestions(data).then((response) => {
        //         if(response?.status === 201) {
        //             setRequiredQuestions(false);
        //             setApplyLoading(true);
        //             if(job?.id && onApply) {
        //                 onApply(job.id, ()=> {
        //                     setApplyLoading(false)
        //                 })
        //             }
        //         }
        //     }, (error) => {
        //         addAlert({type: 'error', title: 'error', description: JSON.stringify(error?.response?.data), autoClose:false})
        //     }).catch(err => console.log(err))
        // }
        // if(data?.user){
        //     go(data) 
        // }
        // else {
        //     const data = {
        //         "user":currentUser?.id,
        //         "job": job?.id,
        //         "has_relative": false,
        //         "relatives": []
        //     };
        //     go(data) 
        // }
    }
    
    return(
        <>
        <div onClick={onViewDetail} className={`bg-white cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto xl:max-w-[85rem] px-6 py-4`}>
            <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-1 md:grid-rows-2 lg:grid-rows-1 gap-3 md:gap-4'}>
                    <div className={'col-span-1 md:col-span-1 flex items-center overflow-hidden'}>
                        <Avatar
                            size={isMobile ? 60 : 80}
                            className={'flex shrink-0'}
                            src={program?.logo?? '/company-s.png'}
                            shape={'circle'}
                        />
                        <div className={'flex flex-col ml-4 xl:w-full'}>
                            <p className={'font-semibold text-lg text-gray-900 pb-1 capitalize w-full flex-wrap'}>{program?.name}</p>
                            <p className={'font-light text-md text-gray-700 pb-1 capitalize'}>{program?.code}</p>
                            <p className={'font-thin text-sm text-gray-500 pb-1'}>Requested {program?.created_at ? moment(program?.created_at).fromNow(): ''}</p>
                        </div>

                    </div>
                    <div className={'col-span-1'}>
                        <div className={'flex flex-row items-start w-full'}>
                            <div className={'flex flex-col mx-2 h-auto md:max-h-26 pt-2 overflow-hidden'}>
                                <div className={'font-light text-sm text-gray-400'}>{program?.description}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-span-1 md:col-span-2'}>
                        <div className={'w-full h-full flex flex-row items-center flex-wrap md:flex-nowrap'}>
                            <div className={'flex flex-row gap-3 items-start w-full xl:w-max mb-4 flex-wrap md:flex-nowrap md:gap-x-2 justify-start md:justify-between xl:justify-around md:mb-0'}>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_gray rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <EnvironmentOutlined className={'font-semibold text-gray-600 transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-xs inline-block truncate'}>{`${program?.university?.city}`}</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_yellow rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        {/* <BarChartOutlined className={'font-semibold text-fw_job_shiro transform scale-125 md:scale-175'} /> */}
                                        <p className="font-semibold text-gray-600">{program?.duration + " Months" ?? "UNKNOWN Months"}</p>
                                        <p className={'text-gray-600 text-xs inline-block  whitespace-pre-wrap w-full text-center'}>Duration</p>
                                    </div>
                                </div>


                            </div>
                            <div className={'flex flex-col items-start w-full xl:w-full md:ml-4'}>
                                <div className={'flex flex-row w-full items-end justify-start lg:justify-between flex-wrap gap-2 lg:flex-col lg:h-20'}>
                                     <Button disabled={applyLoading || program.is_archived} onClick={() => onEdit(program.id)} loading={applyLoading} className={'rounded-full max-w-[150px] lg:w-full font-semibold'} type={'primary'}>Edit</Button>
                                    <Button onClick={() => onArchive(program.id, program.is_archived)} className={'rounded-full max-w-[150px] lg:w-full text-red-500 bg-red-100 border-0 font-semibold'} type={'primary'}>{program.is_archived ? 'UnArchive' :'Archive'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        </>
    )
}
export default ProgramListItem