import { Steps, Form, Input, DatePicker, InputNumber, Button, Checkbox, Empty, Alert, Select, AutoComplete } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, EnvironmentOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from "../../context/AuthContext";
import { AllLevelsOfStudy, ICVEducation, IUniversity } from "../../types";
import { addCVEducation, deleteCVEducation, getCVEducation, updateCVEducation } from "../../services/CVService";
import moment from "moment";
import useAlert from "../../components/toast/useAlert";
import PublishBtn from "../../components/cv/PublishBtn";
import { noteToJobSeeker } from "../../Utility";
import PageSpinner from "../../components/base/PageSpinner";
import Page from "../../components/base/Page";
import FWSubHeader from "../../components/base/FWSubHeader";
import { getUniversities } from "../../services/UserService";

const CVEdit3 = ()=> {
    const {Step} = Steps;
    const { Option } = Select;

    const {cvId} = useAuth();
    const navigate = useNavigate();
    const {addAlert} = useAlert();
    const monthFormat = 'YYYY/MM';

    const [cvEducations, setCVEducations] = useState<ICVEducation[]>([]);
    const [selectedEducation, setSelectedEducation] = useState<ICVEducation>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [isPresent, setIsPresent] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [GpaTotal, setGpaTotal] =useState<number>(4)
    const [universities, setUniversities] = useState<IUniversity[]>([])
    const [selectedUniversityId, setSelectedUniversityId] = useState<number>()
    const [selectedProgramId, setSelectedProgram] = useState<number>()
    const [uniNotFound, setUniNotFound] = useState(false)
    const [programNotFound, setProgramNotFound] = useState(false)

    const [form] = Form.useForm();

    useEffect(()=> {       
        if(cvId) {
            setLoading(true);
            getCVEducation(cvId)
            .then(res => {
                const educations:ICVEducation[] = res?.data;
                setCVEducations(educations)
            })
            .finally(()=> setLoading(false))
           
            getUniversities().then(res => setUniversities(res?.data ?? []))
        }
    }, [cvId, reload])

    useEffect(() => {
        setSelectedUniversityId(selectedEducation?.university ?? undefined)
        setSelectedProgram(selectedEducation?.program ?? undefined)
    }, [selectedEducation])

    const onEditClicked = (id?:number)=> {
        if(id) {
            setIsCreating(false)
            const selected = cvEducations.find(edu => edu.id === id);
            if(selected) {
                const {start_date, end_date, ...rest} = selected;
                if(!end_date){setIsPresent(true)}
                const selectedEdu = {...rest, start_date: moment(start_date), end_date: end_date ? moment(end_date) : undefined}
                setSelectedEducation(selectedEdu)
            }
        }
    }

    const onCancelEdit = ()=> {
        setSelectedEducation(undefined);
        if(isCreating) {
            setIsCreating(false)
        }
        form.resetFields();
    }

    const onSubmitEducation = (values:ICVEducation & {institute_name_prefilled : string, field_of_study_prefilled: string})=> {
        if(cvId) {
            setLoading(true)
            const formattedStartDate =  moment(values?.start_date).format('YYYY-MM-DD');
            const formattedEndDate = (values.end_date && !isPresent) ? moment(values?.end_date).format('YYYY-MM-DD') : undefined;
            const data= {...values, start_date: formattedStartDate, end_date: formattedEndDate};
            if(selectedUniversityId && values?.institute_name_prefilled) {
                data.institute_name = values?.institute_name_prefilled
                data.university = selectedUniversityId
            }
            if(selectedProgramId && values?.field_of_study_prefilled) {
                data.field_of_study = values?.field_of_study_prefilled
            }
            if(selectedProgramId) {
                data.program = selectedProgramId
            }
            const api = (!isCreating && selectedEducation?.id) ? updateCVEducation(cvId, selectedEducation?.id, data) : addCVEducation(cvId,data );
                        
            api
            .then(res => {
                addAlert({type: 'success', title: 'success', description: `${isCreating ? 'added' : 'updated'} successfully`, autoClose: true})
                setSelectedEducation(undefined);
                setReload(prev => !prev);
                if(isCreating) {
                    setIsCreating(false);
                }
                form.resetFields();
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> setLoading(false))
        }
    }

    const onDeleteClicked = (id?:number)=> {
        if(id) {
            deleteCVEducation(cvId,id)
            .then(res => {
                addAlert({type: 'success', title: 'success', description: 'Deleted succesfully', autoClose: true})
                setReload(prev => !prev);
                setSelectedEducation(undefined)
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: true})
                console.log(err)
            })
            .finally(()=> setLoading(false))
        }
    }

    const onNextClick = ()=> {
        navigate(`/dashboard/cv/edit/4/${cvId}`)
    }

    useEffect(() => {
        form.setFieldsValue(!isCreating ? selectedEducation ? {
            ...selectedEducation,
            institute_name_prefilled: selectedEducation?.university ? selectedEducation.institute_name : undefined,
            field_of_study_prefilled: selectedEducation?.program ? selectedEducation.field_of_study : undefined
        } : [] : [])
    }, [form, isCreating, selectedEducation])
    
    const onChangeStep = (newStep: number) => {
        if(isCreating) {
            return
        }
        if(newStep === 0) {
            navigate(`/dashboard/cv/edit/1`)
        }
        if(newStep === 1) {
            navigate(`/dashboard/cv/edit/2/${cvId}`)
        }
        if(newStep === 3) {
            navigate(`/dashboard/cv/edit/4/${cvId}`)
        }
        if(newStep === 4) {
            navigate(`/dashboard/cv/edit/5/${cvId}`)
        }
    }

    const onSelectUniversity = (data: string) => {
        if(data === 'other') {
            setSelectedUniversityId(undefined)
            setUniNotFound(true)
            setProgramNotFound(true)
            return;
        }
        setSelectedProgram(undefined)
        const selectedUni = universities.find(uni => uni.name === data)
        if(selectedUni) setSelectedUniversityId(selectedUni.id)
    };

    const onSelectProgram = (data: string) => {
        const selectedUni = universities.find(uni => uni.id === selectedUniversityId)
        if(data === 'other' || !selectedUni) {
            setSelectedProgram(undefined)
            setProgramNotFound(true)
            return;
        }
       
        const selectedProg = selectedUni.programs.find(prog => `${prog?.code}_${prog?.name}`.toLowerCase() === data.toLowerCase()) 
        if(selectedProg) setSelectedProgram(selectedProg.id)
    };

    const selectedUniPrograms = useMemo(() => {
        if(!selectedUniversityId) return []
        const options = universities.find(uni => uni.id === selectedUniversityId)?.programs?.map((prog) => ({id: prog?.id, value: `${prog?.code}_${prog?.name}`})) ?? []
        return [...options, {id: null, value: 'other'}]
    },[selectedUniversityId, universities])
    
    return(
        <Page>
            <FWSubHeader title={'Edit Curriculum Vitae _ Education'} children={(
                <PublishBtn loading={loading} disabled={loading ||  cvEducations.length === 0 || !!selectedEducation || isCreating} />
            )} />
            <Steps responsive={true} className={'px-4 mt-4 sm:mb-4 w-full max-w-3xl xl:max-w-4xl'}  current={2} onChange={onChangeStep} size={'small'}>
                    <Step title="Personal Info" />
                    <Step title="Experience"  />
                    <Step title="Education" />
                    <Step title="Achievements" />
                    <Step title="Certifications" />
            </Steps>
            <div className={'bg-white p-4 shadow rounded-md md:rounded-xl py-4 w-auto max-w-3xl xl:max-w-4xl'}>
            <div className="w-full h-2 flex justify-end -mb-2"><CloseOutlined onClick={() => navigate('/dashboard/cv', {state: {isFromCv:true}})} className="hover:text-fw-orange cursor-pointer"/></div>
                <div className={'flex flex-col w-full mt-6'}>
            {loading ? <PageSpinner /> :
            <>
            {(selectedEducation || isCreating) && <Form
                name="addExperience"
                onFinish={onSubmitEducation}
                autoComplete="off" 
                className={'w-full grid grid-cols-1 md:grid-cols-3 gap-x-5'}
                form={form}
            >   
                    <Form.Item
                        className={'col-span-1 md:col-span-1'}
                        name="institute_name_prefilled"
                        rules={[{ required: true, message: 'Univesity is required!' }]}
                    >
                        <Select
                            placeholder={'University or Institute name'}
                            onChange={onSelectUniversity}
                            options={[...universities.map(uni => ({value: uni.name})), {value: 'other'}]}
                        />
                    </Form.Item>
                    
                    {!selectedUniversityId && <Form.Item
                        className={'col-span-1 md:col-span-2'}
                        name="institute_name"
                        rules={[{ required: true, message: 'Univesity is required!' }]}
                    >
                        <Input placeholder={'University or Institute name'} />
                    </Form.Item>}

                    <Form.Item
                        name="level_of_study"
                        rules={[{ required: true, message: 'Level of Study is required!' }]}
                    >
                        <Select placeholder={'Level of Study'} onChange={() => console.log('test')}>
                            {AllLevelsOfStudy.map((level, index) => <Option key={index} value={index}>{level}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"start_date"}
                        rules={[{ required: true, message: 'Start date is required!'}]}
                    >
                        <DatePicker className={'w-full'} format={monthFormat} placeholder={'start date'} />
                    </Form.Item>

                    <Form.Item
                        name={"end_date"}
                    >   
                        <DatePicker className={'w-full'} disabled={isPresent} format={monthFormat} placeholder={'end date'} />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox checked={isPresent} onChange={e => setIsPresent(e.target.checked)}>Up to Present</Checkbox>
                    </Form.Item>

                    {selectedUniversityId && <Form.Item
                        name="field_of_study_prefilled"
                        rules={[{ required: true, message: 'Field of study is required!'}]}
                    >
                        <Select
                            placeholder={'Univesity programs'}
                            onChange={onSelectProgram}
                            options={selectedUniPrograms}
                        />
                    </Form.Item>}

                    {(!selectedUniversityId && programNotFound) && <Form.Item
                        name="field_of_study"
                        rules={[{ required: true, message: 'Field of study is required!'}]}
                    >
                        <Input placeholder={'Field of Study, e.g BA in Accounting'} /> 
                    </Form.Item>}

                    
                    {!selectedUniversityId && 
                    <>
                    <Form.Item
                        name="institute_city"
                    >
                        <Input placeholder={'University city'} />
                    </Form.Item>
                    <Form.Item
                        name="institute_country"
                    >
                        <Input placeholder={'University country'} />
                    </Form.Item>
                    </>}

                    <div className="flex items-center gap-x-3">
                        <Form.Item
                            name="gpa"
                        >
                            <InputNumber min={1} max={GpaTotal} placeholder={'your GPA'} />
                        </Form.Item>

                        <Form.Item
                            name="gpa_out_of"
                        >
                            <InputNumber defaultValue={GpaTotal}  onChange={value => setGpaTotal(value)} placeholder={'/Total'} />
                        </Form.Item>
                    </div>

                    {!selectedUniversityId && <Form.Item
                        className={'col-span-1 md:col-span-3'}
                        name="institute_website"
                    >
                        <Input placeholder={'University website or social media profile'} />
                    </Form.Item>}
                    
                    <Form.Item
                        name="major"
                    >
                        <Input placeholder={'Major (if applicable)'} />
                    </Form.Item>
                    <Form.Item
                        name="minor"
                    >
                        <Input placeholder={'Minor (if applicable)'} />
                    </Form.Item>
                    <div className={'flex items-center gap-x-3'}>
                        <Button loading={loading} disabled={loading} size={'small'}  block type={'primary'} htmlType="submit">{isCreating ? 'Add Education' : 'Update'}</Button>              
                        <Button onClick={onCancelEdit} size={'small'}  block type={'default'}>Cancel</Button>  
                    </div>  
            </Form>}

            {/* List of Experiences */}
            {cvEducations?.length === 0 && !isCreating && <Empty className={'mb-4'} description={'No items found, Click add button to input your education 🎓 ...(is Mandatory)' } />}
            {(!selectedEducation && !isCreating) && <Button onClick={()=> setIsCreating(true)} className={'w-max'} loading={loading} disabled={loading} size={'small'} type={'primary'} ghost>Add new Education</Button>}
            {cvEducations?.length > 0 ? cvEducations.filter(edu => edu.id !== selectedEducation?.id).map(education => 
            (<div className={'mt-4'}>
                <p className={'text-base text-gray-900 font-semibold flex items-center'}>{education?.field_of_study} <span> 
                    <EditOutlined onClick={() => onEditClicked(education?.id)} className={'ml-2 mb-1 text-fw-orange'} />
                    <DeleteOutlined onClick={() => onDeleteClicked(education?.id)} className={'ml-2 mb-1 text-red-700'} />
                </span> </p>
                <p className={'text-sm text-gray-500'}>Level of study: <span className={'font-medium'}>{AllLevelsOfStudy[education?.level_of_study]}</span></p>
                <div className={'grid grid-cols-1 lg:grid-cols-6 text-gray-500 items-start'}>
                    <div className={'flex flex-col flex-wrap col-span-1 lg:col-span-3'}>
                        <p className={'text-sm'}><b>{education?.institute_name}</b> | 
                        {education?.institute_website && <a target={'_blank'} rel={'noReferrer'} href={education?.institute_website} className={'text-xs underline pl-2'}>{education?.institute_website}</a>}
                        </p>
                        <p className={'text-sm'}>{moment(education?.start_date)?.format('MMM YYYY') ?? ''} - {education?.end_date ? moment(education?.end_date)?.format('MMM YYYY') : 'Present'}</p>
                    </div>

                    <p className={'text-sm sm:justify-self-center'}><b>{education?.gpa && 'GPA: '}{education?.gpa}</b>{education?.gpa && education?.gpa_out_of && ' out of '}{education?.gpa_out_of}</p>
                    <span className={'inline-flex items-center col-span-1 lg:col-span-2 lg:justify-self-end'} >
                        <EnvironmentOutlined className={'mr-2 font-thin'} />{`${education?.institute_city}, ${education?.institute_country}`} 
                    </span>
                </div>
            </div>)) : null}
            
            <Alert className={'col-span-1 md:col-span-3 mt-4 mb-1 text-xs'} message={noteToJobSeeker} type={'warning'} showIcon />
            
            <div className={'w-full flex justify-end'}>
                <div className={'mt-4 flex flex-col md:flex-row gap-4 w-full md:w-1/2 justify-items-end'}>
                    <Button onClick={() => navigate(-1)} block type="default">Previous</Button>
                    <Button loading={loading} disabled={loading|| cvEducations.length === 0 || !!selectedEducation || isCreating}  block type="primary" onClick={onNextClick}>{'Save and Proceed'}</Button>
                </div>
            </div>
            </>}
            </div>
        </div>
        </Page>
    )
}

export default CVEdit3;