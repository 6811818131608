
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {SnippetsOutlined, IdcardOutlined, SettingOutlined, SearchOutlined, ProfileOutlined, KeyOutlined, ProjectOutlined} from '@ant-design/icons';
import { useAuth } from "./AuthContext";
import useWindowDimensions from "../hooks/UseWindowDimenstions";


export interface IMenuContext {
  menuItems: IMenuItem[]
  setActiveMenu: (e : number) => void;
  activeMenu: number
  menuExpanded: MenuExpandedState
  setMenuExpanded: (e: MenuExpandedState) => void
}

export type MenuExpandedState = 'none' | 'compact' | 'expanded'

export interface IMenuItem {
    id: number;
    name: string;
    icon: JSX.Element;
    activeIcon?: JSX.Element;
    url: string;
    hidden: boolean;
}

const MenuContext =  createContext<IMenuContext>({} as IMenuContext);

const MenuContextProvider = (props:any)=> {
    const [activeMenu, setActiveMenu] = useState<number>(0);
    const [menuExpanded, setMenuExpanded] = useState<MenuExpandedState>('compact')
    const {currentUser} = useAuth()
    const {width} = useWindowDimensions()

    const menuItems = useMemo(():IMenuItem[]  =>  {
        return [
        {   
            id: 0,
            name: 'My Jobs',
            icon: <ProfileOutlined className={'transform scale-125'} />,
            url: `/dashboard`,
            hidden: !!currentUser?.is_university_staff
        },
        {   
            id: 1,
            name: 'Browse Jobs',
            icon: <SearchOutlined className={'transform scale-125'} />,
            url: `/dashboard`,
            hidden: currentUser?.is_staff || currentUser?.is_university_staff ? true : false
        },
        {   
            id: 2,
            name: 'Curriculum Vitae',
            icon: <IdcardOutlined />,
            url: `/dashboard/cv`,
            hidden: currentUser?.is_staff || currentUser?.is_university_staff ? true : false
        },
        {   
            id: 3,
            name: 'Employments',
            icon: <SnippetsOutlined className={'transform scale-125'} />,
            url: `/dashboard/employments`,
            hidden: true
            // hidden: !currentUser?.is_staff
        },  
        {   
            id: 4,
            name: 'Credentials',
            icon: <KeyOutlined className={'transform scale-125'} />,
            url: `/dashboard/credentials`,
            hidden: currentUser?.is_staff ?? false
        }, 
        {   
            id: 5,
            name: 'Programs',
            icon: <ProjectOutlined className={'transform scale-125'} />,
            url: `/dashboard/programs`,
            hidden: !currentUser?.is_university_staff
        },  
        {   
            id: 6,
            name: 'Account',
            icon: <SettingOutlined className={'transform scale-125'} />,
            url: `/dashboard/account`,
            hidden: false
        }
    ]}, [currentUser])

    useEffect(()=> {
        if(width <= 768) {
            setMenuExpanded('none');
        } 
        if(width > 768 && width < 1200) {
            setMenuExpanded('compact');
        } 
        if(width >= 1200) {
            setMenuExpanded('expanded');
        }
    },[width])

    const context:IMenuContext = {
     menuItems,
     menuExpanded,
     setMenuExpanded,
     activeMenu,
     setActiveMenu
    }

    return <MenuContext.Provider {...props} value={context}  />
}


const useMenu = () => {
  const customHook: IMenuContext = useContext(MenuContext);
  return customHook;
};

export {useMenu, MenuContextProvider};