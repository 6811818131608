import { Divider, Form, Rate, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/base/Modal'
import useAlert from '../../../components/toast/useAlert'
import { getMotivationAnswers, getMotivationQuestions, rateMotivationAnswer } from '../../../services/MotivationService'
import { IMotivationAnswer, IMotivationQuestion } from '../../../types'

interface IMotivationEvaluationModal {
    selectionId: number
    onClose: () => void
    onSubmit: () => void
}

const MotivationEvaluationModal:React.FC<IMotivationEvaluationModal> = ({selectionId, onSubmit, onClose}) => {
    const [form] = Form.useForm();
    const {addAlert} = useAlert()
    const [motivationAnswers, setMotivationAnswers] = useState<any[]>([])
    const [questions, setQuestions] = useState<IMotivationQuestion[]>([])
    const [ratingIsSaving, setRatingIsSaving] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // Check if rating is done or not

        // If not done -> populate default values
        setLoading(true)
        getMotivationAnswers(selectionId).then(res => {
            setMotivationAnswers(res?.data ?? [])
        })
        getMotivationQuestions(selectionId).then(res => setQuestions(res?.data ?? [])).finally(() => {
            setLoading(false)
        })

    }, [selectionId])

    const onSubmitModal = () => {
        form.submit()
    }

    const onRatingChange = (val: number, answer: IMotivationAnswer) => {
        // Save rating to database per question
        setRatingIsSaving(true)
        rateMotivationAnswer(selectionId, {user: answer.user, question: answer.question, rating: val}).then(() => {
            addAlert({type: 'success', title: 'sucess', description: 'rating saved successfully', autoClose: true})
        })
        .finally(() => setRatingIsSaving(false))
    }

    return(
        <Modal
         title='Evaluate Motivation Answers'
         onCancel={onClose}
         onSubmit={onSubmitModal}
         className={'min-h-[90%] w-[70%]'}
         hideCurve={true}
         submitText={'Save'}
         cancelText={'Close'}
        >   
            {loading ?
             <div className={'w-full h-full grid place-items-center'}>
                <Spin />
             </div>
            :
            motivationAnswers.length === 0 ?
                <p className={'text-sm text-gray-600'}>No motivation answers yet. Check out us soon.</p>
            :
            <Form
                name="rateMotivationAnswers"
                onFinish={(values) => onSubmit()}
                className={'w-full flex flex-col'}
                form={form}
                autoComplete="off"
            >   
            <p className={'pb-2 text-base text-gray-500 font-semibold'}>Please give ratings for every answer (min 0.5 star)</p>
            {motivationAnswers && motivationAnswers.map((user) => (
                <div
                    className={'flex flex-col w-full mb-2'}
                 >
                    <p className={'text-gray-600 font-semibold text-xs'}>Candidate: CODE_ID***{user.user}
                    </p>
                    <div className='flex items-start flex-row gap-x-4'>
                        {user.answers.map((answer: IMotivationAnswer, i: number) => (
                            <div key={i} className={'flex flex-col flex-1 text-xs max-w-[250px]'}>
                                <div className={'flex flex-col justify-between text-gray-500 min-h-[20px]'}>
                                    <span className={'font-thin'}>Question: {questions?.find(q => q.id === answer.question)?.question}</span>
                                </div>
                                <div className={'w-full min-h-[80px] bg-fw_motiv_green p-2 text-gray-600 text-xsm'}>
                                   <Form.Item
                                     name={answer.user + '/' + answer.question}
                                     className={'mb-0 -ml-2'}
                                   >
                                   <Rate disabled={ratingIsSaving} className={'scale-75'} allowHalf defaultValue={answer.rating ?? 0} onChange={(val) => onRatingChange(val, answer)} />
                                   </Form.Item>
                                   <span className={'font-thin'}>Answer:</span> {answer.answer}
                                </div>
                            </div>
                        ))}
                    </div>  
                    <Divider className={'my-4'} />
                </div>
            ))}  
            </Form>}
        </Modal>
    )
}

export default MotivationEvaluationModal