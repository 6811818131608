import { Spin, Steps } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getFilters, getSelection } from '../../../services/SelectionService'
import { ISelection, ITab } from '../../../types'
import { convertMaptoArr } from '../../../Utility'
import Modal from '../../base/Modal'
import useAlert from '../../toast/useAlert'
import { IPieData } from '../PieChartFW'
import EducationFilter from './EducationFilter'
import ExamFilter from './ExamFilter'
import ExperienceFilter from './ExperienceFilter'
import { FilterStyleClass, IEducationItems, IFilterResponseData, IFilterSummaryData } from './FilterHelper'
import FilterSummary from './FilterSummary'
import MotivationFilter from './MotivationFilter'
import ScreeningFilter from './ScreeningFilter'
import VettedChart from './VettedChart'

interface ISelectionFilter {
    selectionId?: number
    onSubmit: () => void
    onClose: () => void
}

const SelectionFilter:React.FC<ISelectionFilter> = ({selectionId, onSubmit, onClose}) => {

    const [educationData, setEducationData] = useState<IPieData[]>([])
    const [experienceData, setExperienceData] = useState<IPieData[]>([])
    const [motivationData, setMotivationData] = useState<IPieData[]>([])
    const [screeningData, setScreeningData] = useState<IPieData[]>([])
    const [examData, setExamData] = useState<IPieData[]>([])
    const [educationDataRaw, setEducationDataRaw] = useState<any>()
    const [experienceDataRaw, setExperienceDataRaw] = useState<any>()
    const [motivationDataRaw, setMotivationDataRaw] = useState<any>()
    const [screeningDataRaw, setScreeningDataRaw] = useState<any>()
    const [examDataRaw, setExamDataRaw] = useState<any>()
    const [result, setResult] = useState<{selected: number, total: number}>({selected:0, total:0})
    const [loading, setLoading] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)
    const [educationItems, setEducationItems] = useState<IEducationItems>()

    useEffect(() => {
        if(selectionId) {
            setLoading(true)
            getFilters(selectionId).then(res => {
                const filters:IFilterResponseData = res?.data[0]
                const education = filters.filter_values?.['EducationFilter'] ? convertMaptoArr(filters.filter_values['EducationFilter'], true) : []
                const experience = filters.filter_values?.['ExperienceFilter'] ? convertMaptoArr(filters.filter_values['ExperienceFilter'], true) : []
                const motivation = filters.filter_values?.['MotivationFilter'] ? convertMaptoArr(filters.filter_values['MotivationFilter'], true) : []
                const screening = filters.filter_values?.['IndividualScreeningFilter'] ? convertMaptoArr(filters.filter_values['IndividualScreeningFilter'], true) : []
                const exam = filters.filter_values?.['ExamFilter'] ? convertMaptoArr(filters.filter_values['ExamFilter'], true) : []
                setEducationDataRaw(filters.filter_values?.['EducationFilter'] ?? undefined)
                setExperienceDataRaw(filters.filter_values?.['ExperienceFilter'] ?? undefined)
                setMotivationDataRaw(filters.filter_values?.['MotivationFilter'] ?? undefined)
                setScreeningDataRaw(filters.filter_values?.['IndividualScreeningFilter'] ?? undefined)
                setExamDataRaw(filters.filter_values?.['ExamFilter'] ?? undefined)
                setEducationData(education.filter(item => item.name !== 'id'))
                setExperienceData(experience.filter(item => item.name !== 'id'))
                setMotivationData(motivation.filter(item => item.name !== 'id'))
                setScreeningData(screening.filter(item => item.name !== 'id'))
                setExamData(exam.filter(item => item.name !== 'id'))
                setResult(filters.result)
            }).finally(() => setLoading(false))

            getSelection(selectionId).then((res) => {
                const data = res?.data as ISelection
                const educationDataItems = Object.assign({}, {
                    fieldOfStudies: Object.keys(data.applicant_field_of_study),
                    skills: Object.keys(data.applicant_skills),
                    universities: Object.keys(data.applicant_universities)
                })
                setEducationItems(educationDataItems)
            })
        }
        
    }, [selectionId, reload])

    const onSubmitModal = () => {
        // Send to api

        // Run submit callback
        onSubmit()
    }
    
    const { Step } = Steps;
    const {addAlert} = useAlert()

    const [current, setCurrent] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState<number>(-1)

    const onCancelFilter = () => {
        setCurrent(0)
    }

    const onSaveFilter = () => {
        setReload(prev => !prev)
        setCurrent(0)
        setSelectedFilter(-1)
    }

    const onChange = useCallback((value: number) => {
      if(value === 1) {
        addAlert({type: 'warning', title: 'Select Filter Type to see detail', autoClose: true})
      } 
    }, [addAlert])

    const onSummaryClick = (id: number) => {
        setSelectedFilter(id)
        setCurrent(1)
    }

    const tabs: ITab[] = useMemo(() => [
        {
            title:"Select Filter type",
            status: current === 0 ? "process" : "finish",
            description: "Click on an item at a time",
            onStepClick: onChange
        },
        {
            title:"Filter Candidates",
            status: current === 1 ? "process" : "finish",
            description: "Decide on number of candidates",
            onStepClick: onChange
        }
    ], [current, onChange])

    console.log('motivationData', motivationData)

    const filtertypes:IFilterSummaryData[] = useMemo(() => [
       {
         id: 0,
         title: 'Education',
         data: educationData,
         onClick: onSummaryClick,
         ishidden: educationData.length === 0
       },
       {
        id: 1,
        title: 'Experience',
        data: experienceData,
        onClick: onSummaryClick,
        ishidden: experienceData.length === 0
      },
      {
        id: 2,
        title: 'Screening',
        data: screeningData.filter(item => item.name !== 'selection'),
        onClick: onSummaryClick,
        ishidden: screeningData.length === 0
      },
      {
        id: 3,
        title: 'Motivation',
        data: motivationData?.filter(item => item.name !== 'selection'),
        onClick: onSummaryClick,
        ishidden: motivationData.length === 0
      },
      {
        id: 4,
        title: 'Exam',
        data: examData?.filter(item => item.name !== 'selection'),
        onClick: onSummaryClick,
        ishidden: examData.length === 0
      }
    ], [educationData, examData, experienceData, motivationData, screeningData])    

    return(
        <Modal className={'w-[90%] min-h-[80%]'} title={'Selection Filter'} subTitle={'Update number of candidates'} onCancel={onClose} onSubmit={onSubmitModal}>
            <Steps
                type="navigation"
                size="small"
                current={current}
                className="site-navigation-steps"
                >
                {tabs.map((tab, i) => 
                    <Step
                        key={i}
                        title={tab.title}
                        status={tab.status}
                        description={tab.description}
                        onStepClick={tab.onStepClick}
                    />
                )}
            </Steps>
            <div className={'flex flex-row w-full mt-4'}>
                {current === 0 ?
                    <>
                        <div className={'w-1/2 flex-col p-1'}>
                            {loading ? <div className={'w-full h-full grid place-items-center'}><Spin /></div> :
                            <div className={FilterStyleClass.summary}>
                                {filtertypes?.map((filter) => (
                                    !filter.ishidden ?
                                    <FilterSummary ishidden={filter.ishidden} key={filter.id} title={filter.title} data={filter.data} onClick={filter.onClick} id={filter.id} /> : null
                                ))}
                            </div>}
                        </div>
                        <div className='w-1/2 flex items-center h-full pt-14'>
                            <VettedChart selected={result?.selected ?? 0} total={result?.total ?? 0} />
                        </div>
                    </> :
                    <div className={'w-full'}>
                        {selectedFilter === 0 && educationItems && 
                        <EducationFilter 
                            rawData={educationDataRaw}
                            items={educationItems}
                            selectionId={selectionId ?? 0}
                            result={result}
                            filter={filtertypes[0]}
                            onCancel={onCancelFilter}
                            onSave={onSaveFilter}
                        />}
                        {selectedFilter === 1 && 
                        <ExperienceFilter 
                            rawData={experienceDataRaw}
                            selectionId={selectionId ?? 0}
                            result={result}
                            filter={filtertypes[1]}
                            onCancel={onCancelFilter}
                            onSave={onSaveFilter}
                        />}
                        {selectedFilter === 2 && 
                        <ScreeningFilter 
                            rawData={screeningDataRaw}
                            selectionId={selectionId ?? 0}
                            result={result}
                            filter={filtertypes[2]}
                            onCancel={onCancelFilter}
                            onSave={onSaveFilter}
                        />}
                        {selectedFilter === 3 && 
                        <MotivationFilter 
                            rawData={motivationDataRaw}
                            selectionId={selectionId ?? 0}
                            result={result}
                            filter={filtertypes[3]}
                            onCancel={onCancelFilter}
                            onSave={onSaveFilter}
                        />}
                        {selectedFilter === 4 && 
                        <ExamFilter 
                            rawData={examDataRaw}
                            selectionId={selectionId ?? 0}
                            result={result}
                            filter={filtertypes[4]}
                            onCancel={onCancelFilter}
                            onSave={onSaveFilter}
                        />}
                    </div>
                }</div>
        </Modal>
    )
}

export default SelectionFilter