import Modal from "../base/Modal"
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input , Space } from 'antd';
import useDevice from '../../hooks/useDevice';

interface IRequirementModal {
    onCancel: () => void
    onSubmit: (arg0?: any) => void
    jobId? : number
    userId? : number
}

const RequirmentModal:React.FC<IRequirementModal> = ({onCancel, onSubmit, jobId, userId}) => {

    const { isMobile } = useDevice();
    const [relative, setRealtive] = useState<boolean>(false)

    const onFinish = (relatives: any) => {
        const data = {
            "user": userId ,
            "job": jobId,
            "has_relative": relative,
            "relatives": Object.values(relatives)[0]
        }
        onSubmit(data)
        return data;
      };
    const yesRelative = (e: CheckboxChangeEvent) => {
        setRealtive(e.target.checked);
    }
    const noRelative = (e: CheckboxChangeEvent) => {
        setRealtive(!e.target.checked);
    }

  return (
    <>
    <Modal 
        title="Application Requirments" 
        subTitle="Fill this mandatory form to proceed application" 
        onCancel={onCancel}
        onSubmit={onSubmit}
        hideActionButtons={relative}
        className={'!min-h-max'}
    >
        <div className="p-5 pb-0 pl-0">
        <p className="text-[15px] font-semibold pt-4 md:pt-0">Do any of your relatives or members of your family work for Dashen Bank?</p>
        <Checkbox onChange={yesRelative} checked={relative} className="my-2">Yes</Checkbox>
        <Checkbox onChange={noRelative} checked={!relative} className="my-2">No</Checkbox>
        {relative &&  
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ maxWidth: 600 , marginTop: 30}}
            autoComplete="off"
        >
            <Form.List name="relatives">
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" direction={isMobile ? "vertical" : "horizontal"} size={isMobile ? "small" : "middle"}>
                    <Form.Item
                        {...restField}
                        name={[name, 'relative_name']}
                        rules={[{ required: true, message: 'Missing name' }]}
                    >
                        <Input placeholder="Name of the staff" />
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'relative_relation']}
                        rules={[{ required: true, message: 'Missing relationship' }]}
                    >
                        <Input placeholder="Relationship with the staff" />
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'relative_position']}
                        rules={[{ required: true, message: 'Missing position' }]}
                    >
                        <Input placeholder="Position" />
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'relative_place_of_work']}
                        rules={[{ required: true, message: 'Missing place of Work' }]}
                    >
                        <Input placeholder="Place of Work" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                ))}
                <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Relative
                    </Button>
                </Form.Item>
                </>
            )}
            </Form.List>
            <Form.Item>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
            </Form.Item>
    </Form>}
        </div>
    </Modal>
    </>
  )
}

export default RequirmentModal