import { Steps, Button, Badge, Card } from "antd";
import moment from "moment";
import TrophyOutlined from "@ant-design/icons/TrophyOutlined";
import { FC, useEffect, useState } from "react";
import { getExamInfo } from "../../services/ExamService";
import { getMotivationAnswers } from "../../services/MotivationService";
import { IExam } from "../../types";
import MotivationAnswer from "../selection/motivation/MotivationAnswer";

interface IRecruitmentSection {
    selectionId: number
    yourStage: string[]
    jobStage: string[]
}

const RecruitmentSection:FC<IRecruitmentSection> = ({selectionId, jobStage, yourStage}) => {

    const [motivationRequested, setMotivationRequested] = useState(false)
    const [showAnswerModal, setShowAnswerModal] = useState(false)
    const [motivationIsAnswered, setMotivationIsAnswered] = useState(false)
    const [reload, setReload] = useState(false)
    const [exam, setExam] = useState<IExam>()

    useEffect(() => {
        getMotivationAnswers(selectionId ?? 0).then((res) => {
            if(res?.status === 200) {
                setMotivationIsAnswered(res?.data?.length === 0)
                setMotivationRequested(true)
            }
            if(res?.status === 403) {
                setMotivationIsAnswered(false)
                setMotivationRequested(false)
            }
        }).catch(err => console.log(err)) 

        getExamInfo(selectionId ?? 0).then((res) => {
            setExam(res?.data[0])
        }).catch(err => console.log(err))

        return () => {
            setExam(undefined)
        }

    }, [selectionId, reload])
    
    const onSubmitMotivation = () => {
        setShowAnswerModal(false)
        setReload(prev => !prev)
    }
    const {Step} = Steps;
    const Divider = () => (<hr style={{borderColor: '#F0F0F0', opacity: 0.5, marginLeft: '-16px', marginRight: '-16px', marginTop: 10, marginBottom: 10 }} />)

    return(<div>
            <Divider />
            <h2 className={'text-fw-blue font-semibold text-base mb-2'}>Recruitment Process Detail</h2>
            <h4 className={'text-fw-orange font-semibold text-base'}>Job Application Process stages
                <span className={'text-xs text-fw-gray pl-5'}>(The job stages)</span>
                <span className={'text-xs text-fw-orange pl-3'}>(The Stages you are in)</span>
            </h4>
           
            <Steps size={'small'} current={yourStage.length-1 ?? 0} progressDot responsive>
                {jobStage.map((stepData, index) => 
                    <Step key={index} title={`Stage: ${index + 1}`} className={'capitalize'} subTitle={stepData.toLocaleLowerCase()} />
                )}
            </Steps>

            {yourStage[yourStage.length-1] === 'Interview' &&
                <div className={'flex flex-col items-center p-4 bg-fw-green text-white rounded-md'}>
                    <TrophyOutlined className={'text-5xl font-bold'} />
                    <p className={'text-lg font-bold'}>Congratulations, You have been selected for interview/hiring stage.</p>
                    <span className={'text-xs ml-0 self-start'}>Please check your email for further instructions.</span>
                </div>
            }
            
            {motivationRequested &&
            <>
            <h4 className={'text-fw-orange font-semibold text-base mt-4 mb-2'}>Motivation question answers</h4>
            {!motivationIsAnswered ? 
                <>
                <p className={'text-gray-500 font-semibold text-sm'}>Motivation answers has been submitted!</p>
                <span className={'text-xs'}>(No further action is required)</span>
                </>
                :
                <Button className={'mt-2'} onClick={() => setShowAnswerModal(true)} size={'small'} type={'primary'}>Add Motivation answers</Button>
            }
            {showAnswerModal && selectionId &&
            <MotivationAnswer
                selectionId={selectionId}
                onSubmit={onSubmitMotivation}
                onCancel={() => setShowAnswerModal(false)}
            />}
            </>}
            {exam && 
                <div>
                    <h4 className={'text-fw-orange font-semibold text-base mt-4 mb-1'}>Examination</h4>
                    <p className={'text-gray-500 font-semibold text-sm'}></p>
                    <div className={'max-w-lg my-1'}>
                        <Badge.Ribbon text={`Your Unique Exam ID: ${exam.user_exam_id ?? 'N/A'}`}>
                            <Card title="Your are invited for Exam" size="small">
                            <p>Location: <b>{exam.location}</b></p>
                            <p>Exam Date: <b>{moment(exam.date).format('ddd DD-MMM-YYYY, hh:mm A')}</b></p>
                            <p>Company Handles Examination: <b>{exam.self_examined ? 'yes' : 'no'}</b></p>
                            <p className={'text-gray-700 font-semibold'}>Instructions</p>
                            <ul>
                                <li className={'text-xs font-semibold text-gray-500 pt-1'}>
                                    You should not put your name on the exam sheet, only your unique ID for the exam.
                                </li>
                                <li className={'text-xs font-semibold text-gray-500 pt-1'}>
                                    You should be at least 15 minutes early to the exam. You should bring your Identification card as well.
                                </li>
                                <li className={'text-xs font-semibold text-gray-500 pt-1'}>
                                    Please check your email for invitation details
                                </li>                                
                            </ul>
                            </Card>
                        </Badge.Ribbon>
                    </div>
                </div>
            }
            <Divider />
        </div>
    )
}

export default RecruitmentSection